<template>
  <div class="rate-sheet-options-data-table-description-tooltip">
    <custom-button variant="icon" @click="onClick">
      <v-icon
        size="20"
        color="var(--grayscale-color-2)">
        mdi-help-circle-outline
      </v-icon>
    </custom-button>

    <standard-dialog
      :dialog="show"
      :no-header-border="true"
      :show-clear-cta="false"
      :on-cta="onCancel"
      :on-cancel="onCancel"
      :show-cancel-cta="false"
      :show-title="true"
      :title="title"
      button-label="OK"
      button-size="small">
      <template
        #body>
        <p>
          The rate sheet is where you select the loan products you would like to offer. Your
          customers will receive a range of products based on their eligibility.
        </p>
        <p>
          The <strong>Base Package</strong> is what we recommend and have auto-selected for you.
          Our team of experts have optimized this package to cover a wide range of customer
          preferences. It is designed to increase your sales and serve more of your customers.
        </p>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'RateSheetOptionsHeaderInfoModal',

  components: {
    StandardDialog,
    CustomButton,
  },

  props: {
    show: { type: Boolean, default: false },
    onCancel: { type: Function as PropType<() => void>, default: () => () => null },
    onClick: { type: Function as PropType<() => void>, default: () => () => null },
  },

  data: () => ({
    title: 'Rate Sheet Selections',
  }),
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.rate-sheet-options-data-table-description-tooltip {
  margin-left: .25rem;
  display: inline-block;
}
</style>
