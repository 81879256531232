import { PaginatedResponse, RequestOptions } from '@/api/types';
import {
  Ref, ref, watch, reactive,
} from 'vue';
import { useDebounce } from './useDebounce';

type Request<T> = (options: RequestOptions) => PaginatedResponse<T>

interface UseTableRequestControls {
  loading?: Ref<boolean>;
  search?: Ref<string>;
  orderBy?: Ref<string> | string;
  orderDesc?: Ref<boolean>;
  filters?: Record<string, string | string[]>
}

// eslint-disable-next-line import/prefer-default-export
export const useTableRequests = <T>(request: Request<T>, controls: UseTableRequestControls) => {
  const count = ref(0);
  const page = ref(1);
  const results = ref<T[]>([]);
  const loading = ref(controls.loading ?? false);
  const search = ref(controls.search ?? '');
  const orderBy = ref(controls?.orderBy);
  const orderDesc = ref(controls?.orderDesc);
  const filters = reactive(controls.filters ?? {});

  const fetchData = async () => {
    loading.value = true;
    const ordering = orderBy?.value ? `${orderDesc?.value ? '-' : ''}${orderBy.value}` : undefined;

    try {
      const { data } = await request({
        filters,
        params: {
          page: page.value,
          page_size: 5,
          search: search.value,
          ordering,
        },
      });

      count.value = data.count;
      results.value = data.results;
    } finally {
      loading.value = false;
    }
  };

  const sendToFirstPage = () => {
    if (page.value === 1) {
      fetchData();
      return;
    }

    page.value = 1;
  };

  const { debounce } = useDebounce();
  watch(page, fetchData);
  watch(filters, sendToFirstPage, { deep: true });
  watch(search, debounce(() => sendToFirstPage()));

  return {
    fetchData, count, page, results, loading, search,
  };
};
