import GotoRouteName from '@/mixins/GotoRouteName';
import UserIsLoggedInMixin from '@/mixins/Auth/UserIsLoggedInMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserLogOutMixin',
  mixins: [GotoRouteName, UserIsLoggedInMixin],
  computed: {
    loggingOut(): boolean {
      return this.$store.getters['Auth/isLoggingOut'];
    },
  },
  methods: {
    async userLogout() {
      this.$store.dispatch('MerchantPortal/deSelectLoanApplication');

      if (this.isLoggedIn && !this.loggingOut) {
        await this.$store.dispatch('Auth/userLogout');
        this.gotoRouteName('merchant-login');
      }
    },
  },
});
