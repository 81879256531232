<template>
  <v-container fluid class="merchant__admin portal__page portal__page--card-full-size">
    <merchant-portal-admin />
  </v-container>
</template>

<script lang="ts">
import MerchantPortalAdmin from '@/components/Merchant/Portal/Admin/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MerchantAdmin',
  components: {
    MerchantPortalAdmin,
  },
});
</script>
