<template>
  <standard-wizard-message
    data-test="decisionMsg"
    hide-wrapper>
    <template #body>
      <p>
        I'm sorry. We cannot decision your application.
      </p>
      <p>Possible complications include:</p>
      <ul class="bullet-list mb-5">
        <li>Mistyped information in your application.</li>
        <li>
          An inability to pull your credit report from Transunion,
          our credit bureau of record.
        </li>
      </ul>
      <p>
        If you feel you may have mistyped information on your
        application, feel free to try again. Otherwise contact
        Transunion at <a href="tel:(833)-395-6938">(833)-395-6938</a> before
        attempting.
      </p>
    </template>
  </standard-wizard-message>
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditNonDecisionMsg',
  components: {
    StandardWizardMessage,
  },
  data() {
    return {
      errorMessage: 'We’re sorry. We were not able to decision your application and you cannot proceed.',
    };
  },
});
</script>

<style lang="scss" scoped>
.bullet-list {
  list-style: disc;
  padding-left: 2rem;
}
</style>
