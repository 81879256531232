<template>
  <standard-dialog
    dialog
    :on-cta="toggleUser"
    :on-cancel="closeModal"
    :cta-disabled="processing"
    :title="title"
    button-label="Yes"
    data-test="deactivateModal">
    <template #body>
      <span v-if="active">
        <span v-private>{{ userNameLabel }}</span> will no longer be able to login to
        your merchant portal.
      </span>
      <span v-else>
        <span v-private>{{ userNameLabel }}</span> will be able to login to your merchant portal
        with the previously assigned permissions.
      </span>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { patchUserAtThisLocation, patchUserAtAllLocations } from '@/api/merchant';
import { PatchUserPayload } from '@/interfaces/merchantPortal/UserInterface';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'StatusToggle',

  components: {
    StandardDialog,
  },

  props: {
    userId: { type: Number, required: true },
    status: { type: String, required: true },
    firstName: { type: String, required: true },
    lastName: { type: String, required: true },
    allUsersTable: { type: Boolean, default: false },
  },

  data() {
    return {
      processing: false,
    };
  },

  computed: {
    merchantUuid(): string {
      return this.$store.getters['MerchantPortal/getMerchantUuid'];
    },
    active() {
      return this.status === 'Active';
    },
    userNameLabel() {
      return `${this.firstName} ${this.lastName}`;
    },
    title() {
      return `${this.active ? 'Deactivate' : 'Reactivate'} this user?`;
    },
  },

  methods: {
    async toggleUser() {
      const payload: PatchUserPayload = { status: this.active ? 'Inactive' : 'Active' };

      this.processing = true;
      try {
        const patchFn = this.allUsersTable ? patchUserAtAllLocations : patchUserAtThisLocation;
        await patchFn(this.merchantUuid, this.userId, payload);

        const successMessage = this.active ? 'User deactivated' : 'User reactivated';
        this.$store.dispatch('Ui/addGlobalTimedSuccess', successMessage);
        this.$emit('save');
      } catch (error) {
        this.$store.dispatch('Ui/addGlobalTimedError');
      } finally {
        this.processing = false;
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
@import "@/assets/scss/mixins/mixins";

.standard-data-table__actions__activate-modal {
  &--inactive {
    .btn {
      color: #000000;
      border-color: #000000;
    }
  }
}

.custom-button {
  @include user-management-custom-button;
}

</style>
