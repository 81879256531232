<template>
  <v-expansion-panel class="v-elevation-4 standard-collapsable-panel">
    <v-expansion-panel-title class="standard-collapsable-panel__title">
      <h3 class="subheader">
        User Management
      </h3>

      <template v-slot:actions="{ expanded }">
        <custom-button
          v-if="isMultilocation"
          :disabled="processing"
          class="add-new-user-btn"
          size="small"
          variant="text"
          data-test="addNewUserBtn"
          @click.stop="multilocationUserForm = true">
          <v-icon>mdi-plus</v-icon>
          Add New User
        </custom-button>
        <add-new-user v-else @click.stop />

        <v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
      </template>
    </v-expansion-panel-title>

    <v-expansion-panel-text
      class="standard-collapsable-panel__content"
      :class="{ multilocation: isMultilocation }">
      <multi-location-users-table v-if="isMultilocation" :key="multilocationComponentReloadKey" />
      <users-table v-else />
    </v-expansion-panel-text>

    <user-form
      v-if="multilocationUserForm"
      @close="multilocationUserForm = false"
      @save="onUserSave" />
  </v-expansion-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AddNewUser from '@/components/Merchant/Portal/Admin/UserManagement/AddNewUser.vue';
import GetProcessing from '@/mixins/GetProcessing';
import MultiLocationUsersTable from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/UsersTable.vue';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/UserForm.vue';
import UsersTable from '@/components/Merchant/Portal/Admin/UserManagement/UsersTable.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'UserManagement',
  components: {
    AddNewUser,
    CustomButton,
    MultiLocationUsersTable,
    UserForm,
    UsersTable,
  },
  mixins: [GetProcessing, FeatureFlagsMixin],
  data() {
    return {
      multilocationUserForm: false,
      multilocationComponentReloadKey: 0,
    };
  },
  computed: {
    isMultilocation(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.MULTI_LOCATION)
        && !!this.$store.getters['MerchantPortal/getMerchantLocation'];
    },
  },
  methods: {
    onUserSave() {
      this.multilocationComponentReloadKey += 1;
      this.multilocationUserForm = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.standard-collapsable-panel {
  &__title {
    .add-new-user-btn {
      .v-btn__content i.v-icon {
        font-size: 1.4rem;
        color: var(--primary-color);
        transform: none;
      }
    }

    :deep(.v-expansion-panel-title__icon) {
      align-items: center;
    }

    :deep(.v-expansion-panel-title__overlay) {
      opacity: 0;
    }
  }
}

.multilocation {
  border-top: none !important;

  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0 !important;
  }
}

@include vuetify-xs-width-max {
  :deep() {
    .v-expansion-panel-text {
      &__wrapper {
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  :deep() {
    .v-expansion-panel-text {
      &__wrapper {
        padding: 0 !important;
      }
    }
  }
}
</style>
