<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--six" data-test="COB-step3">
    <bubble-card>
      <template #card-title>
        <h5 data-test="sixDigitHeaderLabel">
          Six Digit Code
        </h5>
      </template>

      <div v-if="!showCreditFrozenMessage">
        <v-card-text class="pa-0">
          <standard-input-otp
            :invitation-uuid="invitationUuid"
            :next-step="nextStep"
            :on-phone-number-updated="updatePhoneNumber"
            :phone-number="phoneNumber"
            :session-token="otpRegisterSessionToken"
            :single-input="true"
            :verify-code="setSecurityCode"
            :consumer-otp="true"
            :otp-message="otpMessage"
            :show-header="showHeader"
            autofocus-enabled />
        </v-card-text>

        <consumer-loan-apply-wizard-existing-applications-modal
          :dialog="show"
          :on-cta="verifyCode"
          :on-cancel="closeModal" />
      </div>
      <credit-freeze-re-entry v-else @continue="onCreditUnfrozen" />
    </bubble-card>
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import ConsumerLoanApplyWizardExistingApplicationsModal
  from '@/components/Consumer/LoanApplyWizard/Messages/ExistingApplicationModal.vue';
import StandardInputOtp from '@/components/Inputs/Otp/index.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import GetExistingApplicationsMixin from '@/mixins/Consumer/LoanApply/GetExistingApplicationsMixin';
import { postConsentTypes } from '@/utils/Consents';
import OtpMixin from '@/mixins/OtpMixin';
import { get } from 'lodash';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import InvitationApplicationStatusDetails
  from '@/enums/Consumer/InvitationApplicationStatusDetails';
import CreditFreezeReEntry
  from '@/components/Consumer/LoanApplyWizard/Messages/CreditFreezeReEntry.vue';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepThree',
  components: {
    BubbleCard,
    CreditFreezeReEntry,
    StandardInputOtp,
    ConsumerLoanApplyWizardExistingApplicationsModal,
  },
  mixins: [
    GetExistingApplicationsMixin,
    OtpMixin,
    ReEntryMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
  ],
  props: {
    showHeader: { type: Boolean, default: false },
  },
  data() {
    return {
      show: false,
      ownStep: 3,
      securityCode: '',
      showCreditFrozenMessage: false,
      disableBtn: false,
      hpEventName: 'OTP Page Visit',
      hpStep: 3,
    };
  },
  computed: {
    otpMessage() {
      return this.isReEntry ? 'Resend Verification Code' : "I didn't receive a text message";
    },
    loanApplyWizardData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    phone(): string {
      return get(this.loanApplyWizardData, 'phone_number', '');
    },
    phoneNumber(): string {
      return this.phone || this.invitation.phone as string;
    },
    requestedLoanAmount(): string | number {
      return get(this.loanApplyWizardData, 'requested_loan_amount', '');
    },
    email(): string {
      return get(this.loanApplyWizardData, 'email', '');
    },
    invitationUuid(): string {
      return this.invitation.uuid;
    },
    merchantUuid(): string {
      return this.invitation.merchant;
    },
    consumerApplicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
  },

  mounted() {
    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },

  methods: {
    updatePhoneNumber(phoneNumber: string): void {
      if (!this.isReEntry) {
        this.$store.commit(
          'Consumer/setLoanApplyWizardData',
          {
            ...this.loanApplyWizardData,
            phone_number: phoneNumber,
          },
        );
      } else {
        this.$store.commit(
          'Consumer/setInvitation',
          {
            ...this.invitation,
            phone: phoneNumber,
          },
        );
      }
    },
    async nextStep(securityCode: string): Promise<void> {
      this.$store.commit('Consumer/setOtpSecurityCode', securityCode);
    },
    async setSecurityCode(securityCode: string): Promise<void> {
      this.securityCode = securityCode;

      await this.verifyCode();

      this.disableBtn = false;
    },
    async verifyCode(): Promise<void> {
      this.closeModal();

      if (this.isReEntry) {
        await this.reEntryWorkflow();
      } else {
        const onboardPayload = {
          requestedLoanAmount: this.requestedLoanAmount,
          email: this.email,
          phoneNumber: this.phone,
          merchant: this.merchantUuid,
          sessionToken: this.otpRegisterSessionToken,
          securityCode: this.securityCode,
        };
        const { status } = await this.$store.dispatch('Consumer/onboard', onboardPayload);

        if (status === 201) {
          this.goToStep(this.ownStep + 1);
          await this.updateConsents();
        } else if (status === 400 && this.existingApplications.length > 0) {
          this.showModal();
        }
      }
    },
    async reEntryWorkflow() {
      const { error } = await this.$store.dispatch(
        'Consumer/reEntryVerify',
        {
          phoneNumber: this.invitation.phone,
          sessionToken: this.otpRegisterSessionToken,
          securityCode: this.securityCode,
        },
      );
      if (!error) {
        await this.$store.dispatch('Consumer/getExistingApplication');

        if ([
          InvitationApplicationStatusDetails.SOFT_PULL_FROZEN_CREDIT_FILE,
          InvitationApplicationStatusDetails.HARD_PULL_FROZEN_CREDIT_FILE,
        ].includes(this.$store.getters['Consumer/getInvitation'].app_status_detail)) {
          this.showCreditFrozenMessage = true;
        } else {
          this.goToStep(this.ReEntryStep);
        }
      }
    },
    onCreditUnfrozen() {
      this.goToStep(this.ReEntryStep);
    },
    showModal(): void {
      this.show = true;
    },
    closeModal(): void {
      this.show = false;
    },
    async updateConsents(): Promise<number> {
      const consentTypes: Array<number> = [
        ConsentTypesEnum.TERMS_OF_USE,
        ConsentTypesEnum.PRIVACY_POLICY,
        ConsentTypesEnum.TCPA,
        ConsentTypesEnum.ELECTRONIC_COMMUNICATION_CONSENT,
        ConsentTypesEnum.E_SIGN_DISCLOSURE_AND_CONSENT,
      ];

      const consumer_application_uuid = this.$store.getters[
        'Consumer/getConsumerApplicationId'
      ];

      return postConsentTypes({
        consentTypes,
        consumer_application_uuid,
        consentedOn: true,
        entity: ConsentEntityTypes.CONSUMER,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.standard-wizard {
  &__step {
    &--five {
      @include standard-wizard-form-inline-split;
    }
  }

  .continue-btn {
    margin-top: 1rem;
  }
}
</style>
