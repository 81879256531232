<template>
  <div class="standard-wizard__step standard-wizard__step--two">
    <v-card>
      <v-card-title>Thank you!</v-card-title>
      <v-card-text class="pt-5">
        <p class="my-5">
          Your Application has been sent.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepThree',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/consumer-loan-application-wizard";

.standard-wizard {
  &__step {
    &--two {
      width: 22.5rem;
      height: 9.375rem;
      margin-bottom: 0;
    }
  }
}
</style>
