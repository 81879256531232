import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0948f926"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, _mergeProps(_ctx.$attrs, {
    "data-test": _ctx.dataTestAttr,
    disabled: _ctx.disabled,
    label: _ctx.labelAttr,
    variant: _ctx.variantAttr,
    "prepend-inner-icon": _ctx.showIcon ? 'mdi-email' : '',
    required: _ctx.required,
    rules: [_ctx.validateEmail],
    "error-messages": _ctx.errorMessages,
    type: "email",
    "validate-on": "blur"
  }), {
    message: _withCtx(({ message }) => [
      _createElementVNode("span", null, _toDisplayString(message), 1),
      (_ctx.externalValidation && _ctx.externalValidation.suggestion)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createTextVNode(" Did you mean "),
            _createElementVNode("button", {
              style: {"color":"var(--primary-color)"},
              tabindex: "0",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.acceptSuggestion && _ctx.acceptSuggestion(...args)))
            }, _toDisplayString(_ctx.externalValidation.suggestion), 1),
            _createTextVNode("? ")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["data-test", "disabled", "label", "variant", "prepend-inner-icon", "required", "rules", "error-messages"]))
}