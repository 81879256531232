<template>
  <div>
    <v-form class="customer-summary-card-send" @submit.prevent>
      <currency-input
        v-model="amount"
        allow-cents
        data-test="inputAmount"
        :disabled="disableInputs"
        class="amount-input"
        :error-messages="customErrorMessage.amount"
        label="Pay Request Amount"
        :max="creditBalance"
        :min="0"
        show-icon />

      <select-input
        v-model="transactionCode"
        :items="transactionDescriptions"
        :disabled="disableInputs"
        data-test="inputTransactionDesc"
        class="transaction-description-input old-v-select"
        hide-dropdown-icon
        item-title="name"
        item-value="transaction_code"
        label="Transaction Description" />

      <custom-button
        :disabled="sendDisabled"
        class="customer-summary-card-send__cta"
        data-test="sendButton"
        type="submit"
        @click="showCustomerSummarySendRequestModal">
        Send
      </custom-button>
    </v-form>

    <customer-summary-send-request-modal
      v-if="consumerApplication && requestData"
      :dialog="showSendRequest"
      :on-cta="closeCustomerSummarySendRequestModal"
      :on-cancel="closeCustomerSummarySendRequestModal"
      :request-data="requestData"
      :selected-loan-application="selectedLoanApplication" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SelectInput from '@/components/Inputs/Select.vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomerSummarySendRequestModal
  from '@/components/CustomerSummaryCard/Modals/SendRequestModal.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import LoanApplicationStatusesEnum from '@/enums/Consumer/LoanApplicationStatusesEnum';
import { PaymentRequestData } from '@/interfaces/merchantPortal/PaymentRequestData';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import GetProcessing from '@/mixins/GetProcessing';

export default defineComponent({
  name: 'SendTransaction',

  components: {
    SelectInput,
    CurrencyInput,
    CustomButton,
    CustomerSummarySendRequestModal,
  },

  mixins: [
    GetProcessing,
  ],

  props: {
    selectedLoan: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      disableInputs: true,
      showSendRequest: false,
      amount: '',
      transactionCode: null as number | null,
    };
  },

  computed: {
    creditBalance(): number {
      return Number(this.selectedLoanApplication.consumer_account.available_spend || 0);
    },

    customErrorMessage(): ErrorsInterface {
      const errors: ErrorsInterface = {};

      if (Number(this.amount) > this.creditBalance) {
        errors.amount = [`Transaction cannot exceed ${CurrencyFormatLong(this.creditBalance)}`];
      }

      return errors;
    },

    requestData(): PaymentRequestData | null {
      if (!this.transactionCode) return null;

      return {
        consumer_application_uuid: this.selectedLoanApplication.consumer_application,
        amount: Number(this.amount),
        transaction_type: this.transactionCode,
        phone: this.selectedLoanApplication.phone_number,
      };
    },

    selectedLoanApplication() {
      return this.$store.getters['MerchantPortal/getSelectedLoanApplication'];
    },

    applicationSelected() {
      return this.selectedLoanApplication.consumer_application;
    },

    transactionDescriptions() {
      return this.$store.getters['MerchantPortal/getTransactionsDescriptions'];
    },

    consumerApplication() {
      return this.selectedLoanApplication.consumer_application;
    },

    sendDisabled() {
      return this.processing
        || !this.consumerApplication
        || !this.creditBalance
        || !this.transactionCode
        || Number(this.amount) <= 0
        || Number(this.amount) > this.creditBalance;
    },
  },

  watch: {
    selectedLoan: {
      immediate: true,
      handler(newVal: any) {
        this.clearData();
        this.disableInputs = newVal.status !== LoanApplicationStatusesEnum.APPROVED_ACCEPTED;
      },
    },
  },

  async created() {
    await this.fetchTransactionsDescriptions();
  },

  methods: {
    clearData() {
      this.amount = '';
      this.transactionCode = null;
    },

    showCustomerSummarySendRequestModal() {
      this.showSendRequest = true;
    },

    closeCustomerSummarySendRequestModal() {
      this.clearData();
      this.showSendRequest = false;
    },

    fetchTransactionsDescriptions(): Promise<any> {
      return this.$store.dispatch('MerchantPortal/dispatchFetchTransactionsTypes');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.customer-summary-card-send {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  &__cta {
    margin-top: auto;
    margin-bottom: 1rem;
  }
}

.amount-input {
  margin: 0 !important;

  :deep(.v-text-field__details) {
    margin-bottom: 1.125rem !important;
  }
}

.transaction-description-input {
  margin: 0 !important;

  :deep(.v-text-field__details) {
    margin-bottom: 0.625rem !important;
  }
}
</style>
