import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f983b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "data-test": "createAccountClickLabel" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_password_input = _resolveComponent("password-input")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_custom_alert, {
          key: 0,
          type: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.error), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_password_input, {
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
      label: "Create Password",
      "password-verify": _ctx.passwordVerify,
      "show-requirements-tooltip": ""
    }, null, 8, ["modelValue", "password-verify"]),
    _createVNode(_component_password_input, {
      modelValue: _ctx.passwordVerify,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.passwordVerify) = $event)),
      rules: [_ctx.passwordValidation],
      label: "Verify Password"
    }, null, 8, ["modelValue", "rules"]),
    _createElementVNode("p", _hoisted_1, [
      _createTextVNode(" By clicking “Create Account” you accept the terms of the "),
      _createElementVNode("a", {
        href: _ctx.MOOV_AGREEMENT_URL,
        target: "_blank",
        rel: "noopener noreferrer"
      }, " User Terms ", 8, _hoisted_2),
      _createTextVNode(". ")
    ]),
    _createVNode(_component_custom_button, {
      disabled: !_ctx.isFormValid,
      "full-width": "",
      type: "button",
      onClick: _ctx.onSubmit
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Create Account ")
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}