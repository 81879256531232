<template>
  <simple-card :class="classList">
    <template #body>
      <rate-sheet-options-data-table
        ref="rateSheetDataTable"
        v-model="inputVal"
        :show-header-info-on-mount="false"
        :items-per-page="10"
        :rate-sheet-options="rateSheetOptions"
        :show-header="false"
        :show-legend="false"
        :button-toggle="buttonToggle"
        additional-product-offerings
        :disable-add-btns="disableAddBtns"
        :mobile-breakpoint="mobileBreakpoint"
        :is-from-offer-codes="isFromOfferCodes"
        :table-data-test="dataTestAttr"
        :ratesheet-table="true"
        @toggle="$emit('toggle', $event)" />
    </template>
  </simple-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RateSheetOptionsDataTable from '@/components/RateSheetOptions/DataTable/index.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import GetProcessing from '@/mixins/GetProcessing';
import StandardInputMixin from '@/mixins/StandardInputMixin';
import { get } from 'lodash';

export default defineComponent({
  name: 'RateSheetTables',

  components: {
    RateSheetOptionsDataTable,
    SimpleCard,
  },

  mixins: [
    GetProcessing,
    StandardInputMixin,
  ],

  props: {
    rateSheetOptions: { type: Array<any>, default: () => [] },
    onSelectItem: { type: Function, default: () => null },
    buttonToggle: Boolean,
    disableAddBtns: Boolean,
    rateSheet: { type: Object, default: () => ({}) },
    mobileBreakpoint: { type: Number, default: 600 },
    isFromOfferCodes: Boolean,
    dataTestAttr: { type: String, default: '' },
  },

  data() {
    const selectedRates: Array<RateSheetData> = [];
    return {
      baseClass: 'rate-sheet-options-expandable-categories__card',
      selectedRates,
    };
  },

  computed: {
    cardsDuring(): any {
      const {
        promo_payment_header,
        promo_payment,
        promo_apr_header,
        promo_apr,
        promo_term_header,
        promo_term,
      } = get(this.$props.rateSheet, 'json_description', {});
      return [
        {
          header: promo_payment_header,
          value: promo_payment,
        },
        {
          header: promo_apr_header,
          value: promo_apr,
        },
        {
          header: promo_term_header,
          value: promo_term,
        },
      ];
    },

    footerHeaderLabel(): string {
      const prefix: string = get(
        this.$props.rateSheet,
        'json_description.total_term_header',
        '',
      );
      const suffix: string = get(
        this.$props.rateSheet,
        'json_description.total_term',
        '',
      );

      return `${prefix}: ${suffix}`;
    },

    cardsAfter(): any {
      const {
        standard_payment_header,
        standard_payment,
        standard_apr_header,
        standard_apr,
        standard_term_header,
        standard_term,
      } = get(this.$props.rateSheet, 'json_description', {});
      return [
        {
          header: standard_payment_header,
          value: standard_payment,
        },
        {
          header: standard_apr_header,
          value: standard_apr,
        },
        {
          header: standard_term_header,
          value: standard_term,
        },
      ];
    },

    classList() {
      const classList: Array<string> = [
        this.baseClass,
      ];

      return classList.join(' ');
    },
  },

  methods: {
    async selectItem(rateSheetOption: RateSheetData): Promise<void> {
      this.$props.onSelectItem(rateSheetOption);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";

$padding: 1rem;

.rate-sheet-options-expandable-categories {
  &__card {
    @include box-glow-default;
    margin-bottom: 2rem;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas: "header" "body" "footer";
    color: var(--secondary-color);

    &__header {
      padding: $padding;
      display: grid;
      grid-template-columns: max-content minmax(0, 1fr) max-content;
      grid-template-areas: "select title more";
      grid-area: header;
      align-items: center;

      &__select {
        grid-area: select;
      }

      &__title {
        @include ellipsis;
        text-align: center;
        grid-area: title;
        font-weight: 900;
        font-size: $font-size-standard;
        line-height: 150%;
        color: inherit;
      }

      &__more {
        grid-area: more;
      }
    }

    &__body {
      grid-area: body;
      padding: 0;

      &__header {
        font-size: .75rem;
        line-height: 150%;
        text-align: center;
        color: inherit;
      }

      &__cards {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 1rem;
        margin-top: 1rem;
      }

      &--during,
      &--after {
        text-align: center;
        padding: $padding;
        min-height: 6rem;
      }

      &--during {
        background-color: #e7f5fd;
      }

      &--after {
        background-color: var(--grayscale-color-4);
      }
    }

    &__footer {
      text-align: center;
      grid-area: footer;
      padding: $padding;

      &__info,
      &__header {
        font-size: .75rem;
        line-height: 150%;
        color: inherit;
      }

      &__header {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      &__info {
        font-weight: normal;
      }
    }
  }
}
</style>
