<template>
  <div class="fs-unmask merchant-portal-page merchant-portal-page--admin">
    <v-expansion-panels v-model="panel">
      <merchant-portal-admin-user-management class="mb-6" />

      <merchant-portal-admin-payment-settings v-if="merchantData && !isSalesManager" />
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import MerchantPortalAdminPaymentSettings
  from '@/components/Merchant/Portal/Admin/PaymentSettings.vue';
import MerchantPortalAdminUserManagement
  from '@/components/Merchant/Portal/Admin/UserManagement/index.vue';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import BANK_ACCOUNT_STATUSES from '@/constants/PaymentConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MerchantPortalAdmin',
  components: {
    MerchantPortalAdminPaymentSettings,
    MerchantPortalAdminUserManagement,
  },
  data() {
    return {
      panel: null as number|null,
    };
  },
  computed: {
    merchantData(): any {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },
    isSalesManager() {
      return this.$store.getters['MerchantPortal/getMerchantData']?.user_type.name === 'merchant-sales-manager' || false;
    },
    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
    expandPanelByDefault(): boolean {
      return this.paymentProviders?.moov?.status !== BANK_ACCOUNT_STATUSES.NEW
      || this.paymentProviders?.moov?.status !== BANK_ACCOUNT_STATUSES.VERIFIED
      || this.paymentProviders?.moov?.status !== BANK_ACCOUNT_STATUSES.CONNECTED
      || this.paymentProviders?.repay?.status === BANK_ACCOUNT_STATUSES.DISCONNECTED;
    },
  },
  watch: {
    expandPanelByDefault: {
      handler(val: boolean) {
        if (val) {
          this.panel = 1;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.$route.query.target === 'users') {
      this.panel = 0;
    }

    if (this.$route.query.target === 'payments') {
      this.panel = 1;
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/merchant-portal-page";
@import "@/assets/scss/standard-collapsable-panel";

</style>
