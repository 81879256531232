<template>
  <standard-dialog
    dialog
    :on-cta="saveUser"
    :cta-disabled="buttonDisabled"
    :on-cancel="closeModal"
    button-label="Save">
    <template #body>
      <user-form
        :user="user"
        :form-data="formData"
        :user_type="user_type"
        :email-error="emailError"
        @updateFormData="formData = $event"
        @updateUserType="updateUserStatus"
        @emailUpdated="disableSaveButton = false" />
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { EditMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/EditMerchantUserPayloadInterface';
import { UserInterface } from '@/interfaces/merchantPortal/UserInterface';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import { get } from 'lodash';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/UserForm.vue';
import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';

export default defineComponent({
  name: 'EditUser',
  components: {
    StandardDialog,
    UserForm,
  },
  mixins: [GetProcessing, GetErrors],
  props: {
    user: { type: Object, required: true },
    onCta: { type: Function, default: () => null },
  },
  data() {
    return {
      formData: {} as UserInterface,
      user_type: '',
      disableSaveButton: false,
    };
  },
  computed: {
    buttonDisabled(): boolean {
      return this.processing || this.hasErrors || this.disableSaveButton;
    },
    hasErrors(): boolean {
      return !!this.errors?.non_field_errors;
    },
    isSalesManager() {
      return this.$store.getters['MerchantPortal/getMerchantData']?.user_type.name === 'merchant-sales-manager' || false;
    },
    emailError(): string {
      return this.errors.email ? this.errors.email[0] : '';
    },
  },
  created() {
    this.initUserData();
  },
  methods: {
    updateUserStatus(value:string) {
      this.user_type = value;
    },
    async saveUser(): Promise<void> {
      const {
        first_name,
        last_name,
        email,
        phone,
      } = this.formData as UserInterface;

      const payload: EditMerchantUserPayloadInterface = {
        userId: this.user.user.id,
        merchantUserId: this.user.id,
        phoneId: get(this.user, 'user.phonenumber_set[0].id', ''),
        first_name,
        last_name,
        email,
        phone_number: phone,
        user_type: this.user_type,
      };

      const response = await this.$store.dispatch('MerchantPortal/dispatchEditMerchantUser', payload);

      if (response === 200) {
        const text = 'Your Changes Have Been Saved';
        const type = GlobalAlertTypes.SUCCESS;

        const payload: GlobalAlertIface = { text, type, timed: true };
        this.$store.dispatch('Ui/addGlobalAlert', payload);

        this.onCta();
        this.$emit('save');
        this.closeModal();
      }

      if (this.emailError) {
        this.disableSaveButton = true;
      }
    },
    closeModal() {
      this.$emit('cancel');
    },
    initUserData(): void {
      this.formData = { ...this.user.user };
      const phone = get(this.$props.user, 'user.phonenumber_set[0].phone_number', '');
      this.formData.phone = phone;

      let userType = '';
      if (this.user.is_admin) {
        userType = 'Admin';
      } else if (this.user.is_salesmanager) {
        userType = 'Salesmanager';
      } else if (this.user.is_salesrep) {
        userType = 'Salesrep';
      } else {
        userType = 'Salesperson';
      }
      this.user_type = userType;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
@import "@/assets/scss/mixins/mixins";

.custom-button {
  @include user-management-custom-button;
}
</style>
