<template>
  <div class="rate-sheet-options-data-table-description-tooltip">
    <a
      class="rate-sheet-options-data-table-description-tooltip__activator"
      @click="showModal">
      {{ label }}
    </a>

    <standard-dialog
      :dialog="show"
      :show-actions="true"
      :on-cancel="closeModal"
      :is-explainer="true"
      header-style="text-align: left;"
      :title="title">
      <template
        v-if="info"
        #body>
        <p>{{ info }}</p>

        <p>{{ splitText }}</p>

        <p v-if="info2" class="border-up">
          {{ info2 }}
        </p>
      </template>
      <template #actions>
        <custom-button
          data-test="closeBtn"
          class="full-width"
          size="small"
          @click="closeModal">
          Close
        </custom-button>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'TooltipMessage',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    label: { default: 'default', type: String },
    info: { default: '', type: String },
    info2: { default: '', type: String },
    splitText: { default: '', type: String },
    title: { default: '', type: String },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showModal(): void {
      this.show = true;
    },
    closeModal(): void {
      this.show = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.rate-sheet-options-data-table-description-tooltip {
  display: inline;

  &__activator {
    color: var(--primary-color);
  }
}

.border-up {
  border-top: 1px solid var(--grayscale-color-3);
  padding: 1rem 0;
  margin-bottom: 0;
}
</style>
