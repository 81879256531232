import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74f61169"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_rate_sheets_data_table = _resolveComponent("merchant-rate-sheets-data-table")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_simple_card = _resolveComponent("simple-card")!

  return (_openBlock(), _createBlock(_component_simple_card, {
    class: "elevation-4 fs-unmask standard-wizard__step standard-wizard__step--rate-sheets",
    "data-test": "MOB-step5"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_merchant_rate_sheets_data_table, {
        modelValue: _ctx.selectedRates,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRates) = $event))
      }, null, 8, ["modelValue"])
    ]),
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_custom_button, {
          "full-width": "",
          class: "ma-0",
          disabled: _ctx.saveChangesButtonDisabled,
          "data-test": "saveChangesBtn",
          onClick: _ctx.saveChanges
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Save Changes To Rate Sheet ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }))
}