<template>
  <simple-card
    class="elevation-4 fs-unmask standard-wizard__step standard-wizard__step--rate-sheets"
    data-test="MOB-step5">
    <template #body>
      <merchant-rate-sheets-data-table v-model="selectedRates" />
    </template>
    <template #actions>
      <div class="button-container">
        <custom-button
          full-width
          class="ma-0"
          :disabled="saveChangesButtonDisabled"
          data-test="saveChangesBtn"
          @click="saveChanges">
          Save Changes To Rate Sheet
        </custom-button>
      </div>
    </template>
  </simple-card>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantRateSheetsDataTable from '@/components/Merchant/RateSheetsDataTable.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { RateSheetsPostDataInterface } from '@/interfaces/rates/RateSheetsPostDataInterface';
import GetRateSheetPostData from '@/utils/GetRateSheetPostData';
import { forEach, isEmpty } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepFive',

  components: {
    CustomButton,
    SimpleCard,
    MerchantRateSheetsDataTable,
  },

  data: () => ({
    selectedRates: [] as Array<RateSheetData>,
  }),

  computed: {
    rateSheets(): Array<RateSheetData> {
      return this.$store.getters[
        'MerchantOnboarding/getRateSheets'
      ];
    },

    rateSheetSelected(): boolean {
      return !isEmpty(this.selectedRates);
    },

    saveChangesButtonDisabled(): boolean {
      return !this.rateSheetSelected;
    },

    redirectUrlState(): any {
      return this.$store.getters['MerchantOnboarding/getRedirectUrl'];
    },

    portalLoginUrl(): string {
      return this.$store.getters['Ui/getPortalLoginUrl'];
    },
  },

  async created() {
    const status = await this.$store.dispatch('MerchantOnboarding/dispatchFetchRateSheet');

    if (status === 200) {
      if (!this.rateSheets.some(rs => rs.is_recommended)) {
        if (this.portalLoginUrl) {
          window.location.href = this.portalLoginUrl;
        } else {
          this.$router.push({ name: 'merchant-login' });
        }
      }
    }

    this.selectedRates = [...this.rateSheets];

    forEach(
      this.selectedRates,
      (rateSheet: RateSheetData, index: number) => {
        this.selectedRates[index].is_selected = rateSheet.is_recommended;
      },
    );
  },

  methods: {
    async saveChanges() {
      const selectedRates: RateSheetsPostDataInterface = GetRateSheetPostData(
        this.selectedRates,
      );

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchSelectRates',
        selectedRates,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post Rate Sheet/ Pre-Login',
      );

      if (responseStatus === 201) {
        if (this.redirectUrlState === '') {
          this.$router.push({ name: 'merchant-login' });
        } else {
          window.location = this.redirectUrlState;
        }
      } else {
        this.$router.push({ name: 'merchant-login' });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

:deep(.simple-card__actions) {
  padding: 0;
}

.standard-wizard {
  position: relative;

  &__step {
    background-color: #fff;
    &--rate-sheets {
      @include box-border-default;

      :deep(.rate-sheets-options__data-table) {
        border: none;
      }

      .button-container {
        padding: 2rem 1rem;
        background: var(--grayscale-color-5);
        width: 100%;
      }

      .v-btn {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
</style>
