<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--one" data-test="COB-step1">
    <bubble-card>
      <template #card-title>
        <h5 data-test="howMuchHeaderLabel">
          How Much Do You Need?
        </h5>
      </template>

      <p data-test="howMuchParagraphLabel">
        Spend as much or as little as you’d like. You will only be responsible
        for your spend amount plus any related interest or fees.
      </p>

      <currency-input
        v-model="stepData.requested_loan_amount"
        data-test="inputAmount"
        :max="maxAmount"
        :min="minAmount"
        hide-details
        show-icon
        required
        class="input-field" />

      <div class="standard-consent-and-input-notation-text">
        <span data-test="amountRequestedSpanLabel">
          The amount requested must be minimum $3,100.00 and maximum $55,000.00.
        </span>
      </div>

      <custom-button
        :disabled="isDisabled || disabled"
        full-width
        data-test="continueBtn"
        @click="nextStep">
        Continue
      </custom-button>

      <div class="standard-consent-and-input-notation-text">
        <ul>
          <li data-test="amountYouSelectLabel">
            * Amount you select is subject to credit approval
          </li>
        </ul>
      </div>
    </bubble-card>
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepOne',
  components: {
    BubbleCard,
    CustomButton,
    CurrencyInput,
  },
  mixins: [NavigatesStepsMixin, HoneypotTrackMixin],
  data() {
    const stepData: LoanApplyDataInterface = {
      requested_loan_amount: '',
    };
    return {
      minAmount: 3100,
      maxAmount: 55000,
      disabled: false,
      stepData,
      ownStep: 1,
      hpEventName: 'Request Amount Page Visit',
      hpStep: 1,
    };
  },
  computed: {
    isDisabled(): boolean {
      const amount: number = Number(this.stepData.requested_loan_amount) || 0;

      return amount < this.minAmount || amount > this.maxAmount;
    },
  },
  mounted() {
    const { requested_amount } = this.$store.getters['Consumer/getInvitation'];

    if (requested_amount) {
      this.stepData.requested_loan_amount = requested_amount;
    }

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    async nextStep(): Promise<void> {
      this.disabled = true;
      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);
      this.disabled = false;
      this.goToStep(this.ownStep + 1);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.standard-wizard {
  &__step {
    margin: 0;

    &--one {
      :deep(h4) {
        margin-bottom: 2rem;
      }

      .input-field {
        :deep(.v-text-field__details) {
          display: none;
        }
      }

      .standard-consent-and-input-notation-text {
        color: var(--grayscale-color-1);
        margin-top: 1rem;
      }

      .standard-wizard-continue-button {
        margin-top: 2rem;
      }
    }
  }

  .custom-button {
    margin-top: 1rem;
  }
}

p {
  margin-bottom: 1.5rem;
}
</style>
