import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, Transition as _Transition, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-805b33fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "close-btn-wrap"
}
const _hoisted_2 = { class: "buttons-wrap__btn-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_standard_progress_indicator = _resolveComponent("standard-progress-indicator")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialog,
    "content-class": _ctx.contentClass,
    width: _ctx.width,
    persistent: _ctx.persistent,
    "data-test": _ctx.dataTest,
    "onClick:outside": _ctx.onClickOutside,
    "onUpdate:modelValue": _ctx.onClickOutside
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: _normalizeClass(["dialog-wrap fs-unmask", _ctx.cardClass])
      }, {
        default: _withCtx(() => [
          (!_ctx.hideHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["dialog-title-wrap", [_ctx.updateTitleBcgColor ? 'title-bcg-color' : '']]),
                style: _normalizeStyle(_ctx.headerStyle)
              }, [
                _createVNode(_component_v_card_title, {
                  class: _normalizeClass(_ctx.titleClass),
                  "data-test": "dialogTitleLabel"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "title", {}, () => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
                    ], true)
                  ]),
                  _: 3
                }, 8, ["class"]),
                (!_ctx.hideHeaderClose)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_v_btn, {
                        icon: "mdi-close",
                        size: "small",
                        variant: "plain",
                        color: "var(--grayscale-color-1)",
                        "aria-label": "Close",
                        "data-test": "closeIcon",
                        onClick: _ctx.onCancelAction
                      }, null, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ], 6))
            : _createCommentVNode("", true),
          _createVNode(_component_v_card_text, {
            class: _normalizeClass([_ctx.bodyClass, "main-content-dialog"]),
            "data-test": "dialogTextLabel"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.bodyText), 1),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ]),
              _createVNode(_Transition, {
                mode: "out-in",
                name: "fade"
              }, {
                default: _withCtx(() => [
                  (_ctx.showProgressIndicator)
                    ? (_openBlock(), _createBlock(_component_standard_progress_indicator, {
                        key: 0,
                        type: _ctx.progressIndicatorType
                      }, null, 8, ["type"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 3
          }, 8, ["class"]),
          (_ctx.showActions)
            ? (_openBlock(), _createBlock(_component_v_card_actions, {
                key: 1,
                class: "buttons-wrap"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createElementVNode("div", {
                    class: _normalizeClass(["buttons-wrap__btn-block", [
            _ctx.consumerReEntry || _ctx.isExplainer ? 'justify-end' : '',
            _ctx.wrappedActionButtons ? 'wrapped-action-buttons' : '',
          ]])
                  }, [
                    _renderSlot(_ctx.$slots, "actions", {}, () => [
                      (_ctx.showCancelCta)
                        ? (_openBlock(), _createBlock(_component_custom_button, {
                            key: 0,
                            "data-test": "cancelBtn",
                            size: "small",
                            variant: _ctx.secondaryCancelButton ? 'secondary' : 'primary',
                            disabled: _ctx.cancelCtaDisabled,
                            onClick: _ctx.onCancelAction
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.buttonCancelLabel), 1)
                            ]),
                            _: 1
                          }, 8, ["variant", "disabled", "onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.showClearCta)
                        ? (_openBlock(), _createBlock(_component_custom_button, {
                            key: 1,
                            "data-test": "clearBtn",
                            size: "small",
                            disabled: _ctx.clearDisabled,
                            onClick: _ctx.onClear
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.buttonClearLabel), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled", "onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.showCancelCta || _ctx.showClearCta)
                        ? (_openBlock(), _createBlock(_component_custom_button, {
                            key: 2,
                            "data-test": _ctx.ctaBtnDataTest,
                            size: "small",
                            disabled: _ctx.ctaDisabled,
                            onClick: _ctx.onCta
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
                            ]),
                            _: 1
                          }, 8, ["data-test", "disabled", "onClick"]))
                        : (_openBlock(), _createBlock(_component_custom_button, {
                            key: 3,
                            "full-width": "",
                            class: _normalizeClass(_ctx.consumerReEntry ? 'btnWidth' : ''),
                            "data-test": _ctx.ctaBtnDataTest,
                            size: _ctx.buttonSize,
                            disabled: _ctx.ctaDisabled,
                            onClick: _ctx.onCta
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
                            ]),
                            _: 1
                          }, 8, ["class", "data-test", "size", "disabled", "onClick"]))
                    ], true)
                  ], 2)
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"]),
      _createVNode(_component_v_dialog, {
        "model-value": _ctx.showCloseConfirmation && _ctx.showConfirm,
        "content-class": "confirmDialog",
        width: "400"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "dialog-wrap" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "dialog-title-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.closeConfirmTitleText), 1),
                  _createVNode(_component_v_btn, {
                    icon: "mdi-close",
                    variant: "plain",
                    size: "small",
                    onClick: _ctx.hideCloseConfirm
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.bodyClass, "main-content-dialog"]),
                    "data-test": "dialogTextLabel"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.closeConfirmBodyText), 1)
                  ], 2)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, { class: "buttons-wrap" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_custom_button, {
                      "data-test": "cancelCloseBtn",
                      size: "small",
                      onClick: _ctx.hideCloseConfirm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.closeConfirmationCancelText), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_custom_button, {
                      "data-test": "confirmCloseBtn",
                      size: "small",
                      onClick: _ctx.cancel
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.closeConfirmationConfirmText), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    _: 3
  }, 8, ["model-value", "content-class", "width", "persistent", "data-test", "onClick:outside", "onUpdate:modelValue"]))
}