<template>
  <div class="v-elevation-1 rate-sheet-options-expandable-categories__card__body__cards__card">
    <div class="rate-sheet-options-expandable-categories__card__body__cards__card__header">
      {{ card.header }}
    </div>

    <div v-private class="rate-sheet-options-expandable-categories__card__body__cards__card__value">
      {{ card.value }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardsCardDetails',

  props: {
    card: { type: Object, default: () => ({}) },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";

.rate-sheet-options-expandable-categories__card__body__cards__card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: $border-radius;
  padding: .5rem 1rem;

  &__header {
    text-align: center;
    color: inherit;
    font-weight: normal;
    font-size: .75rem;
    line-height: 150%;
  }

  &__value {
    text-align: center;
    color: inherit;
    font-weight: bold;
    font-size: 1rem;
    line-height: 150%;
  }
}
</style>
