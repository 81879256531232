<template>
  <div class="full-width px-0 simple-card__body-text">
    <rate-sheet-options-data-table
      ref="rateSheetDataTable"
      :rate-sheet-options="rateSheetsBase"
      is-from-offer-codes
      switch-toggle
      :show-header-info-on-mount="false"
      :show-header="false"
      :show-legend="true"
      merchant-onboarding
      header-title="Your Rate Sheet" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RateSheetOptionsDataTable from '@/components/RateSheetOptions/DataTable/index.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import GetProcessing from '@/mixins/GetProcessing';
import StandardInputMixin from '@/mixins/StandardInputMixin';

export default defineComponent({
  name: 'RateSheetsDataTable',

  components: { RateSheetOptionsDataTable },

  mixins: [GetProcessing, StandardInputMixin],

  computed: {
    rateSheets(): Array<RateSheetData> {
      return this.$store.getters[
        'MerchantOnboarding/getRateSheets'
      ];
    },

    rateSheetsBase(): Array<RateSheetData> {
      const rateSheetBaseArr: Array<RateSheetData> = [];
      this.rateSheets.forEach(el => {
        el.is_selected = true;
        if (el.is_recommended === true) {
          rateSheetBaseArr.push(el);
        }
        return rateSheetBaseArr;
      });
      return rateSheetBaseArr;
    },
  },
});
</script>
