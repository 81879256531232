<template>
  <div>
    <currency-input
      ref="amountInput"
      v-bind="$attrs"
      :append-inner-icon="disabled ? '' : 'mdi-help-circle'"
      :disabled="disabled"
      data-test="householdAnnualInput"
      label="Annual Income"
      :min="0"
      :required="isRequired"
      :model-value="modelValue"
      :hint="hint"
      @blur="checkAmount"
      @click:appendInner="showInfoDialog = true"
      @update:model-value="onAmountInput" />

    <info-dialog
      v-if="showInfoDialog"
      :show="true"
      @close="showInfoDialog = false" />

    <confirmation-dialog
      :show="showConfirmationDialog"
      :value="modelValue"
      @close="showConfirmationDialog = false"
      @confirm="confirmAmount"
      @edit="selectAmountField" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import { MIN_AGHI_VALUE } from '@/constants/AghiConstants';
import InfoDialog from './InfoDialog.vue';
import ConfirmationDialog from './ConfirmationDialog.vue';

export default defineComponent({
  name: 'AnnualGross',
  components: {
    ConfirmationDialog,
    CurrencyInput,
    InfoDialog,
  },
  props: {
    modelValue: { required: true, default: '', type: [String, Number] },
    initialModal: { default: false, type: Boolean },
    hint: { default: '', type: String },
    disabled: { default: false, type: Boolean },
  },
  data() {
    return {
      showInfoDialog: false,
      amountConfirmed: false,
      showConfirmationDialog: false,
    };
  },
  computed: {
    isRequired(): boolean {
      return !this.initialModal || !this.showInfoDialog;
    },
  },
  mounted() {
    this.$el.querySelector('input')?.classList.add('fs-unmask'); // FullStory
    if (this.initialModal) this.showInfoDialog = true;
  },
  methods: {
    checkAmount() {
      if (!this.modelValue || Number(this.modelValue) >= MIN_AGHI_VALUE) return;
      if (this.amountConfirmed) return;
      this.showConfirmationDialog = true;
    },
    confirmAmount() {
      this.amountConfirmed = true;
      this.showConfirmationDialog = false;
      this.$emit('amount-confirmed');
    },
    selectAmountField() {
      this.showConfirmationDialog = false;
      const inputField: HTMLInputElement = (this.$refs.amountInput as any).$el.querySelector('input');
      this.$nextTick(() => inputField.select());
    },
    onAmountInput(value: number) {
      this.$emit('update:model-value', value);
      this.amountConfirmed = false;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep() {
  .v-input__icon > .v-icon {
    color: var(--grayscale-color-1);
  }
}
</style>
