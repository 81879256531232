<template>
  <v-text-field
    v-mask="inputMask"
    v-bind="$attrs"
    autocomplete="one-time-code"
    :data-test="defaultDataTestAttr"
    inputmode="numeric"
    :variant="inPlace ? 'plain' : 'outlined'"
    :required="required"
    :label="labelAttr"
    :rules="[otpValidation]"
    validate-on="blur" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import isOtp from '@/validators/otp_single_input';

export default defineComponent({
  name: 'OtpInput',

  mixins: [
    InputFieldMixin,
  ],

  data() {
    const inputMask: any = {
      mask: '999999',
      autoUnmask: true,
    };
    return {
      defaultDataTestAttr: 'otpSingleInput',
      defaultLabelAttr: 'One-Time Passcode',
      inputMask,
    };
  },

  methods: {
    otpValidation(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      if (!isOtp(value)) return 'Please correct OTP format';

      return true;
    },
  },
});
</script>
