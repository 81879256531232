import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b25bd910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-unmask merchant-portal-page merchant-portal-page--admin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_portal_admin_user_management = _resolveComponent("merchant-portal-admin-user-management")!
  const _component_merchant_portal_admin_payment_settings = _resolveComponent("merchant-portal-admin-payment-settings")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_expansion_panels, {
      modelValue: _ctx.panel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.panel) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_merchant_portal_admin_user_management, { class: "mb-6" }),
        (_ctx.merchantData && !_ctx.isSalesManager)
          ? (_openBlock(), _createBlock(_component_merchant_portal_admin_payment_settings, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}