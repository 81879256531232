<template>
  <v-dialog
    :model-value="dialog"
    :content-class="contentClass"
    :width="width"
    :persistent="persistent"
    :data-test="dataTest"
    @click:outside="onClickOutside"
    @update:model-value="onClickOutside">
    <v-card class="dialog-wrap fs-unmask" :class="cardClass">
      <div
        v-if="!hideHeader"
        class="dialog-title-wrap"
        :class="[updateTitleBcgColor ? 'title-bcg-color' : '']"
        :style="headerStyle">
        <v-card-title :class="titleClass" data-test="dialogTitleLabel">
          <slot name="title">
            <span>{{ title }}</span>
          </slot>
        </v-card-title>

        <div v-if="!hideHeaderClose" class="close-btn-wrap">
          <v-btn
            icon="mdi-close"
            size="small"
            variant="plain"
            color="var(--grayscale-color-1)"
            aria-label="Close"
            data-test="closeIcon"
            @click="onCancelAction" />
        </div>
      </div>

      <v-card-text
        :class="bodyClass"
        class="main-content-dialog"
        data-test="dialogTextLabel">
        <span>{{ bodyText }}</span>

        <div>
          <slot name="body" />
        </div>

        <transition mode="out-in" name="fade">
          <standard-progress-indicator
            v-if="showProgressIndicator"
            :type="progressIndicatorType" />
        </transition>
      </v-card-text>

      <v-card-actions v-if="showActions" class="buttons-wrap">
        <v-spacer />
        <div
          class="buttons-wrap__btn-block"
          :class="[
            consumerReEntry || isExplainer ? 'justify-end' : '',
            wrappedActionButtons ? 'wrapped-action-buttons' : '',
          ]">
          <slot name="actions">
            <custom-button
              v-if="showCancelCta"
              data-test="cancelBtn"
              size="small"
              :variant="secondaryCancelButton ? 'secondary' : 'primary'"
              :disabled="cancelCtaDisabled"
              @click="onCancelAction">
              {{ buttonCancelLabel }}
            </custom-button>

            <custom-button
              v-if="showClearCta"
              data-test="clearBtn"
              size="small"
              :disabled="clearDisabled"
              @click="onClear">
              {{ buttonClearLabel }}
            </custom-button>

            <custom-button
              v-if="showCancelCta || showClearCta"
              :data-test="ctaBtnDataTest"
              size="small"
              :disabled="ctaDisabled"
              @click="onCta">
              {{ buttonLabel }}
            </custom-button>

            <custom-button
              v-else
              full-width
              :class="consumerReEntry ? 'btnWidth' : ''"
              :data-test="ctaBtnDataTest"
              :size="buttonSize"
              :disabled="ctaDisabled"
              @click="onCta">
              {{ buttonLabel }}
            </custom-button>
          </slot>
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog
      :model-value="showCloseConfirmation && showConfirm"
      content-class="confirmDialog"
      width="400">
      <v-card class="dialog-wrap">
        <v-card-title class="dialog-title-wrap">
          <span>{{ closeConfirmTitleText }}</span>
          <v-btn
            icon="mdi-close"
            variant="plain"
            size="small"
            @click="hideCloseConfirm" />
        </v-card-title>

        <v-card-text class="pa-0">
          <div
            :class="bodyClass"
            class="main-content-dialog"
            data-test="dialogTextLabel">
            <span>
              {{ closeConfirmBodyText }}
            </span>
          </div>
        </v-card-text>

        <v-card-actions class="buttons-wrap">
          <v-spacer />
          <div class="buttons-wrap__btn-block">
            <custom-button
              data-test="cancelCloseBtn"
              size="small"
              @click="hideCloseConfirm">
              {{ closeConfirmationCancelText }}
            </custom-button>
            <custom-button
              data-test="confirmCloseBtn"
              size="small"
              @click="cancel">
              {{ closeConfirmationConfirmText }}
            </custom-button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardProgressIndicator from '@/components/ProgressIndicator.vue';
import PROGRESS_INDICATOR_TYPES from '@/constants/StandardProgressIndicator';

export default defineComponent({
  name: 'StandardDialog',

  components: {
    CustomButton,
    StandardProgressIndicator,
  },

  props: {
    dialog: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideHeaderClose: { type: Boolean, default: false },
    title: { type: [String, Object], default: null },
    bodyText: { type: [String, Object], default: null },
    showActions: { type: Boolean, default: true },
    buttonLabel: { type: String, default: 'Confirm' },
    buttonCancelLabel: { type: String, default: 'Cancel' },
    buttonClearLabel: { type: String, default: 'Clear' },
    onCancel: { type: Function as PropType<() => void>, default: () => () => null },
    onClear: { type: Function as PropType<() => void>, default: () => () => null },
    onCta: { type: Function as PropType<() => void>, default: () => () => null },
    ctaBtnDataTest: { type: String, default: 'ctaBtn' },
    showProgressIndicator: { type: Boolean, default: false },
    progressIndicatorType: { type: String, default: PROGRESS_INDICATOR_TYPES.circular },
    width: { type: String, default: '500' },
    showCancelCta: { type: Boolean, default: true },
    showClearCta: { type: Boolean, default: false },
    ctaDisabled: { type: Boolean, default: false },
    cancelCtaDisabled: { type: Boolean, default: false },
    clearDisabled: { type: Boolean, default: false },
    persistent: { type: Boolean, default: false },
    showCloseConfirmation: { type: Boolean, default: false },
    closeConfirmationConfirmText: { type: String, default: null },
    closeConfirmationCancelText: { type: String, default: null },
    cardClass: { type: String, default: '' },
    dataTest: { type: String, default: '' },
    closeConfirmBodyText: { type: String, default: 'Are you sure?' },
    closeConfirmTitleText: { type: String, default: '' },
    lightTitle: { type: Boolean, default: false },
    contentClass: { type: String, default: '' },
    headerStyle: { type: String, default: '' },
    buttonSize: { type: String, default: 'large' },
    consumerReEntry: { type: Boolean, default: false },
    isExplainer: { type: Boolean, default: false },
    secondaryCancelButton: { type: Boolean, default: false },
    updateTitleBcgColor: { type: Boolean, default: false },
    wrappedActionButtons: { type: Boolean, default: false },
    grayBackgroundTitle: { type: Boolean, default: false },
  },

  data: () => ({
    showConfirm: false,
  }),

  computed: {
    titleClass(): string {
      if (this.grayBackgroundTitle) return 'gray-background';
      return this.lightTitle ? 'light-title' : 'standard-title';
    },

    bodyClass(): string {
      const bodyBaseClass = 'dialog-body-wrap';
      const classList: Array<string> = [bodyBaseClass];

      if (!this.title && !this.hideHeader) {
        classList.push(`${bodyBaseClass}--no-title-text`);
      }

      return classList.join(' ');
    },
  },

  methods: {
    onClickOutside(): any {
      if (this.persistent === false) {
        if (this.showCloseConfirmation) this.showCloseConfirm();
        else this.cancel();
      }
    },

    onCancelAction(): any {
      if (this.showCloseConfirmation) this.showCloseConfirm();
      else this.cancel();
    },

    cancel() {
      this.$emit('cancel');
      this.onCancel();
    },

    showCloseConfirm(): void {
      this.showConfirm = true;
    },

    hideCloseConfirm(): void {
      this.showConfirm = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.selected-loan-product .dialog-wrap {
  background-color: var(--grayscale-color-5);

  .main-content-dialog {
    margin-top: -2.8rem;
  }
}

.btnWidth {
  width: 100%;
  max-width: 8rem;
}

.change-default-bcg-color {
  background-color: var(--grayscale-color-5);
}

.title-bcg-color {
  background-color: var(--grayscale-color-5);
}
</style>
