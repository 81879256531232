<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--ten" data-test="COB-step10">
    <transition mode="out-in" name="slide">
      <consumer-loan-apply-wizard-processing-loan-offers v-if="fetchingData">
        <div>
          <p>Now pulling your credit and confirming your loan selection.</p>
          <p>
            Please remain on this screen and do not refresh your browser
            until this process has completed.
          </p>
        </div>
      </consumer-loan-apply-wizard-processing-loan-offers>

      <standard-wizard-message v-else-if="showGucaDecline" title="Application Cancelled">
        <template #body>
          <p>Based on the information you provided you cannot proceed.</p>
        </template>
      </standard-wizard-message>

      <consumer-loan-apply-wizard-borrower-agreement-specifics-state-error-msg
        v-else-if="borrowerAgreementSpecificStateError" />

      <consumer-loan-apply-credit-freeze-error v-else-if="creditFrozen && !isCaptureIdEnabled" />

      <consumer-loan-apply-wizard-credit-engine-hard-decline-msg
        v-else-if="(hardDeclined || hardHardDeclined) && !isCaptureIdEnabled || gucaCheckDecline" />

      <consumer-loan-apply-wizard-credit-engine-hard-approved-for-less-msg
        v-else-if="showHardApprovedForLessAan && !isCaptureIdEnabled"
        :on-consent="nextStep" />

      <consumer-loan-apply-credit-non-decision-msg v-else-if="isNonDecision" />

      <div v-else>
        <bubble-card>
          <template #card-title>
            <h5 data-test="congratsHeaderLabel">
              Congratulations!
            </h5>
          </template>

          <div v-if="bufferAmount">
            <p class="info-text" data-test="requestQualifiedLabel">
              You requested
              <span v-private>
                {{ CurrencyFormatLong(requestedLoanAmount) }}
              </span> and
              are pre-qualified for a line of credit up to
              <span v-private>{{ CurrencyFormatLong(approvedAmountSoftPull) }}</span>.
              <tooltip-message
                label="Why is this more than I requested?"
                :info="bufferAmountText"
                :info2="bufferAmountText2"
                :split-text="bufferAmountSplitText"
                title="Your Credit Limit Includes a Buffer" />
            </p>

            <p class="border-up info-text" data-test="purchaseWindowLabel">
              All offers have a
              <tooltip-message
                label="5 month purchase window."
                :info="tooltipText"
                title="5 Month Purchase Window" />
              Compare payment schedules and make a selection below.
            </p>
          </div>

          <div v-else>
            <p class="info-text" data-test="requestQualifiedLabel">
              You requested <span v-private>
                {{ CurrencyFormatLong(requestedLoanAmount) }}
              </span> and
              are pre-qualified for a line of credit up to
              <span v-private>{{ CurrencyFormatLong(approvedAmountSoftPull) }}</span>.
            </p>

            <p class="border-up info-text" data-test="purchaseWindowLabel">
              All offers have a
              <tooltip-message
                label="5 month purchase window."
                :info="tooltipText"
                title="5 Month Purchase Window" />
              Please make a selection below.
            </p>
          </div>

          <rate-sheet-options-expandable-categories
            :option-selection="optionSelection"
            :value="selectedLoanOption"
            :on-select-item="onSelectLoanItem"
            :disabled="processingCreditEngine"
            :rate-sheet-options="loanOptions"
            :is-buffer-amount="bufferAmount"
            :requested-amount="requestedLoanAmount"
            :approved-amount="approvedAmountSoftPull" />

          <custom-button
            :disabled="nextStepDisabled"
            full-width
            @click="showModal">
            Continue
          </custom-button>

          <div class="standard-consent-and-input-notation-text" data-test="estPaymentLabel">
            <ul>
              <li>* Estimated payment amount may change depending on actual spend</li>
            </ul>
          </div>

          <consumer-loan-apply-wizard-selected-loan-product-confirmation-modal
            :dialog="show"
            :on-cta="processLoan"
            :on-cancel="closeModal"
            :rate-sheet-option="selectedLoanOption"
            :show-loader="showLoader" />
        </bubble-card>
      </div>
    </transition>

    <complete-later-button
      v-if="!fetchingData && !isAppInErrorState" />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyWizardBorrowerAgreementSpecificsStateErrorMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/BorrowerAgreementSpecificsStateErrorMsg.vue';
import ConsumerLoanApplyWizardCreditEngineHardApprovedForLessMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/HardApprovedForLessMsg.vue';
import ConsumerLoanApplyWizardCreditEngineHardDeclineMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/AAN/CreditEngine/HardDeclineMsg.vue';
import ConsumerLoanApplyCreditFreezeError
  from '@/components/Consumer/LoanApplyWizard/Messages/CreditFreezeError.vue';
import ConsumerLoanApplyWizardProcessingLoanOffers
  from '@/components/Consumer/LoanApplyWizard/Messages/ProcessingLoanOffers.vue';
import TooltipMessage
  from '@/components/Consumer/LoanApplyWizard/Messages/TooltipMessage.vue';
import ConsumerLoanApplyWizardSelectedLoanProductConfirmationModal
  from '@/components/Consumer/LoanApplyWizard/Messages/SelectedLoanProductConfirmation.vue';
import RateSheetOptionsExpandableCategories
  from '@/components/RateSheetOptions/ExpandableCategories/index.vue';
import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import CreditEngineHardPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullApprovedAmountMixin';
import CreditEngineSoftPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullApprovedAmountMixin';
import { get } from 'lodash';
import CreditEngineDecisions from '@/enums/CreditEngine/Decisions';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import GetProcessing from '@/mixins/GetProcessing';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import InvitationApplicationStatusDetails
  from '@/enums/Consumer/InvitationApplicationStatusDetails';
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import ConsumerLoanApplyCreditNonDecisionMsg from '@/components/Consumer/LoanApplyWizard/Messages/CreditNonDecisionMsg.vue';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepTen',
  components: {
    BubbleCard,
    CustomButton,
    CompleteLaterButton,
    StandardWizardMessage,
    ConsumerLoanApplyCreditFreezeError,
    ConsumerLoanApplyWizardProcessingLoanOffers,
    ConsumerLoanApplyWizardBorrowerAgreementSpecificsStateErrorMsg,
    ConsumerLoanApplyWizardCreditEngineHardDeclineMsg,
    ConsumerLoanApplyWizardCreditEngineHardApprovedForLessMsg,
    TooltipMessage,
    RateSheetOptionsExpandableCategories,
    ConsumerLoanApplyWizardSelectedLoanProductConfirmationModal,
    ConsumerLoanApplyCreditNonDecisionMsg,
  },
  mixins: [
    CreditEngineSoftPullApprovedAmountMixin,
    CreditEngineHardPullApprovedAmountMixin,
    ReEntryMixin,
    GetProcessing,
    FeatureFlagsMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
    IdleTimeoutMixin,
  ],
  data() {
    return {
      show: false,
      showLoader: false,
      fetchingData: false,
      optionSelection: true,
      gucaCheckDecline: false,
      showGucaDecline: false,
      disabled: false,
      tooltipText: 'The purchase window is the period of time following approval '
        + 'of your loan that you can spend the funds with the associated merchant. Your available '
        + 'spend is equal to the credit limit approved.',
      bufferAmountText: `We've added a buffer to the credit limit that you've requested.
        This extra amount allows you to spend more if you need to without requesting a credit limit
        increase.`,
      bufferAmountSplitText: `You will only be responsible for paying back the amount you spend during
        the purchase window, plus associated interest and fees.`,
      ownStep: 10,
      selectedLoanOption: {} as RateSheetData,
      hpEventName: 'Offers Page Visit',
      hpStep: 10,
    };
  },
  computed: {
    bufferAmountText2() {
      return `If you do not want the extra amount, you can request a credit limit
      decrease after you accept your loan by calling ${this.footerData.support_number}. `;
    },
    footerData(): any {
      return this.$store.getters['Ui/getFooterData'] || {};
    },
    loanApplyWizardData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    firstName(): string {
      return get(this.loanApplyWizardData, 'first_name', '');
    },
    borrowerAgreementSpecificStateError(): boolean {
      return this.$store.getters['Consumer/getBorrowerAgreementSpecificStateError'];
    },
    processingCreditEngine(): boolean {
      return this.$store.getters['Consumer/getProcessingCreditEngine'];
    },
    loanOptions(): Array<RateSheetData> {
      return this.$store.getters['Consumer/getLoanOptions'];
    },
    nextStepDisabled(): boolean {
      return !get(this.selectedLoanOption, 'uuid') || this.processingCreditEngine || this.disabled;
    },
    consumerApplicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
    requestedLoanAmount(): string | number {
      return this.loanApplyWizardData?.requested_loan_amount || '';
    },
    bufferAmount(): boolean {
      return this.approvedAmountSoftPull > Number(this.requestedLoanAmount);
    },
    hardDeclined(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.DECLINED;
    },
    hardHardDeclined(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.HARD_DECLINED;
    },
    hardApproved(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.APPROVED;
    },
    hardApprovedForLess(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.APPROVED_FOR_LESS;
    },
    creditFrozen(): boolean {
      const { decline_type } = get(this.creditEngineCheck, `[${CreditEnginePullTypes.HARD}]`, {});
      return decline_type === 'frozen_file';
    },
    isNonDecision(): boolean {
      const { decision } = get(this.creditEngineCheck, CreditEnginePullTypes.HARD, {});
      return decision === CreditEngineDecisions.NON_DECISION;
    },
    approvedAmountNotChanged(): boolean {
      return this.approvedAmountSoftPull === this.approvedAmountHardPull;
    },
    showHardApprovedForLessAan(): boolean {
      return this.hardApprovedForLess && !this.approvedAmountNotChanged;
    },
    isCaptureIdEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.CAPTURE_ID);
    },
    isLoanStackingEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.LOAN_STACKING);
    },
    isGucaEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.USE_GUCA);
    },
    consumerWizarData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    appId() {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
    isAppInErrorState(): boolean {
      return this.showGucaDecline || this.borrowerAgreementSpecificStateError
      || this.creditFrozen || this.isNonDecision
      || this.hardDeclined || this.hardHardDeclined || this.gucaCheckDecline
      || this.showHardApprovedForLessAan;
    },
  },
  async created() {
    if (this.isReEntry || this.invitation.reentry_pull_decision.soft_pull_approved_for_less === true) {
      if (this.appStatusDetail
        === InvitationApplicationStatusDetails.HARD_PULL_FROZEN_CREDIT_FILE) {
        this.fetchingData = true;
        await this.initiateHardPull();
        this.fetchingData = false;
        return;
      }

      this.fetchingData = true;
      await this.$store.dispatch('Consumer/getApprovedLoans', true);
      this.fetchingData = false;
      this.completeReEntry();
    }
  },
  async mounted() {
    await this.presentConsent([ConsentTypesEnum.CREDIT_REPORT_AUTHORIZATION], true);

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    CurrencyFormatLong,
    hardPull() {
      return this.$store.dispatch('Consumer/creditEnginePull', CreditEnginePullTypes.HARD);
    },
    async nextStep() {
      const { error } = await this.$store.dispatch('Consumer/saveSelectedLoan');

      this.$emit('hideCompleteLaterBtn', true);

      if (!error) {
        this.show = false;

        this.$emit('hideCompleteLaterBtn', false);

        this.goToStep(this.ownStep + 1);
      }
    },
    async showModal() {
      this.disabled = true;
      await this.presentConsent([ConsentTypesEnum.HARD_PULL_CONSENT], false);

      if (!this.isCaptureIdEnabled) {
        this.fetchLoanAgreement();
      }
      this.show = true;
    },
    async processLoan() {
      this.show = false;

      await this.presentConsent([ConsentTypesEnum.HARD_PULL_CONSENT], true);

      this.fetchingData = true;
      this.$emit('hideCompleteLaterBtn', true);

      await this.$store.dispatch(
        'Consumer/saveAllocationDecision',
        this.selectedLoanOption,
      );

      if (this.isGucaEnabled) {
        const gucaPayload = {
          consumer_application: this.appId,
          phone_number: this.consumerWizarData.phone_number,
          email: this.consumerWizarData.email,
          address: [{
            address_1: this.consumerWizarData.address_1,
            address_2: this.consumerWizarData.address_2,
            city: this.consumerWizarData.city,
            state: this.consumerWizarData.state,
            zip_code: this.consumerWizarData.zip_code,
          }],
          ssn: this.consumerWizarData.ssn,
          check_against_merchant: false,
          check_against_consumer: true,
        };

        const gucaCheck = await this.$store.dispatch('Auth/gucaCheckApi', gucaPayload);

        this.gucaCheckDecline = gucaCheck.data?.decline_application
          || (gucaCheck.data?.positive_hit && gucaCheck.data?.originating_merchant_hit === true);

        if (this.gucaCheckDecline) {
          await this.$store.dispatch('Consumer/declineApplication', this.appId);
          this.showGucaDecline = true;
          this.fetchingData = false;
          return;
        }
      }

      if (this.isLoanStackingEnabled) {
        const loanStacking = await this.$store.dispatch('Consumer/loanStacking');

        if (loanStacking.data?.decline_application) {
          await this.$store.dispatch('Consumer/declineApplication', this.appId);
          this.showGucaDecline = true;
          this.fetchingData = false;
          return;
        }
      }

      if (this.isCaptureIdEnabled) {
        this.goToStep(this.ownStep + 1);
        return;
      }

      await this.initiateHardPull();
    },
    closeModal() {
      this.show = false;
      this.disabled = false;
    },
    onSelectLoanItem(rateSheetOption: RateSheetData) {
      this.selectedLoanOption = rateSheetOption;
    },
    updateConsents(consents: Array<number>): Promise<number> {
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      return patchAvailableConsents(consents, appId, ConsentEntityTypes.CONSUMER);
    },
    fetchLoanAgreement() {
      return this.$store.dispatch('Consumer/getLoanAgreement');
    },
    async presentConsent(
      consentTypes: ConsentTypesEnum[],
      consentedOn: boolean,
    ): Promise<void> {
      const consumer_application_uuid = this.$store.getters['Consumer/getConsumerApplicationId'];

      await postConsentTypes({
        consentTypes,
        consumer_application_uuid,
        entity: ConsentEntityTypes.CONSUMER,
        consentedOn,
      });
    },
    async initiateHardPull() {
      const { error } = await this.hardPull();

      if (
        this.hardDeclined
        || this.hardHardDeclined
        || this.isNonDecision // Temporary solution to disable reentry more info: BELLE-4502
      ) {
        this.$emit('hideCompleteLaterBtn', true);
        this.fetchingData = false;
        return;
      }

      const consents = [ConsentTypesEnum.HARD_PULL_CONSENT];

      await this.updateConsents(consents);

      if (!error) {
        if (this.hardApproved || (this.hardApprovedForLess && this.approvedAmountNotChanged)) {
          this.$emit('hideCompleteLaterBtn', false);
          await this.nextStep();
        }
      }
      this.fetchingData = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom-transitions";
@import "@/assets/scss/standard-wizard";

.info-text {
  padding: 1rem 0;
  margin-bottom: 0;
}

.border-up {
  border-top: 1px solid var(--grayscale-color-3);
}

.custom-button {
  margin-top: 1rem;
}
</style>
