<template>
  <standard-dialog
    :dialog="show"
    :cta-disabled="ctaDisabled"
    content-class="v-dialog--overflow"
    :on-cancel="clearData"
    :show-cancel-cta="false"
    :on-cta="!passwordReset ? resetUserPassword : clearData"
    :show-progress-indicator="processing"
    :show-title="false"
    :button-label="btnLabel">
    <template #body>
      <div class="password-reset-container simple-card__body-text">
        <v-row v-if="!passwordReset">
          <v-col>
            <password-input
              v-model="oldPassword"
              autocomplete="current-password"
              :error-messages="errors.old_password"
              :show-password-while-typing="true"
              label="Old Password" />

            <password-input
              v-model="newPassword"
              autocomplete="new-password"
              label="New Password"
              :password-verify="passwordCheck"
              :error-messages="errors.password"
              :show-password-while-typing="true"
              show-requirements-tooltip
              @blur="resetErrorState" />

            <password-input
              v-model="passwordCheck"
              autocomplete="new-password"
              label="Verify New Password"
              :error-messages="errors.password_check"
              :password-verify="newPassword"
              :show-password-while-typing="true"
              @blur="resetErrorState" />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            <span>Your new password has been saved.</span>
          </v-col>
        </v-row>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { resetPassword } from '@/api/account';
import StandardDialog from '@/components/Dialogs/index.vue';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import password from '@/validators/password';
import PasswordInput from '@/components/Inputs/Password.vue';
import { ResetUserPasswordData } from '@/interfaces/merchantPortal/ResetUserPasswordData';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderModal',
  components: {
    PasswordInput,
    StandardDialog,
  },
  mixins: [
    GetErrors,
    GetProcessing,
  ],
  props: {
    show: { type: Boolean, default: false },
    onClose: { type: Function, default: () => null },
  },
  data() {
    return {
      passwordReset: false,
      newPassword: '',
      oldPassword: '',
      passwordCheck: '',
    };
  },
  computed: {
    ctaDisabled(): boolean {
      return !password(this.oldPassword)
        || this.newPassword !== this.passwordCheck
        || !password(this.newPassword);
    },
    btnLabel(): string {
      return this.passwordReset ? 'OK' : 'Save';
    },
  },
  methods: {
    async resetUserPassword() {
      const requestData: ResetUserPasswordData = {
        old_password: this.oldPassword,
        password: this.newPassword,
        password_check: this.passwordCheck,
      };
      const userId = this.$store.getters['Auth/getUserId'];
      if (!userId) return;

      try {
        await resetPassword(userId, requestData);
        this.passwordReset = true;
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
      }
    },
    clearData(): void {
      this.oldPassword = '';
      this.newPassword = '';
      this.passwordCheck = '';
      this.passwordReset = false;

      this.onClose();
    },
    resetErrorState() {
      this.$store.dispatch('Ui/resetErrorState');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/components/dialogs";
</style>
