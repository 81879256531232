import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c1e6f45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rate-sheet-options-data-table-description-tooltip" }
const _hoisted_2 = {
  key: 0,
  class: "border-up"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "rate-sheet-options-data-table-description-tooltip__activator",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)))
    }, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_standard_dialog, {
      dialog: _ctx.show,
      "show-actions": true,
      "on-cancel": _ctx.closeModal,
      "is-explainer": true,
      "header-style": "text-align: left;",
      title: _ctx.title
    }, _createSlots({
      actions: _withCtx(() => [
        _createVNode(_component_custom_button, {
          "data-test": "closeBtn",
          class: "full-width",
          size: "small",
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Close ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 2
    }, [
      (_ctx.info)
        ? {
            name: "body",
            fn: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.info), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.splitText), 1),
              (_ctx.info2)
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.info2), 1))
                : _createCommentVNode("", true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["dialog", "on-cancel", "title"])
  ]))
}