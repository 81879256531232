<template>
  <v-card
    :class="[classList, fromVerifyDetails ? 'mx-0' : 'mx-4']"
    class="owners-list__item pa-0"
    :elevation="cardElevation">
    <div class="owners-list__item__title py-3">
      <div>
        <div v-if="activeOwner !== index && !inPlaceInput" class="owner-section">
          <div class="owner-count">
            Owner {{ numberToEnglish(index + 1) }}
          </div>
          <div class="owner-name" data-test="ownerNameLabel">
            {{ ownerFullName }} - {{ owner.ownerPercentage }}% Owner
          </div>
        </div>

        <div v-else class="owner-section-two">
          <h5
            v-if="!fromVerifyDetails"
            class="owner-one"
            :data-test="`ownerSubHeader${index}`">
            Owner {{ numberToEnglish(index + 1) }}
          </h5>

          <h5
            v-else
            class="owner-two"
            :data-test="`ownerSubHeader${index}`">
            Owner {{ numberToEnglish(index + 1) }}
          </h5>
        </div>
      </div>

      <div>
        <custom-button
          v-if="activeOwner !== index && !inPlaceInput"
          variant="link"
          size="small"
          class="complete-step-button px-0"
          :disabled="ownerInEditMode"
          @click="setActiveOwner">
          Edit
        </custom-button>

        <custom-button
          v-if="activeOwner !== index && !inPlaceInput && showDelete"
          variant="link"
          size="small"
          class="complete-step-button ml-4 px-0"
          :disabled="ownerInEditMode"
          @click="showConfirmDeleteModal">
          Delete
        </custom-button>

        <standard-close-button-x
          v-else-if="!owner.uid && !inPlaceInput && !isTheOnlyOwner"
          :on-click="showConfirmInEditDeleteModal" />
      </div>

      <custom-button
        v-if="inPlaceInput"
        class="edit-save-button"
        :disabled="saveDisabled"
        size="small"
        variant="text"
        @click="toggleEdit">
        {{ editing ? 'Save' : 'Edit' }}
      </custom-button>
    </div>

    <div
      v-if="activeOwner === index && !inPlaceInput && index + 1 === 1"
      class="company-percentage">
      <span class="company-percentage-text" data-test="companyPercentageText">
        Click here if you own at least 10% of the company and
        would like to auto-fill your information below.
      </span>
      <v-switch
        class="old-v-switch"
        inset
        hide-details
        :ripple="false"
        :switch-toggle="true"
        @update:model-value="fillData" />
    </div>

    <merchant-onboarding-wizard-owner-form
      ref="ownerForm"
      :key="componentKey"
      class="pa-4"
      :disabled-inputs="formInputsDisabled"
      :in-place-input="inPlaceInput"
      :index="index"
      :on-save="onSaveOwner"
      :owner="owner"
      :from-verify-details="fromVerifyDetails"
      :show-save-button="showSaveButton"
      :editing="editing"
      :otp-simple-design="otpSimpleDesign"
      :enable-ownership-text="enableOwnershipText"
      :auto-fill-data="autoFillData"
      @ownerAdded="$emit('ownerAdded')"
      @saveDisabled="saveDisabled = $event" />

    <standard-dialog
      :dialog="inEditDelete"
      :on-cta="removeOwner"
      :on-cancel="closeConfirmDeleteModal"
      button-label="Delete owner"
      title="Are you sure?" />

    <delete-owner-modal
      :dialog="show"
      :owner-position="numberToEnglish(index + 1)"
      :owner-name="ownerFullName"
      :owner-percentage="owner.ownerPercentage"
      :disable-buttons-in-modal="disableModalButtons"
      @confirmed="removeOwner"
      @declined="closeConfirmDeleteModal" />

    <step-info
      :show="showStepInfo"
      @close="showStepInfo = false" />
  </v-card>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import MerchantOnboardingWizardOwnerForm from '@/components/Merchant/OnboardingWizard/Owner/Form.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import NumberToEnglish from '@/filters/NumberToEnglish';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import StepInfo from '@/components/Merchant/OnboardingWizard/Modals/CompleteStepInfo.vue';
import StandardCloseButtonX from '@/components/CloseButtonX.vue';
import DeleteOwnerModal from '@/components/Merchant/OnboardingWizard/Modals/DeleteOwnerModal.vue';
import { addOrUpdateOwner, deleteOwner } from '@/api/owners';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';

export default defineComponent({
  name: 'MerchantOnboardingWizardOwnerCard',

  components: {
    CustomButton,
    MerchantOnboardingWizardOwnerForm,
    StandardDialog,
    StepInfo,
    StandardCloseButtonX,
    DeleteOwnerModal,
  },

  props: {
    owner: { type: Object as PropType<OwnerDataInterface>, required: true },
    index: { type: Number, required: true },
    activeOwner: { type: Number, required: true },
    onSaveOwner: { type: Function, default: () => () => null },
    onSetActiveOwner: { type: Function, default: () => () => null },
    onDeleteOwner: { type: Function, default: () => () => null },
    inPlaceInput: { type: Boolean, default: false },
    fromVerifyDetails: { type: Boolean, default: false },
    otpSimpleDesign: { type: Boolean, default: false },
    subStep: { type: Boolean, default: false },
    enableOwnershipText: { type: Boolean, default: true },
    isTheOnlyOwner: { type: Boolean, default: false },
    showDelete: { type: Boolean, default: false },
    ownerInEditMode: { type: Boolean, default: false },
  },

  data: () => ({
    editing: false,
    saveDisabled: false,
    show: false,
    showStepInfo: false,
    ownerPreview: false,
    autoFillData: false,
    inEditDelete: false,
    disableModalButtons: false,
    componentKey: 0,
  }),

  computed: {
    classList(): string {
      const classList: Array<string> = [];

      if (this.$props.inPlaceInput) {
        classList.push('owners-list__item--inplace-input');
      }

      if (this.$props.activeOwner === this.$props.index) {
        classList.push('owners-list__item--active');
      }

      if (this.$props.activeOwner !== this.$props.index) {
        classList.push('owners-list__item--inactive');
      }

      if (this.subStep) {
        classList.push('sub-step');
      }

      return classList.join(' ');
    },

    wizardSubStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardSubStep'];
    },

    ownerFullName(): string {
      return `${this.owner.firstName} ${this.owner.lastName}`;
    },

    processingApplication(): boolean {
      return this.$store.getters[
        'MerchantOnboarding/getProcessingApplication'
      ];
    },

    applicationApproved(): boolean {
      return this.$store.getters[
        'MerchantOnboarding/getApplicationApproved'
      ];
    },

    applicationWaitingApproval(): boolean {
      return this.$store.getters[
        'MerchantOnboarding/getApplicationWaitingApproval'
      ];
    },

    formInputsDisabled(): boolean {
      return (this.inPlaceInput && !this.editing)
      || this.processingApplication
      || this.applicationApproved
      || this.applicationWaitingApproval;
    },

    showSaveButton(): boolean {
      return !this.inPlaceInput;
    },

    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    cardElevation() {
      return this.editing ? 20 : undefined;
    },

    userVerifyData(): OnboardingUserData {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'];
    },

    merchantUuid(): string {
      return this.userVerifyData?.merchantUid;
    },
  },

  methods: {
    showConfirmInEditDeleteModal() {
      this.inEditDelete = true;
    },

    closeModal() {
      this.show = false;
    },

    async toggleEdit() {
      if (this.editing) {
        const isValidAddress = await (this.$refs.ownerForm as any).openAddressValidation();
        if (!isValidAddress) return;

        await addOrUpdateOwner(this.owner, this.merchantUuid);
      }

      this.editing = !this.editing;
      this.$store.dispatch('MerchantOnboarding/setVerifyFormOwnerDetailsStatus', this.editing);
    },

    showConfirmDeleteModal(): void {
      this.show = true;
    },

    closeConfirmDeleteModal(): void {
      this.show = false;
      this.inEditDelete = false;
    },

    setActiveOwner(): void {
      this.$emit('editModeActive');
      this.onSetActiveOwner(this.$props.index);
      this.$store.dispatch('MerchantOnboarding/setOwnerSaved', false);
    },

    async removeOwner() {
      this.disableModalButtons = true;

      if (this.owner.id) {
        const response = await deleteOwner(this.owner.id, this.merchantUuid);

        if (response.status !== 204) {
          this.$store.dispatch('Ui/addGlobalAlert', {
            text: 'Something went wrong when trying to delete owner',
            type: GlobalAlertTypes.ERROR,
            timed: true,
          });
        }
      }

      this.onDeleteOwner(this.index);
      this.$store.dispatch('MerchantOnboarding/setOwnerSaved', true);
      this.$emit('ownerDeleted');

      this.disableModalButtons = false;

      this.closeConfirmDeleteModal();

      this.componentKey += 1;
    },

    numberToEnglish(val: number): string {
      return NumberToEnglish(val);
    },

    fillData() {
      this.autoFillData = !this.autoFillData;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/components/dialogs";
@import "@/assets/scss/vuetify/elevation.scss";

.sub-step {
  border: 1px solid var(--grayscale-color-2);
  box-shadow: $v-elevation-4;
}

.owners-list__item {
  @include standard-wizard-form-inline-split;
  margin-bottom: 2rem !important;
  border-radius: 8px;
  padding: 1rem;

  .company-percentage {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0 1rem;
    align-items: top;

    .company-percentage-text {
      color: var(--grayscale-color-1);
      margin-top: 0.5rem;
      max-width: 17rem;
    }

    .v-switch {
      margin: 0 1rem;
      min-width: 3rem;
    }
  }

  :deep(.owners-list__item__data) {
    @include mobile {
      .card-text__info-group,
      .state-zip-row {
        label,
        input,
        .v-select__slot label,
        .v-select__selections span {
          font-size: 0.875rem;
        }
      }
      .address-input-container {
        label,
        input {
          font-size: 0.875rem;
        }
      }
    }
  }

  &__title {
    background-color: var(--grayscale-color-5);
    border-radius: 8px;
    user-select: none;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2;
    font-weight: bold;
    color: var(--grayscale-color-1);

    .edit-save-button {
      font-size: 0.875rem !important;
      background-color: #fff;
      border-radius: 8px;
      min-width: 2rem;
    }

    .mobile-title {
      margin-top: 1rem;
    }

    h5 {
      margin-bottom: 0;
      padding-right: 5rem;
    }

    i {
      font-size: 1.5rem;
      line-height: 1;
    }

    .owner-section {
      line-height: 160%;
      .owner-count {
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--secondary-color);
        text-align: left;
      }
      .owner-name {
        font-size: 1rem;
        color: var(--secondary-color);
        text-align: left;
      }
    }
    .owner-section-two {
      .owner-one {
        font-size: 1rem;
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
      }
      .owner-two {
        font-size: 1rem;
        color: var(--grayscale-color-1);
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
      }
    }

    :deep(.custom-button span) {
      @include mobile {
        font-size: 0.875rem !important;
      }
    }
  }

  &__data {
    padding: 0 1.25rem 1.25rem 1.25rem;
  }

  .card-body {
    padding: 0;
  }

  &--inplace-input {
    .owners-list__item__title {
      :deep(.standard-toggle-edit-form-button) {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
      }
    }
  }

  &--inplace-input,
  &--active {
    .owners-list__item__data {
      display: block;
    }
  }

  &--inactive {
    &:not(.owners-list__item--inplace-input) {
      .owners-list__item__title {
        cursor: pointer;
        color: var(--grayscale-color-1);
        margin-bottom: 0;
      }

      .owners-list__item__data {
        display: none;
      }
    }
  }
  :deep() .v-theme--light.v-btn.complete-step-button.button-small:hover {
      box-shadow: none;
      background-color: #fff;
  }

  :deep() .v-theme--light.v-btn.edit-save-button.button-small:hover {
      box-shadow: none;
      background-color: #fff;
  }

  :deep(.v-theme--light.v-btn.complete-step-button.button-small) {
    font-size: 0.75rem;
    background-color: #fff;
    border-radius: 8px;
  }

  :deep(.v-theme--light.v-btn.complete-step-button) {
    min-width: 2rem;
  }
}
</style>
