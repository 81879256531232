import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-486f9c6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "global-alerts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_v_slide_y_transition = _resolveComponent("v-slide-y-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_slide_y_transition, { group: "" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert) => {
          return (_openBlock(), _createBlock(_component_alert, {
            id: alert.id,
            key: alert.id,
            type: alert.type,
            text: alert.text,
            timed: alert.timed,
            dismissible: alert.dismissible,
            "html-text": alert.htmlText,
            onClose: _ctx.remove
          }, null, 8, ["id", "type", "text", "timed", "dismissible", "html-text", "onClose"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}