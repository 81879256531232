<template>
  <standard-wizard-message
    :message="errorMessage"
    :no-border="noBorder"
    data-test="maxMsg" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';

export default defineComponent({
  name: 'MaxAttemptsMsg',

  components: {
    StandardWizardMessage,
  },

  props: {
    noBorder: { type: Boolean, default: false },
  },

  data() {
    return {
      errorMessage: 'Sorry, we cannot verify your mobile number and you cannot proceed.',
    };
  },
});
</script>
