import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_merchant_begin_onboarding_wizard = _resolveComponent("merchant-begin-onboarding-wizard")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_ctx.showBmob)
    ? (_openBlock(), _createBlock(_component_v_dialog, {
        key: 0,
        "model-value": _ctx.showBmob,
        persistent: "",
        width: "500",
        "content-class": "merchant-begin-onboarding",
        "onUpdate:modelValue": _ctx.close,
        "onClick:outside": _ctx.close
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_ctx.showBeginButton && !_ctx.showBeginOnboardingWizard)
              ? (_openBlock(), _createBlock(_component_custom_button, {
                  key: 0,
                  class: "merchant-begin-onboarding__begin-btn",
                  "data-test": "beginBtn",
                  onClick: _ctx.toggleBeginOnboardingWizard
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Begin Onboarding Now ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.showBeginOnboardingWizard)
              ? (_openBlock(), _createBlock(_component_merchant_begin_onboarding_wizard, {
                  key: 1,
                  "on-close": _ctx.closeBeginOnboardingWizard,
                  onClose: _ctx.close
                }, null, 8, ["on-close", "onClose"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["model-value", "onUpdate:modelValue", "onClick:outside"]))
    : _createCommentVNode("", true)
}