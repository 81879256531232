import instance from '@/Axios';
import AXIOS_CONFIG from '@/constants/AxiosConfigConstants';
import { dateToFormatIso } from '@/helpers/DateTimeHelper';
import { RateSheetsPostDataInterface } from '@/interfaces/rates/RateSheetsPostDataInterface';
import { MerchantOnboardingStateInterface } from '@/interfaces/states/MerchantOnboardingStateInterface';
import axios from 'axios';
import {
  get,
  isArray,
  toLower,
} from 'lodash';
import { ActionContext } from 'vuex';
import { setStatusAndErrorStateFromAxios } from '@/api/utils';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';

type Context = ActionContext<MerchantOnboardingStateInterface, any>;

export async function dispatchSetMerchantOnboardingWizardStep(context: Context, step: number) {
  context.commit('setMerchantOnboardingWizardSubStep', 1);
  context.commit('setMerchantOnboardingWizardStep', step);
}

export async function dispatchSetMerchantOnboardingWizardSubStep(context: Context, step: number) {
  context.commit('setMerchantOnboardingWizardSubStep', step);
}

export async function dispatchOtpRegister(context: Context, phone_number: string) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('Otp/setOtpRegisterSessionToken', '', { root: true });

  const userVerifyData = context.getters.getUserVerifyData;
  const merchant_user_email = userVerifyData.emailAddress;
  const noTokenRegister = true;

  const postData: any = {
    phone_number,
    merchant_user_email,
    noTokenRegister,
  };

  return context.dispatch('Otp/otpRegister', postData, { root: true });
}

export async function dispatchOtpVerify(context: Context, payload: any) {
  context.commit('Ui/setErrors', {}, { root: true });

  const {
    phoneNumber,
    sessionToken,
    securityCode,
  } = payload;

  let response: any = {};
  let responseStatus: number = 0;

  const userVerifyData = context.getters.getUserVerifyData;

  const postData: any = {
    phone_number: phoneNumber,
    session_token: sessionToken,
    security_code: securityCode,
    merchant_user_email: userVerifyData.emailAddress,
  };

  try {
    response = await axios.post(
      '/api/v1/otp/verify/',
      postData,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    await context.dispatch('Otp/setOtpEnabled', responseStatus !== 417, { root: true });

    const token = response.data?.token;

    if (userVerifyData.sponsorType === 'IC1' && token) {
      const setUserPayload = {
        token,
        merchant: userVerifyData.sponsorId,
        merchantData: userVerifyData,
      };
      await context.dispatch('Auth/setUserData', setUserPayload, { root: true });
    } else if (token) {
      await context.dispatch('Auth/setUserData', { token }, { root: true });
    }
  }

  return responseStatus;
}

export async function dispatchCreatePassword(context: Context, payload: any) {
  const { userId, password, passwordCheck } = payload;

  let response: any = {};
  let responseStatus: number = 0;

  if (userId && password && passwordCheck && (password === passwordCheck)) {
    context.commit('Ui/setErrors', {}, { root: true });

    const postData: object = {
      password,
      password_check: passwordCheck,
    };

    try {
      response = await instance.post(
        `/api/v1/account/user/${userId}/set_password/`,
        postData,
      );
    } catch (e) {
      setStatusAndErrorStateFromAxios(responseStatus, e, context);
    } finally {
      if (response.status) {
        responseStatus = response.status;
      }
    }
  }

  return responseStatus;
}

export async function dispatchFetchPrograms(context: Context) {
  let response: any = {};
  let programs: any = [];

  try {
    response = await instance.get(
      '/api/v1/meta/programs/',
      AXIOS_CONFIG(),
    );

    if (response.status === 200) {
      programs = response.data;
      context.commit('setPrograms', programs);
    }
  } catch (e: unknown) {
    setStatusAndErrorStateFromAxios(0, e, context);
  }

  return programs;
}

export async function dispatchFetchSubprograms(context: Context, programUuid: string) {
  let response: any = {};
  let subPrograms: any = [];

  try {
    response = await instance.get(
      `/api/v1/meta/programs/${programUuid}/sub-programs/`,
      AXIOS_CONFIG(),
    );

    if (response.status === 200) {
      subPrograms = response.data;
      context.commit('setSubPrograms', subPrograms);
    }
  } catch (e) {
    setStatusAndErrorStateFromAxios(0, e, context);
  }

  return subPrograms;
}

export async function dispatchUserVerify(context: Context, emailCode: number) {
  context.commit('Ui/setErrors', {}, { root: true });

  let response: any = {};
  let responseStatus: number = 0;

  try {
    // API query without token
    response = await axios.get(`/api/v1/account/user/verify-email-otp/${emailCode}/`);
  } catch (e: any) {
    response = e.response;
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  if (responseStatus !== 200) return response;

  const {
    email,
    first_name,
    id,
    last_name,
    middle_name,
    phone_number,
  } = response.data.user;

  const {
    name,
    status,
    uuid,
    human_readable_id,
  } = response.data.merchant;

  const {
    application_status,
    application_status_reason,
  } = response.data.merchant.application;

  const merchantAppUuid = response.data.merchant.application.uuid;

  const sponsorID = response.data.sponsor?.uid;
  const sponsorName = response.data.sponsor?.name;
  const sponsorType = response.data.sponsor?.s_type;

  const userVerifyData: OnboardingUserData = {
    emailAddress: toLower(email),
    firstName: first_name,
    userId: id,

    lastName: last_name,
    middleName: middle_name,
    userMobile: phone_number,

    businessName: name,
    status,
    merchantUid: uuid,
    humanReadableId: human_readable_id,

    appStatus: application_status,
    appStatusReason: application_status_reason,
    merchantApplicationId: merchantAppUuid,

    sponsorId: sponsorID,
    sponsorName,
    sponsorType,
  };

  context.commit('setUserVerifyData', userVerifyData);
  context.commit('setApplicationStatus', application_status);

  return response;
}

export function clearOwners(context: Context) {
  context.commit('clearOwners');
}

export async function dispatchAllAttributes(context: Context, attributes: any) {
  context.commit('Ui/setErrors', {}, { root: true });

  let response: any = {};
  let responseStatus: number = 0;

  const merchantUID = context.getters.getMerchantUid;

  const postData: any = {};

  if (attributes.legalBusinessName) {
    postData.legal_business_name = attributes.legalBusinessName;
  }

  if (attributes.doingBusinessAs) {
    postData.doing_business_as = attributes.doingBusinessAs;
  }

  if (attributes.federalTaxId) {
    postData.federal_tax_id = attributes.federalTaxId;
  }

  if (attributes.ssn) {
    postData.ssn = attributes.ssn;
  }

  if (attributes.websiteUrl) {
    postData.website_url = attributes.websiteUrl;
  }

  if (attributes.businessStartDate) {
    postData.business_start_date = dateToFormatIso(attributes.businessStartDate);
  }

  if (attributes.annualSalesRevenue || attributes.annualSalesRevenue === 0) {
    postData.annual_sales_revenue = attributes.annualSalesRevenue;
  }

  if (attributes.averageCustomerSpend) {
    postData.average_customer_spend = attributes.averageCustomerSpend;
  }

  if (attributes.offersFinancing) {
    postData.offers_financing = attributes.offersFinancing;
  }

  if (attributes.financingVolume) {
    postData.financing_volume = attributes.financingVolume;
  }

  if (attributes.businessStructure) {
    postData.business_structure = attributes.businessStructure;
  }

  if (attributes.paymentTypesAccepted) {
    postData.payment_types_accepted = attributes.paymentTypesAccepted;
  }

  if (attributes.merchant_phone) {
    postData.merchant_phone = attributes.merchant_phone;
  }

  try {
    response = await instance.post(
      `/api/v1/merchant/${merchantUID}/all-attributes/`,
      postData,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    context.commit('setAllAttributes', attributes);
  }

  return responseStatus;
}

export async function dispatchPatchApplication(context: Context, postData: string) {
  context.commit('Ui/setErrors', {}, { root: true });

  let response: any = {};
  let responseStatus: number = 0;

  const requestData: any = {
    application_status_reason: postData,
  };

  const merchantApplicationUuid = get(
    context.getters.getUserVerifyData,
    'merchantApplicationId',
  );

  try {
    response = await instance.patch(
      `/api/v1/merchant/application/${merchantApplicationUuid}/`,
      requestData,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function dispatchUpdateMerchantData(context: Context, userVerifyData: any) {
  const merchantUID = context.getters.getMerchantUid;

  const postData: any = {
    name: userVerifyData.businessName,
    create_address: true,
    address_1: userVerifyData.address1,
    city: userVerifyData.city,
    state: userVerifyData.state,
    zip: userVerifyData.zipCode,
    application: userVerifyData.merchantApplicationId,
  };

  if (userVerifyData.address2.length > 0) {
    postData.address_2 = userVerifyData.address2;
  }

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.put(
      `/api/v1/merchant/${merchantUID}/`,
      postData,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      context.commit(
        'setUserVerifyData',
        userVerifyData,
      );
    }
  }

  return responseStatus;
}

export async function dispatchSubmitApplication(context: Context, socureSessionId: string) {
  context.commit('Ui/setErrors', {}, { root: true });

  context.commit('setProcessingApplication', true);
  context.commit('setApplicationApproved', false);
  context.commit('setApplicationWaitingApproval', false);

  const merchantUID = context.getters.getMerchantUid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.post(
      `/api/v1/merchant/${merchantUID}/submit-application/`,
      { deviceSessionID: socureSessionId },
    );
  } catch (e) {
    context.commit('setApplicationWaitingApproval', true);
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      if (response.data.merchant_application_approved) {
        if (!response.data.external_merchant) {
          context.commit('setApplicationStatus', 'Approved');
        } else if (response.data.external_merchant && response.data.bypass_verification_hold) {
          context.commit('setApplicationStatus', 'Approved-External-Hold-True');
        } else if (response.data.external_merchant && !response.data.bypass_verification_hold) {
          context.commit('setApplicationStatus', 'Approved-External-Hold-False');
        }
        // Save Redirect URL for later use (Step 5)
        context.commit('setRedirectUrl', response.data.redirect_url);
      } else {
        context.commit('setApplicationStatus', 'Pending');
      }
    } else {
      context.commit('setApplicationWaitingApproval', true);
    }

    context.commit('setProcessingApplication', false);
  }

  return responseStatus;
}

export async function dispatchFetchRateSheet(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUID = context.getters.getMerchantUid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUID}/rates/`);
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      const rates = isArray(response.data) ? response.data : [];

      context.commit('setRateSheets', rates);
    }
  }

  return responseStatus;
}

export async function dispatchSelectRates(
  context: Context,
  selectedRates: RateSheetsPostDataInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUID = context.getters.getMerchantUid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.post(
      `/api/v1/merchant/${merchantUID}/select-rates/`,
      selectedRates,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function dispatchValidateMerchantData(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUID = context.getters.getMerchantUid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get(
      `/api/v1/merchant/${merchantUID}/validate-merchant-data/`,
      AXIOS_CONFIG(),
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function patchMerchantSelectedPrograms(context: Context, payload: any) {
  const { program, subProgram } = payload;
  const merchantUID = context.getters.getMerchantUid;

  let response: any = {};
  let responseStatus: number = 0;
  const postData = {
    program: program.id,
    sub_program: [subProgram.id],
  };

  try {
    response = await instance.patch(
      `/api/v1/merchant/${merchantUID}/merchant-detail/`,
      postData,
      AXIOS_CONFIG(),
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    context.commit('setSelectedPrograms', { program, subProgram });
  }

  return responseStatus;
}

export async function dispatchFetchReEntryData(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setMerchantReEntryData', {});

  const merchantApplicationUuid = get(context.getters.getUserVerifyData, 'merchantApplicationId');

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get(
      `/api/v1/merchant/application/${merchantApplicationUuid}/`,
      AXIOS_CONFIG(),
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  if (responseStatus === 200) {
    context.commit('setMerchantReEntryData', response.data);

    const selectedProgram = response.data.merchant?.program;
    if (!selectedProgram) return responseStatus;

    const programs = await context.dispatch('dispatchFetchPrograms');
    const program = programs.find((it: any) => it.name === selectedProgram);
    let subProgram;

    if (program) {
      const subPrograms = await context.dispatch('dispatchFetchSubprograms', program.program_uuid);
      const selectedSubProgram = response.data.merchant.sub_program;

      if (selectedSubProgram) {
        subProgram = subPrograms.find((it: any) => it.name === selectedSubProgram);
      }
    }

    context.commit('setSelectedPrograms', { program, subProgram });
  }

  return responseStatus;
}

export function setVerifyFormUserDetailsStatus(context: Context, payload: boolean) {
  const verifyFormsOpen = context.getters.getVerifyFormsOpen;
  verifyFormsOpen.userFormOpen = payload;
  context.commit('setVerifyFormsOpen', verifyFormsOpen);
}

export function setVerifyFormOwnerDetailsStatus(context: Context, open: boolean) {
  const verifyFormsOpen = context.getters.getVerifyFormsOpen;
  let ownerFormsOpen = verifyFormsOpen.openOwnerForms;
  if (open) {
    ownerFormsOpen += 1;
  } else {
    ownerFormsOpen = Math.max(ownerFormsOpen - 1, 0);
  }
  verifyFormsOpen.openOwnerForms = ownerFormsOpen;
  context.commit('setVerifyFormsOpen', verifyFormsOpen);
}

export function setShowMobStepper(context: Context, payload: boolean) {
  context.commit('setShowMobStepper', payload);
}

export function setOwnerSaved(context: Context, payload: boolean) {
  context.commit('setOwnerSaved', payload);
}

export function setPassedPhoneVerification(context: Context, payload: boolean) {
  context.commit('setPassedPhoneVerification', payload);
}

export function setShowBackButtonPreventModal(context: Context, payload: boolean) {
  context.commit('setShowBackButtonPreventModal', payload);
}
