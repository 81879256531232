<template>
  <v-text-field
    v-mask="inputMask"
    v-bind="$attrs"
    autocomplete="off"
    :data-test="dataTestAttr"
    :label="labelAttr"
    :variant="inPlace ? 'plain' : 'outlined'"
    :required="required"
    :rules="[validatePercentage]"
    validate-on="blur" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';

export default defineComponent({
  name: 'PercentageInput',

  mixins: [
    InputFieldMixin,
  ],

  props: {
    min: { type: [Number, String], default: 0 },
    max: { type: [Number, String], default: 100 },
  },

  data() {
    const inputMask: any = {
      mask: '9[99]',
      placeholder: '',
    };
    return {
      inputMask,
    };
  },

  methods: {
    validatePercentage(value: number | undefined): string | boolean {
      if (!value) {
        if (value === 0) return true;
        return this.isRequired();
      }

      if (value < Number(this.min)) return `Cannot be less than ${this.min}`;

      if (value > Number(this.max)) return `Cannot be more than ${this.max}`;

      return true;
    },
  },
});
</script>
