<template>
  <text-input
    v-mask="inputMask"
    v-bind="attrs"
    :data-test="dataTestAttr"
    :label="labelAttr"
    :rules="[validateTaxId]" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import { INPUT_MASK_DEFAULTS } from '@/components/Inputs/defaults';
import taxId from '@/validators/tax_id';
import TextInput from '@/components/Inputs/Text.vue';

export default defineComponent({
  name: 'TaxIdInput',

  components: { TextInput },

  mixins: [InputFieldMixin],

  data: () => ({
    defaultLabelAttr: 'Federal Tax Id / EIN',
    defaultDataTestAttr: 'federalInput',
  }),

  computed: {
    inputMask() {
      return {
        ...INPUT_MASK_DEFAULTS,
        mask: '99-9999999',
      };
    },
  },

  methods: {
    validateTaxId(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      if (!taxId(value)) return 'Please correct tax ID format';

      return true;
    },
  },
});
</script>
