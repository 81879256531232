<template>
  <div class="standard-wizard__step standard-wizard__step--three standard-wizard__step__substep-owners">
    <v-card class="pa-0 standard-wizard__step__card">
      <transition mode="out-in" name="slide">
        <v-card-text class="fs-unmask owners-list pa-0" data-test="MOB-step1-substep1">
          <div class="inside-card">
            <div class="inside-card-title" data-test="ownerHeader">
              <h2 class="ma-0">
                Owners
              </h2>
            </div>

            <div class="inside-card-body">
              <p
                class="mb-0 pa-4 pt-0 text-left"
                data-test="ownerLabel">
                Enter information below for any owner that owns 10% or more of your company.
                Owner information is required for at least 51% ownership.
              </p>

              <custom-button
                class="complete-step-button"
                data-test="completeStepBtn"
                variant="link"
                @click="showStepInfo = true">
                How To Complete This Step
              </custom-button>
            </div>

            <div v-if="totalOwnershipPercentage" class="d-flex justify-left">
              <div class="ownership-section" data-test="currentOwnershipLabel">
                You are currently at {{ totalOwnershipPercentage }}% ownership.
              </div>
            </div>

            <merchant-onboarding-wizard-owner-card
              v-for="(owner, index) in ownersData"
              :key="index"
              :active-owner="activeOwner"
              :index="index"
              :on-delete-owner="deleteOwner"
              :on-save-owner="onSaveOwner"
              :on-set-active-owner="setActiveOwner"
              :owner="owner"
              :is-the-only-owner="ownersData.length < 2 ? true : false"
              :sub-step="true"
              :show-delete="enableDelete"
              :owner-in-edit-mode="ownerInEditMode"
              @editModeActive="ownerInEditMode = true"
              @ownerAdded="ownerInEditMode = false"
              @ownerDeleted="ownerInEditMode = false" />

            <div
              v-if="isOwnerSaved && totalOwnershipPercentage >= 51"
              style="border-radius: 8px; border: 1px solid var(--grayscale-color-2);
              padding: 1rem; margin: 1rem;">
              <p
                class="text-left"
                data-test="anotherOwnerLabel"
                style="font-size: 1rem;">
                Would you like to add another owner or proceed to the next step?
              </p>

              <custom-button
                v-if="isOwnerSaved && totalOwnershipPercentage >= 51"
                :disabled="nextSubstepDisabled"
                full-width
                label="Proceed to Next Step"
                class="mb-8"
                @click="goToNextStep" />

              <custom-button
                v-if="isOwnerSaved"
                :disabled="addAnotherOwnerDisabled"
                data-test="addBtn"
                :variant="totalOwnershipPercentage < 51 ? 'primary' : 'secondary'"
                full-width
                @click="addNewOwner">
                Add Another Owner
              </custom-button>
            </div>

            <div v-else>
              <div class="mb-4 mx-4">
                <custom-button
                  v-if="isOwnerSaved"
                  :disabled="addAnotherOwnerDisabled"
                  data-test="addBtn"
                  :variant="totalOwnershipPercentage < 51 ? 'primary' : 'secondary'"
                  full-width
                  @click="addNewOwner">
                  Add Another Owner
                </custom-button>
              </div>
            </div>

            <step-info
              :show="showStepInfo"
              @close="showStepInfo = false" />
          </div>
        </v-card-text>
      </transition>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantOnboardingWizardOwnerCard
  from '@/components/Merchant/OnboardingWizard/Owner/Card.vue';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import GetProcessing from '@/mixins/GetProcessing';
import cloneDeep from 'lodash/cloneDeep';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import StepInfo from '@/components/Merchant/OnboardingWizard/Modals/CompleteStepInfo.vue';
import GetSponsorMixin from '@/mixins/GetSponsor';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'SubstepOne',

  components: {
    CustomButton,
    MerchantOnboardingWizardOwnerCard,
    StepInfo,
  },

  mixins: [
    GetProcessing,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
    GetSponsorMixin,
  ],

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data() {
    const defaultOwnerData: OwnerDataInterface = {
      isAddressValidated: false,
      firstName: '',
      middleName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: {
        title: '',
        value: '',
      },
      zipCode: '',
      email: '',
      ssn: '',
      ownerPercentage: undefined,
      phoneNumber: '',
      uid: '',
      dob: '',
      security_pin: '',
      address_verification_response: {
        analysis: {},
        components: {},
        metadata: {},
      },
    };

    return {
      activeOwner: -1,
      disableNextStepBtn: false,
      defaultOwnerData,
      showStepInfo: false,
      ownerInEditMode: false,
      hpEventName: 'Owners Page Visit',
    };
  },

  computed: {
    isOwnerSaved() {
      return this.$store.getters['MerchantOnboarding/getIsOwnerSaved'];
    },

    reEntryData() {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    reEntryMerchantOwnerSet(): Array<any> {
      return this.reEntryData?.merchant?.merchantowner_set || [];
    },

    combinedOwnershipValid(): boolean {
      return this.$store.getters['MerchantOnboarding/getTotalOwnershipValid'];
    },

    editingOwner(): boolean {
      return this.activeOwner > -1;
    },

    addAnotherOwnerDisabled(): boolean {
      return this.processing
      || (this.editingOwner && this.ownersData.length > 0)
      || this.totalOwnershipPercentage >= 100;
    },

    totalOwnershipPercentage(): number {
      return this.$store.getters['MerchantOnboarding/getTotalOwnershipPercentage'];
    },

    nextSubstepDisabled(): boolean {
      return !this.combinedOwnershipValid || this.editingOwner || this.disableNextStepBtn;
    },

    ownersData(): Array<OwnerDataInterface> {
      return this.$store.getters['MerchantOnboarding/getCombinedOwners'];
    },

    ownStep(): number {
      return this.isTypeIC1 ? 2 : 3;
    },

    enableDelete() :boolean {
      return this.ownersData.length > 1;
    },
  },

  watch: {
    reEntryMerchantOwnerSet: {
      immediate: true,
      handler() {
        if (!this.reEntryMerchantOwnerSet.length) return;

        this.$store.dispatch('MerchantOnboarding/clearOwners');
        this.fillReEntryData();
        this.setActiveOwner(this.ownersData.length - 1);
      },
    },
  },

  mounted() {
    if (!this.ownersData.length) {
      this.addNewOwner();
    } else {
      this.$store.dispatch('MerchantOnboarding/setOwnerSaved', true);
    }
  },

  methods: {
    async goToNextStep() {
      this.disableNextStepBtn = true;

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post-Owners / Pre-Industry',
      );
      this.goToStep(this.ownStep + 1);

      this.trackEvent(this.hpEventName, PageTypesShorthand.MOB);

      this.disableNextStepBtn = false;
    },

    setActiveOwner(index: number) {
      if (!this.editingOwner) {
        this.activeOwner = index;
      }
    },

    deleteOwner(index: number) {
      this.activeOwner = -1;
      this.$store.commit('MerchantOnboarding/setDeleteOwner', index);
    },

    onSaveOwner(): void {
      this.activeOwner = -1;
    },

    addNewOwner(ownerData?: OwnerDataInterface) {
      this.ownerInEditMode = true;

      this.$store.commit(
        'MerchantOnboarding/setSingleOwner',
        {
          owner: cloneDeep(ownerData || this.defaultOwnerData),
          index: this.ownersData.length,
        },
      );

      this.$store.dispatch('MerchantOnboarding/setOwnerSaved', false);

      this.setActiveOwner(this.ownersData.length - 1);
    },

    fillReEntryData() {
      this.reEntryMerchantOwnerSet.forEach((ownerSet: any) => {
        const newOwner = cloneDeep(this.defaultOwnerData);
        newOwner.uid = ownerSet.uuid;
        newOwner.ownerPercentage = Number(ownerSet.owner_percentage) || 0;

        const { user } = ownerSet;
        if (!user) {
          this.addNewOwner(newOwner);
          return;
        }

        newOwner.firstName = user.first_name;
        newOwner.middleName = user.middle_name;
        newOwner.lastName = user.last_name;
        newOwner.email = user.email;

        const address = user.address_set[0];
        if (address) {
          newOwner.isAddressValidated = true;
          newOwner.address1 = address.address_1;
          newOwner.address2 = address.address_2;
          newOwner.city = address.city;
          newOwner.state = address.state;
          newOwner.zipCode = address.zip_code;
        }

        const sensitiveData = user.sensitive_set ? user.sensitive_set[0] : null;
        if (sensitiveData) {
          newOwner.dob = sensitiveData.dob;
          newOwner.security_pin = sensitiveData.security_pin;
        }

        newOwner.phoneNumber = user.phonenumber_set ? user.phonenumber_set[0]?.phone_number : '';

        this.addNewOwner(newOwner);
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import '@/assets/scss/variables/_custom-variables';
@import "@/assets/scss/vuetify/elevation.scss";

:deep(h5) {
  margin-top: 0;
  margin-bottom: 0;
}

.label-or {
  margin: 1rem 0;
}

:deep(.v-input) {
  margin-bottom: 1.5rem;
}

.standard-wizard {
  &__step {
    background-color: #fff;
    margin-top: 0 !important;

    &__substep-owners {
      .standard-wizard__step__card {
        box-shadow: none;
        border: none;
        background-color: var(--grayscale-color-4);
        padding: 1rem;

        .inside-card {
          margin-top: 2rem;
          border-radius: 8px;
          border: 1px solid var(--grayscale-color-2);
          padding: 0.1rem;
          background-color: #FFFFFF;

          .ownership-section {
            display: inline-block;
            width: auto;
            margin: 0 1rem 1rem 1rem;
            padding: 0.5rem;
            background-color: var(--grayscale-color-5);
            color: var(--grayscale-color-1);
            border-radius: 16px;
            font-size: 1rem;
          }

          .inside-card-title {
            background-color: var(--grayscale-color-5);
            padding: 1rem;
            text-align: left;
            margin-bottom: 1rem;

            h2 {
              font-size: 1.125rem;
            }
          }

          .inside-card-body {

            p {
              font-size: 1rem;
            }

            .complete-step-button {
              font-size: 0.875rem;
              display: flex;
              margin-left: auto;
              margin-bottom: 1rem;
            }
          }
        }

        & > .card-body {
          padding: 0;
        }
      }
    }
  }
}

:deep() .v-theme--light.v-btn.complete-step-button.button-large:hover {
  box-shadow: none;
  background-color: transparent;
}
</style>
