import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-text__info-group__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_currency_input = _resolveComponent("currency-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.inPlaceInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_currency_input, _mergeProps(_ctx.$attrs, {
      "data-test": "annualSelect",
      disabled: _ctx.disabled,
      "error-messages": _ctx.errors.annual_sales_revenue,
      "in-place": _ctx.inPlaceInput,
      label: "Annual Sales Revenue",
      max: 999999999999999,
      min: 0,
      required: "",
      "show-icon": "",
      "error-capitalized": ""
    }), null, 16, ["disabled", "error-messages", "in-place"])
  ]))
}