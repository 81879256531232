<template>
  <div>
    <v-card
      elevation="4"
      class="standard-wizard__step__card"
      :class="[blockCols ? `col-lg-${blockCols} reset-shadow mx-auto` : 'reset-shadow',
               isOnboarding ? 'keep-elevation' : '']">
      <v-card-text>
        <div v-if="loaderActive && showLoader && !stopLoader" class="d-flex justify-center pa-8">
          <v-progress-circular
            size="64"
            width="5"
            color="var(--grayscale-color-1)"
            indeterminate />
        </div>

        <div v-else>
          <v-form
            ref="ownerForm"
            autocomplete="off"
            @submit.prevent>
            <custom-alert
              v-for="error in errors.non_field_errors"
              :key="error"
              type="error">
              {{ error }}
            </custom-alert>

            <custom-alert
              v-if="isError"
              type="error"
              class="mb-6 text-left">
              It appears some of your data is incorrect.
              Please correct in order to proceed.
            </custom-alert>

            <div v-if="isOnboarding">
              <h3 class="mb-4 mt-0 text-left" style="font-size: 1.125rem;">
                Your Payment Settings
              </h3>

              <div class="mb-4 text-left" style="font-size: 1rem; color:var(--grayscale-color-1);">
                Enter the bank account where you’d
                like to process credits and debits (refunds).
              </div>
            </div>

            <div class="card-text__info-group">
              <account-type-radio
                v-model="paymentFormData.holder_type"
                required
                @input="checkFields" />
            </div>

            <div class="card-text__info-group">
              <v-select
                v-model="paymentFormData.bank_account_type"
                required
                variant="outlined"
                class="old-v-select"
                label="Select Account Type"
                data-test="accountTypeDropdown"
                :items="accountTypeItems"
                @update:model-value="checkFields" />
            </div>

            <div class="card-text__info-group" :class="isOnboarding ? 'mob-margin-update' : ''">
              <text-input
                v-model="paymentFormData.name_on_check"
                class="mb-0"
                data-test="fullName"
                :error-messages="errors.first_name"
                label="Name On Account"
                required
                @update:model-value="checkFields" />

              <div class="d-flex justify-end mb-2">
                <button
                  type="button"
                  class="text-action"
                  data-test="whereCanFindBtn"
                  style="color: var(--primary-color);"
                  @click="showExampleCheckDialog">
                  Where can I find this information?
                </button>
              </div>
            </div>

            <div class="card-text__info-group">
              <bank-account-number-input
                v-model="paymentFormData.ach_account_number"
                error-messages="It appears some of your data is incorrect.
                Please correct the input in order to proceed."
                :validate-last-four-digits="!paymentNetwork ? lastFour : undefined"
                required
                :visible="false"
                @update:model-value="checkFields" />
            </div>

            <div class="card-text__info-group">
              <bank-account-number-input
                v-model="ach_account_number_check"
                error-messages="It appears some of your data is incorrect.
                Please correct the input in order to proceed."
                :input-verify="paymentFormData.ach_account_number"
                label="Verify Bank Account Number"
                required
                :visible="false"
                @update:model-value="checkFields" />
            </div>

            <div class="card-text__info-group">
              <routing-number-input
                v-model="paymentFormData.ach_routing_number"
                class="routing-number"
                :class="bankName ? 'mb-0 hide-routing-error' : ''"
                :error-messages="errors.ssn"
                required
                :hide-details="!!bankName"
                :append-inner-icon="showRoutingCheckmark ? 'mdi-check-circle-outline' : ''"
                :check-return-failed="showRoutingError"
                @blur="checkRoutingNumber"
                @update:model-value="checkFields" />
              <div v-show="bankName" class="bank-name">
                {{ bankName }}
              </div>
            </div>

            <div class="card-text__info-group">
              <zip-code-input
                v-model="paymentFormData.address_zip"
                :error-messages="errors.zip"
                required
                data-test="zipCode"
                @update:model-value="checkFields" />
            </div>

            <div class="border-bottom card-text__info-group">
              <email-input
                v-model="paymentFormData.email"
                :error-messages="errors.email"
                required
                label="Email Address"
                @update:model-value="onEmailUpdate" />
            </div>

            <div
              class="card-text__info-group compliance-field text-left"
              data-test="byClickingLabel">
              By clicking
              {{ repayPaymentNetwork ? '"Authorize"' : '"Send Deposits"' }}
              you accept the
              <a
                :href="userTerms || ''"
                target="_blank"
                rel="noopener noreferrer">
                User Terms
              </a>.
            </div>

            <div class="card-text__info-group">
              <custom-button
                :disabled="saveButtonDisabled"
                class="authorize-button"
                data-test="authorizeBtn"
                full-width
                type="submit"
                @click="getPaymentToken">
                {{ repayPaymentNetwork ? 'Authorize' : 'Send Deposits' }}
              </custom-button>

              <custom-button
                v-if="showCancelButton"
                class="cancel-button"
                data-test="cancelBtn"
                variant="secondary"
                full-width
                @click="$emit('cancelForm')">
                Cancel
              </custom-button>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>

    <example-check-dialog :show="exampleCheckDialog" @close="closeExampleCheckDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import BankAccountNumberInput from '@/components/Inputs/BankAccountNumber.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import GetErrors from '@/mixins/GetErrors';
import isEmailAddress from '@/validators/email_address';
import RoutingNumberInput from '@/components/Inputs/RoutingNumber.vue';
import ssn from '@/validators/ssn';
import validateZipCode from '@/validators/zip_code';
import TextInput from '@/components/Inputs/Text.vue';
import ZipCodeInput from '@/components/Inputs/Address/ZipCode.vue';
import postTokenPayment from '@/api/repay';
import AccountTypeRadio from '@/components/Inputs/AccountTypeRadio.vue';
import ExampleCheckDialog from '@/components/PaymentProviders/ExampleCheckDialog.vue';
import validateBankAccountNumber from '@/validators/bankAccountNumber';

export default defineComponent({
  name: 'FormFields',

  components: {
    CustomAlert,
    BankAccountNumberInput,
    CustomButton,
    EmailInput,
    RoutingNumberInput,
    TextInput,
    ZipCodeInput,
    AccountTypeRadio,
    ExampleCheckDialog,
  },

  mixins: [GetErrors],

  props: {
    blockCols: { type: Number, default: 12 },
    lastFour: { type: String, default: null },
    hasRepayAcc: { type: Boolean, default: false },
    showCancelButton: { type: Boolean, default: false },
    isOnboarding: { type: Boolean, default: false },
    moovPaymentNetwork: { type: Boolean, default: false },
    repayPaymentNetwork: { type: Boolean, default: false },
    paymentNetwork: { type: Boolean, default: false },
    confirmAccountUpdate: { type: Boolean, default: false },
    showLoader: { type: Boolean, default: false },
    stopLoader: { type: Boolean, default: false },
    skipCheckSameAccount: { type: Boolean, default: false },
  },

  data() {
    const paymentFormData: any = {
      name_on_check: null,
      ach_account_number: null,
      ach_routing_number: null,
      address_zip: null,
      email: null,
      holder_type: null,
      bank_account_type: null,
    };

    return {
      paymentFormData,
      isEmailUsed: false,
      saveDisabled: true,
      isError: false,
      ach_account_number_check: null,
      accountTypeItems: [{ title: 'Checking', value: 'checking' }, { title: 'Savings', value: 'savings' }],
      exampleCheckDialog: false,
      showRoutingCheckmark: false,
      showRoutingError: false,
      loaderActive: false,
    };
  },

  computed: {
    userVerifyDataEmail(): string {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'].emailAddress;
    },

    userTerms(): string {
      return this.$store.getters['MerchantOnboarding/getUserTermsLink'];
    },

    merchantUuid(): any {
      return this.$store.getters['Auth/getMerchantUuid'];
    },

    bankName(): string {
      return this.$store.getters['MerchantPortal/getBankName'];
    },

    compareAccountNumberLast4Digits() {
      const acc_number = this.paymentFormData.ach_account_number;
      if (this.lastFour) {
        return acc_number?.slice(-4) === this.lastFour;
      }
      return true;
    },

    saveButtonDisabled() {
      return this.saveDisabled || !this.showRoutingCheckmark;
    },
  },

  watch: {
    confirmAccountUpdate: {
      immediate: true,
      handler(val: boolean) {
        if (val) {
          this.getPaymentToken();
        }
      },
    },

    'paymentFormData.ach_routing_number': {
      immediate: true,
      handler() {
        this.showRoutingCheckmark = false;
      },
    },
  },

  methods: {
    onEmailUpdate(val: string) {
      this.isEmailUsed = this.userVerifyDataEmail === val;
      this.checkFields();
    },

    validateAllFields() {
      const {
        name_on_check,
        ach_account_number,
        ach_routing_number,
        address_zip, email,
        holder_type,
        bank_account_type,
      } = this.paymentFormData;

      // Send form details to form component so verify deposit can use it
      this.$emit('sendFormDetails', this.paymentFormData);

      if (ach_account_number !== this.ach_account_number_check) {
        this.saveDisabled = true;
        return;
      }

      if (
        name_on_check
        && ach_account_number
        && validateBankAccountNumber(ach_account_number)
        && ach_account_number === this.ach_account_number_check
        && holder_type
        && bank_account_type
        && ssn(ach_routing_number)
        && validateZipCode(address_zip)
        && isEmailAddress(email)
      ) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },

    validateBankAccountMoov() {
      const {
        name_on_check,
        ach_account_number,
        ach_routing_number,
        holder_type,
        bank_account_type,
      } = this.paymentFormData;

      // Send form details to form component so verify deposit can use it
      this.$emit('sendFormDetails', this.paymentFormData);

      if (
        name_on_check
        && ach_account_number
        && ach_account_number === this.ach_account_number_check
        && this.compareAccountNumberLast4Digits
        && holder_type
        && bank_account_type
        && ssn(ach_routing_number)
      ) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },

    validateMoovPaymentNetwork() {
      const {
        name_on_check,
        ach_account_number,
        ach_routing_number,
        holder_type,
        address_zip, email,
        bank_account_type,
      } = this.paymentFormData;

      if (
        name_on_check
        && ach_account_number
        && ach_account_number === this.ach_account_number_check
        && holder_type
        && bank_account_type
        && ssn(ach_routing_number)
        && validateZipCode(address_zip)
        && isEmailAddress(email)
      ) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
      }
    },

    checkFields() {
      this.isError = false;

      if (this.hasRepayAcc) {
        this.validateBankAccountMoov();
      } else if (this.moovPaymentNetwork) {
        this.validateMoovPaymentNetwork();
      } else {
        this.validateAllFields();
      }
    },

    async checkForStatuses(tokenPaymentStatus:number) {
      if (tokenPaymentStatus === 200) {
        this.$emit('payTokenSuccess');

        if (this.$route.name !== 'merchant-admin') {
          await this.$store.dispatch(
            'MerchantOnboarding/dispatchPatchApplication',
            'Post Payment Provider / Pre-Rate Sheet',
          );
        }
        this.saveDisabled = false;
        this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');
      } else {
        this.saveDisabled = true;
        this.isError = true;
      }
    },

    async checkRoutingNumber() {
      this.showRoutingError = false;
      this.showRoutingCheckmark = false;

      if (!this.paymentFormData.ach_routing_number) {
        return;
      }

      const routingNumber = this.paymentFormData.ach_routing_number;
      const routingNumberLength = this.paymentFormData.ach_routing_number.length;
      const routingNumberLengthCheck = 9;

      if (routingNumberLength === routingNumberLengthCheck) {
        const responseStatus = await this.$store.dispatch('MerchantPortal/checkRoutingNumber', routingNumber);

        if (responseStatus === 200) {
          this.showRoutingCheckmark = true;
        } else {
          this.showRoutingError = true;
        }
      } else {
        this.$store.dispatch('MerchantPortal/resetRoutingNumber');
      }
    },

    showExampleCheckDialog() {
      this.exampleCheckDialog = true;
    },

    closeExampleCheckDialog() {
      this.exampleCheckDialog = false;
    },

    async getPaymentToken() {
    /*
    We need confirming Repay and creating a Moov account
    to be handled on the backend side.
    For now, we only send data required for Repay,
    and some of those fields are not needed for Moov,
    so we get an error/exception when we don't send them.
    */
      if (this.paymentNetwork
    && !this.compareAccountNumberLast4Digits
    && !this.confirmAccountUpdate
    && !this.skipCheckSameAccount) {
        this.$emit('sameBankAccount');
      } else {
        try {
          this.saveDisabled = true;
          this.loaderActive = true;
          let tokenPaymentStatus = 0;

          /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
          try {
            const tokenResponse = await postTokenPayment(this.merchantUuid, this.paymentFormData);
            tokenPaymentStatus = tokenResponse.status;
          } catch (error) {}
          this.$store.dispatch('Repay/setCardPassedState', tokenPaymentStatus === 200);

          this.checkForStatuses(tokenPaymentStatus);
        } catch (err) {
          this.saveDisabled = false;
          this.loaderActive = false;
          this.isError = true;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_custom-variables';
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

.standard-wizard {
  .card {
    box-shadow: none !important;
  }

  &__step {
    &__substep-owners {
      .standard-wizard__step__card {
        box-shadow: none;
        border: none;
        background: transparent;

        & > .card-body {
          padding: 0;
        }
      }
    }

    &__alert {
      font-size: 1rem;
      font-weight: 500;
    }

    &__substep-combined {
      .standard-wizard__step__card {
          background-color: transparent;

          .owners-list__item {
            background: #FFFFFF;
          }
        }
    }
  }
}

.compliance-field {
  font-size: 0.75rem !important;
  color: var(--grayscale-color-1);
}

.authorize-button {
  margin-top: 1rem;
}

.cancel-button {
  margin-top: 2rem;
}

.reset-shadow {
  box-shadow: none !important;
}

.keep-elevation {
  box-shadow: $v-elevation-4 !important;
  border: 1px solid var(--grayscale-color-2);
}

.mob-margin-update {
  margin-bottom: 0.8rem !important;
}

.card-text__info-group {
  :deep(.routing-number) {
    margin-bottom: 0.5rem !important;

    .v-input__control .v-field__append-inner .v-icon {
      color: var(--success-color) !important;
    }
  }

  .bank-name {
    color: var(--grayscale-color-1);
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: left !important;
    font-size: 0.75rem;
  }

  :deep(.hide-routing-error .v-text-field__details) {
    display: none;
  }
}
</style>
