<template>
  <v-form>
    <text-input
      v-model="internalFormData.first_name"
      :disabled="processing"
      required
      :error-messages="errors.first_name"
      autocomplete="given-name"
      label="First Name" />

    <text-input
      v-model="internalFormData.last_name"
      :disabled="processing"
      required
      :error-messages="errors.last_name"
      autocomplete="family-name"
      label="Last Name" />

    <phone-input
      v-if="!internalFormData.id"
      v-model="internalFormData.phone_number"
      data-test="phoneInput"
      :disabled="processing"
      required
      :error-messages="errors.phone_number"
      label="Mobile Phone" />

    <phone-input
      v-else
      v-model="internalFormData.phone"
      data-test="phoneInput"
      :disabled="processing"
      required
      :error-messages="errors.phone_number"
      label="Mobile Phone" />

    <email-input
      v-model="internalFormData.email"
      :disabled="processing"
      required
      :email-error="emailError"
      label="Email Address"
      @input="onEmailInput" />

    <template v-if="!isSalesManager">
      <select-input
        v-if="!internalFormData.id"
        v-model="internalFormData.user_type"
        :disabled="processing"
        :items="userTypes"
        item-title="name"
        item-value="value"
        label="User Role" />

      <select-input
        v-else
        v-model="editedUserType"
        :disabled="userTypeInputDisabled"
        :items="updatedRolesArr"
        item-title="name"
        item-value="value"
        label="User Role" />
    </template>

    <template v-else>
      <span>User role</span>
      <p><strong>Representative</strong></p>
    </template>
  </v-form>
</template>

<script lang="ts">
import TextInput from '@/components/Inputs/Text.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import SelectInput from '@/components/Inputs/Select.vue';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserForm',
  components: {
    TextInput,
    PhoneInput,
    EmailInput,
    SelectInput,
  },
  mixins: [GetErrors, GetProcessing],
  props: {
    formData: { type: Object, required: true },
    user_type: { type: String, default: '' },
    user: { type: Object, default: () => ({}) },
    emailError: { type: String, default: '' },
  },
  data() {
    return {
      internalFormData: null as any,
      updatedRolesArr: [] as Array<any>,
      userTypes: [{
        name: 'Representative',
        value: 'Salesrep',
      }, {
        name: 'Manager',
        value: 'Salesmanager',
      }, {
        name: 'Admin',
        value: 'Admin',
      }],
      editedUserType: '',
    };
  },
  computed: {
    loggedInUserId(): number {
      return this.$store.getters['Auth/getUserId'];
    },
    userTypeInputDisabled(): boolean {
      return this.processing || this.user.user.id === this.loggedInUserId;
    },
    buttonDisabled(): boolean {
      return this.processing || this.user.is_owner;
    },
    isSalesManager() {
      return this.$store.getters['MerchantPortal/getMerchantData']?.user_type.name === 'merchant-sales-manager' || false;
    },
  },
  watch: {
    editedUserType(value: string) {
      this.editedUserType = value;
      this.$emit('updateUserType', this.editedUserType);
    },
    internalFormData: {
      handler(value: any) {
        this.$emit('updateFormData', value);
      },
      deep: true,
    },
  },
  created() {
    this.editedUserType = this.user_type;
    this.internalFormData = this.formData;

    /* eslint-disable no-return-assign */
    this.$nextTick(() => {
      this.updatedRolesArr = this.userTypes.filter((el: any) => {
        if (el.name === 'Manager' && this.user_type === 'Salesmanager') {
          return el.name = 'Manager - Current Role';
        }
        if (el.name === 'Representative' && this.user_type === 'Salesrep') {
          return el.name = 'Representative - Current Role';
        }
        if (el.name === 'Admin' && this.user_type === 'Admin') {
          return el.name = 'Admin - Current Role';
        }
        return el;
      });
    });
  },
  methods: {
    onEmailInput() {
      if (this.errors.email) this.$store.dispatch('Ui/resetErrorState');
      this.$emit('emailUpdated');
    },
  },
});
</script>

<style lang="scss" scoped>
.user-role {
  span, p {
    font-size: 1rem;
  }
  p {
    padding-top: 0.25rem;
  }
}
</style>
