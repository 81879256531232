<template>
  <header :class="['portal-header', isAdminPage ? '' : 'sticky']">
    <v-container fluid class="portal-header__container">
      <div
        class="portal-header__top-row"
        :class="{ 'multi-location': multiLocationActive && location }">
        <merchant-name :merchant-name="merchant.name" />
        <div class="portal-header__logo">
          <img
            v-if="logoUrl"
            class="standard-logo"
            :src="logoUrl"
            alt="Logo">
        </div>
        <portal-header-user-info />
        <merchant-location v-if="multiLocationActive && location" mobile />
        <portal-header-actions-mobile />
      </div>

      <v-card v-if="!isMobile" class="portal-header__actions">
        <portal-header-actions />
      </v-card>
    </v-container>

    <custom-alert
      v-if="showAlert"
      type="success"
      :timed="true"
      class="merchant-portal-alert"
      @dismissed="closeAlert">
      <div class="font-weight-bold">
        Success!
      </div>

      <div>
        You requested
        {{ alertData.chargeName === 'Initial Charge' ? 'an' : 'a' }} {{ alertData.chargeName }}
        of {{ alertData.chargeValue }} from {{ alertData.consumerName }}.
      </div>
    </custom-alert>
  </header>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import PortalHeaderActions from '@/components/Merchant/Portal/Header/Actions.vue';
import PortalHeaderActionsMobile from '@/components/Merchant/Portal/Header/ActionsMobile.vue';
import PortalHeaderUserInfo from '@/components/Merchant/Portal/Header/UserInfo.vue';
import MerchantName from '@/components/Merchant/Portal/Header/MerchantName.vue';
import { RouteLocationNormalized } from 'vue-router';
import { defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import MerchantLocation from '@/components/Merchant/Portal/Header/MerchantLocation.vue';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'PortalHeader',
  components: {
    PortalHeaderUserInfo,
    PortalHeaderActions,
    PortalHeaderActionsMobile,
    MerchantLocation,
    MerchantName,
    CustomAlert,
  },
  mixins: [FeatureFlagsMixin],
  data() {
    return {
      isAdminPage: false,
      showAlert: false,
    };
  },
  computed: {
    ...mapGetters('Ui', {
      logoUrl: 'getBrandingHeaderLogo',
    }),
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
    isMainPage(): boolean {
      return this.$route.name === 'merchant-activity-center';
    },
    merchant(): any {
      return this.$store.getters['Auth/getMerchant'];
    },
    alertData() {
      return this.$store.getters['MerchantPortal/getAlertData'];
    },
    location() {
      return this.$store.getters['MerchantPortal/getMerchantLocation'];
    },
    multiLocationActive(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.MULTI_LOCATION);
    },
  },
  watch: {
    $route: {
      handler(newVal: RouteLocationNormalized) {
        this.isAdminPage = newVal.name === 'merchant-admin';
      },
      immediate: true,
    },
    alertData(newVal: any) {
      if (newVal) {
        this.showAlert = true;
      }
    },
    location(newVal: any) {
      if (newVal) {
        this.showAlert = false;
      }
    },
  },
  methods: {
    closeAlert() {
      this.showAlert = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/vuetify/elevation.scss";
@import '@/assets/scss/mixins/media_queries';

.sticky {
  position: sticky;
}

.portal-header {
  top: 0;
  z-index: 200;
  background-color: var(--grayscale-color-5);
  box-shadow: $v-elevation-1;
  margin-bottom: 1.5rem;

  @include mobile {
    margin-bottom: 1rem;
  }

  .merchant-portal-alert {
    width: 25.25rem;
    max-width: 25.25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__container {
    padding-bottom: 0;

    :deep(.custom-button.button-large.header-link) {
      font-size: 1.125rem;
    }

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    align-self: flex-end;

    img {
      max-height: 2rem;
      max-width: 7rem;
      width: 100%;
    }

    @media only screen and (max-width: 374px) {
      max-width: 30%;
    }
  }

  &__user-info {
    width: auto;
    height: 4.7rem;
    background-color: #FFFFFF;
    box-shadow: $v-elevation-1;
  }

  &__actions {
    position: static;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding: .75rem;
    height: 3.75rem;
    box-shadow: $v-elevation-3 !important;

    @media only screen and (max-width: 600px) {
      padding: 1rem;
      margin: 0;
      width: 100%;
    }
  }

  &__search {
    width: 25%;

    :deep(.standard-input-field) {
      height: 100%;
    }
  }

  &__dropdown {
    display: none;
  }

  .merchant-location-info {
    display: none;
  }

  @include up-to-desktop {
    .portal-header {
      &__top-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        padding: 0.5rem 1rem;

        &.multi-location {
          flex-wrap: wrap;
          padding: 0.5rem 1rem;

          .merchant-name {
            @media only screen and (max-width: 360px) {
              width: 50vw;
            }
          }

          .portal-header__logo {
            width: 8rem;
          }

          .merchant-location-info {
            display: flex;
            flex-grow: 1;
            width: 90%;
            text-align: left;

            @media only screen and (min-width: 850px) {
              justify-content: flex-end;
              width: auto;
              padding-right: 0;
            }

            @media only screen and (max-width: 620px) {
              width: 70%;
            }

            :deep(span) {
              display: inline-block;
              margin-right: 0.5rem;
            }

            :deep(.merchant-location-info__name) {
              width: auto;
              height: 1.2rem;
            }

          }

          .portal-header__dropdown {
            flex-grow: 0;
            padding: 0.5rem 0 0.5rem 1rem;
          }
        }

        .portal-header__dropdown {
          padding: 0;
        }
      }

      &__logo {
        img {
          padding: 0;
        }
      }

      &__search {
        width: 100%;
        margin-right: 1rem;
      }

      &__dropdown {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        padding: 1rem;
        margin-left: .5rem;
        cursor: pointer;
        z-index: 5;

        &__img {
          max-height: 2.0625rem;
        }

        @media only screen and (max-width: 600px) {
          padding: 1rem;
        }
      }

      &__container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        margin: 0;
        padding: 0;
      }

      &__user-info {
        display: none;
      }

      &__actions {
        display: none;
      }
    }

    :deep() {
      .portal-header__user-info {
        flex-wrap: wrap;

        &__cta {
          width: 100%;

          @media only screen and (min-width: 360px) and (max-width: $mobile-width-max) {
            width: auto;
          }
        }
      }
    }
  }
}
</style>
