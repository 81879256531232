<template>
  <v-dialog
    v-if="showBmob"
    :model-value="showBmob"
    persistent
    width="500"
    content-class="merchant-begin-onboarding"
    @update:model-value="close"
    @click:outside="close">
    <div>
      <custom-button
        v-if="showBeginButton && !showBeginOnboardingWizard"
        class="merchant-begin-onboarding__begin-btn"
        data-test="beginBtn"
        @click="toggleBeginOnboardingWizard">
        Begin Onboarding Now
      </custom-button>

      <merchant-begin-onboarding-wizard
        v-if="showBeginOnboardingWizard"
        :on-close="closeBeginOnboardingWizard"
        @close="close" />
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantBeginOnboardingWizard from '@/components/Merchant/BeginOnboardingWizard/Wizard.vue';
import LocalStorageConstants from '@/constants/LocalStorageConstants';

export default defineComponent({
  name: 'MerchantBeginOnboarding',

  components: {
    CustomButton,
    MerchantBeginOnboardingWizard,
  },

  props: {
    showBeginButton: { type: Boolean, default: true },
    showBmob: { type: Boolean, default: false },
  },

  data: () => ({
    showBeginOnboardingWizard: true,
  }),

  created() {
    localStorage.setItem(
      LocalStorageConstants.CURRENTLY_ONBOARDING,
      LocalStorageConstants.ONBOARDING.MOB,
    );
  },

  methods: {
    toggleBeginOnboardingWizard(): void {
      if (this.showBeginOnboardingWizard) {
        this.closeBeginOnboardingWizard();
      } else {
        this.initializeBeginOnboardingWizard();
      }
    },

    resetBeginOnboardingWizardState(): void {
      this.$store.commit('MerchantBeginOnboarding/resetModuleState');
    },

    initializeBeginOnboardingWizard(): void {
      this.resetBeginOnboardingWizardState();

      this.showBeginOnboardingWizard = true;
    },

    closeBeginOnboardingWizard(): void {
      this.showBeginOnboardingWizard = false;

      this.resetBeginOnboardingWizardState();
    },

    close() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom-transitions";
@import "@/assets/scss/vuetify/elevation.scss";

:deep(.v-overlay__content > div) {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

:deep(.v-stepper-window) {
  margin-top: 0;
}

:deep(.lightbulb) {
  margin-bottom: 3rem;
  box-shadow: $v-elevation-6;
}

:deep(.v-input) {
  margin-bottom: 0.8125rem !important; // 13px
}

.merchant-begin-onboarding {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 2rem;
  position: relative;

  &__begin-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
}
</style>
