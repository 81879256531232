import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45054820"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "standard-input-otp__label" }
const _hoisted_2 = { class: "standard-input-otp__single-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_input_otp_max_attempts_msg = _resolveComponent("standard-input-otp-max-attempts-msg")!
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_otp_input = _resolveComponent("otp-input")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_input_otp_resend_otp_modal = _resolveComponent("standard-input-otp-resend-otp-modal")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.disableOtp)
        ? (_openBlock(), _createBlock(_component_standard_input_otp_max_attempts_msg, { key: 0 }))
        : (_openBlock(), _createBlock(_component_v_form, {
            key: 1,
            class: "fs-unmask standard-input-otp",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedErrors, (error, index) => {
                return (_openBlock(), _createBlock(_component_custom_alert, {
                  key: index,
                  type: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(error), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.isMob ? 'bigger-header' : '')
                }, _toDisplayString(_ctx.bodyText), 3)
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_otp_input, {
                  ref: `${_ctx.inputNameString}Single`,
                  modelValue: _ctx.singleInputCode,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.singleInputCode) = $event)),
                  disabled: _ctx.disableOtp,
                  required: "",
                  "single-input": "",
                  autofocus: _ctx.autofocusEnabled
                }, null, 8, ["modelValue", "disabled", "autofocus"]),
                _createVNode(_component_custom_button, {
                  disabled: !_ctx.canSubmit || _ctx.disableBtn,
                  "full-width": "",
                  class: "mb-6",
                  onClick: _ctx.verifyOtpSingleInput
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Submit ")
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _createVNode(_component_standard_input_otp_resend_otp_modal, {
                "invitation-uuid": _ctx.invitationUuid,
                "merchant-user-email": _ctx.merchantUserEmail,
                "on-phone-number-updated": _ctx.phoneNumberUpdated,
                "phone-number": _ctx.phoneNumber,
                "otp-message": _ctx.otpMessage,
                "consumer-otp": _ctx.consumerOtp,
                "is-mob": _ctx.isMob
              }, null, 8, ["invitation-uuid", "merchant-user-email", "on-phone-number-updated", "phone-number", "otp-message", "consumer-otp", "is-mob"])
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}