import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c78e1a74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "my-0 px-5 py-4 text-left",
  "data-test": "businessDetailsHeader",
  style: {"font-size":"1.125rem","background-color":"var(--grayscale-color-5)"}
}
const _hoisted_2 = {
  key: 1,
  class: "mt-4 px-4",
  "data-test": "enterBusinessDetailsLabel",
  style: {"text-align":"left","font-size":"1rem","color":"var(--grayscale-color-1)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_substep_two = _resolveComponent("substep-two")!
  const _component_substep_three = _resolveComponent("substep-three")!
  const _component_substep_four = _resolveComponent("substep-four")!
  const _component_substep_five = _resolveComponent("substep-five")!
  const _component_substep_six = _resolveComponent("substep-six")!
  const _component_substep_seven = _resolveComponent("substep-seven")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_substep_eight = _resolveComponent("substep-eight")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.classList, "standard-wizard__step standard-wizard__step--three"]),
      "data-test": "MOB-step3"
    }, [
      (_ctx.wizardSubStep !== 8)
        ? (_openBlock(), _createBlock(_component_v_card, {
            key: 0,
            class: "mt-4 standard-wizard__step__card standard-wizard__substep__two",
            style: {"border-radius":"8px"}
          }, {
            default: _withCtx(() => [
              (_ctx.wizardSubStep !== 1)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " Business Details "))
                : _createCommentVNode("", true),
              (_ctx.wizardSubStep === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Please enter your business details. "))
                : _createCommentVNode("", true),
              _createVNode(_component_v_card_text, {
                class: _normalizeClass(["pb-0", [_ctx.isMobile ? 'pt-0' : '', _ctx.isMobile && _ctx.wizardSubStep === 2 ? 'pl-0' : '']])
              }, null, 8, ["class"]),
              _createVNode(_Transition, {
                mode: "out-in",
                name: "slide"
              }, {
                default: _withCtx(() => [
                  (_ctx.wizardSubStep === 2)
                    ? (_openBlock(), _createBlock(_component_substep_two, {
                        key: 0,
                        "on-next-sub-step": _ctx.onNextSubStep
                      }, null, 8, ["on-next-sub-step"]))
                    : (_ctx.wizardSubStep === 3)
                      ? (_openBlock(), _createBlock(_component_substep_three, {
                          key: 1,
                          "on-next-sub-step": _ctx.onNextSubStep
                        }, null, 8, ["on-next-sub-step"]))
                      : (_ctx.wizardSubStep === 4)
                        ? (_openBlock(), _createBlock(_component_substep_four, {
                            key: 2,
                            "on-next-sub-step": _ctx.onNextSubStep
                          }, null, 8, ["on-next-sub-step"]))
                        : (_ctx.wizardSubStep === 5)
                          ? (_openBlock(), _createBlock(_component_substep_five, {
                              key: 3,
                              "on-next-sub-step": _ctx.onNextSubStep
                            }, null, 8, ["on-next-sub-step"]))
                          : (_ctx.wizardSubStep === 6)
                            ? (_openBlock(), _createBlock(_component_substep_six, {
                                key: 4,
                                "on-next-sub-step": _ctx.onNextSubStep
                              }, null, 8, ["on-next-sub-step"]))
                            : (_ctx.wizardSubStep === 7)
                              ? (_openBlock(), _createBlock(_component_substep_seven, {
                                  key: 5,
                                  "on-next-sub-step": _ctx.nextStep
                                }, null, 8, ["on-next-sub-step"]))
                              : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_Transition, {
            key: 1,
            mode: "out-in",
            name: "slide"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_substep_eight, { "on-next-step": _ctx.nextStep }, null, 8, ["on-next-step"])
            ]),
            _: 1
          }))
    ], 2)
  ]))
}