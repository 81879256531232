import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "full-width px-0 simple-card__body-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rate_sheet_options_data_table = _resolveComponent("rate-sheet-options-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_rate_sheet_options_data_table, {
      ref: "rateSheetDataTable",
      "rate-sheet-options": _ctx.rateSheetsBase,
      "is-from-offer-codes": "",
      "switch-toggle": "",
      "show-header-info-on-mount": false,
      "show-header": false,
      "show-legend": true,
      "merchant-onboarding": "",
      "header-title": "Your Rate Sheet"
    }, null, 8, ["rate-sheet-options"])
  ]))
}