import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab9e5fdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "consumer-activity__header fs-unmask" }
const _hoisted_2 = { class: "consumer-activity__header fs-unmask pl-3 pr-2" }
const _hoisted_3 = {
  key: 1,
  class: "consumer-activity-controls"
}
const _hoisted_4 = { class: "align-center d-flex" }
const _hoisted_5 = { class: "search-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_navigation_btn = _resolveComponent("tab-navigation-btn")!
  const _component_tab_navigation = _resolveComponent("tab-navigation")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_status_filter_body = _resolveComponent("status-filter-body")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_recent_applications_table = _resolveComponent("recent-applications-table")!
  const _component_tab_navigation_item = _resolveComponent("tab-navigation-item")!
  const _component_opportunities_table = _resolveComponent("opportunities-table")!
  const _component_tab_navigation_content = _resolveComponent("tab-navigation-content")!
  const _component_custom_expansion_panel = _resolveComponent("custom-expansion-panel")!

  return (_openBlock(), _createBlock(_component_custom_expansion_panel, {
    expanded: !_ctx.isMobile,
    "can-collapse": !_ctx.isMobile,
    class: "consumer-activity"
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h5", {
            class: _normalizeClass(["ma-0 text-left", { 'px-4': !_ctx.isMobile }])
          }, " Consumer Activity ", 2)
        ])
      ])
    ]),
    text: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_tab_navigation, {
              key: 0,
              modelValue: _ctx.currentTab,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
                  return (_openBlock(), _createBlock(_component_tab_navigation_btn, {
                    key: tab.value,
                    value: tab.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tab.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.currentTab,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentTab) = $event)),
                  class: "activity-select old-v-select",
                  "menu-props": { offset: [-40, 0] },
                  "hide-details": "",
                  items: _ctx.tabs
                }, null, 8, ["modelValue", "items"])
              ]),
              (_ctx.currentTab === 'recentApplications')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      variant: "plain",
                      size: "small",
                      class: "bg-white full-opacity ml-6",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showFilters = true))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "var(--primary-color)" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-filter-variant ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_dialog, {
                      modelValue: _ctx.showFilters,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showFilters) = $event)),
                      "content-class": "app-status-filter-dialog"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_status_filter_body, {
                          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showFilters = false))
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ], 64))
                : _createCommentVNode("", true)
            ])),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_text_input, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search) = $event)),
              "append-inner-icon": "mdi-magnify",
              dense: "",
              "hide-details": "",
              "in-place": "",
              placeholder: "Sent To, ID Number"
            }, null, 8, ["modelValue"])
          ]),
          (!_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                tag: "button",
                class: _normalizeClass({ 'mdi-spin': _ctx.loading }),
                disabled: _ctx.loading,
                onClick: _ctx.refresh
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-refresh ")
                ]),
                _: 1
              }, 8, ["class", "disabled", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["consumer-activity__content", { 'is-mobile': _ctx.isMobile }])
      }, [
        _createVNode(_component_tab_navigation_content, { value: _ctx.currentTab }, {
          default: _withCtx(() => [
            _createVNode(_component_tab_navigation_item, { tab: "recentApplications" }, {
              default: _withCtx(() => [
                _createVNode(_component_recent_applications_table, { ref: "recentApplicationsTable" }, null, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_tab_navigation_item, { tab: "opportunities" }, {
              default: _withCtx(() => [
                _createVNode(_component_opportunities_table, { ref: "opportunitiesTable" }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ], 2)
    ]),
    _: 1
  }, 8, ["expanded", "can-collapse"]))
}