import get from 'lodash/get';
import { LoanInvitation } from '@/interfaces/merchantPortal/LoanInvitation';
import { MenuItemInterface } from '@/interfaces/menu/MenuItemInterface';
import { MerchantPortalStateInterface } from '@/interfaces/states/MerchantPortalStateInterface';
import { MerchantUserInterface } from '@/interfaces/states/MerchantUserInterface';
import { NewMerchantUserInterface } from '@/interfaces/merchantPortal/NewMerchantUserInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { TransactionsDescriptionsInterface } from '@/interfaces/merchantPortal/TransactionsDescriptionsInterface';
import { TransactionsInterface } from '@/interfaces/merchantPortal/TransactionsInterface';
import { StatementDetailsInterface } from '@/interfaces/statements/StatementDetailsInterface';
import { StatementSummaryInterface } from '@/interfaces/statements/StatementSummaryInterface';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import LocalStorageConstants from '@/constants/LocalStorageConstants';
import { MerchantLocation } from '@/interfaces/merchant/MerchantLocation';

export function getNewLoanApplications(state: MerchantPortalStateInterface): Array<any> {
  return state.newLoanApplications;
}

export function getNewLoanApplicationsCount(state: MerchantPortalStateInterface): number {
  return state.newLoanApplicationsCount;
}

export function getNewLoanInvitations(state: MerchantPortalStateInterface): Array<LoanInvitation> {
  return state.newLoanInvitations;
}

export function getNewLoanInvitationsCount(
  state: MerchantPortalStateInterface,
): number {
  return state.newLoanInvitationsCount;
}

export function getAvailableTransactions(
  state: MerchantPortalStateInterface,
): Array<TransactionsInterface> {
  return state.availableTransactions;
}

export function getAvailableTransactionsCount(
  state: MerchantPortalStateInterface,
): number {
  return state.availableTransactionsCount;
}

export function getTransactionsDescriptions(
  state: MerchantPortalStateInterface,
): Array<TransactionsDescriptionsInterface> {
  return state.transactionsDescriptions;
}

export function getSelectedLoanApplication(state: MerchantPortalStateInterface): any {
  return state.selectedLoanApplication;
}

export function getMerchantUuid(
  state: MerchantPortalStateInterface,
  getters: any,
  rootState: any,
  rootGetters: any,
): string {
  const locationUuid = sessionStorage.getItem(LocalStorageConstants.LOCATION_UUID);
  const mpMerchantUuid = get(state.merchant, 'uuid');
  const authMerchatUuid = rootGetters['Auth/getMerchantUuid'];
  return locationUuid ?? mpMerchantUuid ?? authMerchatUuid;
}

export function getMenuItems(state: MerchantPortalStateInterface): Array<MenuItemInterface> {
  return state.menuItems;
}

export function getRecentTransactionsCount(
  state: MerchantPortalStateInterface,
): number {
  return state.recentTransactionsCount;
}

export function getRecentTransactions(
  state: MerchantPortalStateInterface,
): Array<TransactionsInterface> {
  return state.recentTransactions;
}

export function getRequestsTransactions(
  state: MerchantPortalStateInterface,
): any {
  return state.paymentRequests;
}

export function getRequestsTransactionsCount(
  state: MerchantPortalStateInterface,
): number {
  return state.paymentRequestsCount;
}

export function getRateSheets(state: MerchantPortalStateInterface): Array<RateSheetData> {
  return state.rateSheets;
}

export function getMerchantData(state: MerchantPortalStateInterface): any {
  return state.merchantData;
}

export function getMerchantUsers(
  state: MerchantPortalStateInterface,
): Array<MerchantUserInterface> {
  return state.merchantUsers;
}

export function getNewMerchantUser(state: MerchantPortalStateInterface): NewMerchantUserInterface {
  return get(state.newMerchantUserData, 'newUser');
}

export function getSearchedItems(state: MerchantPortalStateInterface): Array<any> {
  return state.searchedItems;
}

export function getSearchInput(state: MerchantPortalStateInterface): string {
  return state.searchInput;
}

export function getStatementDetails(
  state: MerchantPortalStateInterface,
): StatementDetailsInterface {
  return state.statementDetails;
}

export function getStatementSummary(
  state: MerchantPortalStateInterface,
): Array<StatementSummaryInterface> {
  return state.statements;
}

export function getRefundableTransactions(state: MerchantPortalStateInterface): any {
  return state.refundableTransactions;
}

export function getRateSheetExpandableCategory(state: MerchantPortalStateInterface): number {
  return state.rateSheetExpandableCategory;
}

export function getShowResetPwMsg(state: MerchantPortalStateInterface): boolean {
  return state.showResetPasswordMsg;
}

export function getMerchantApplicationUuid(state: MerchantPortalStateInterface): string {
  return state.merchantApplicationUuid;
}

export function getRecentlyApprovedConsumerAppId(state: MerchantPortalStateInterface): string {
  return state.recentlyApprovedConsumerAppId;
}

export function getShowProductAndPricingDialogJuly(state: MerchantPortalStateInterface): boolean {
  return state.showProductAndPricingDialogJuly;
}

export function getShowProductAndPricingDialogSeptember(
  state: MerchantPortalStateInterface,
): boolean {
  return state.showProductAndPricingDialogSeptember;
}

export function getShowHealthCareDialogOctober(
  state: MerchantPortalStateInterface,
): boolean {
  return state.showHealthCareDialogOctober;
}

export function getAprCapComplianceDialog(
  state: MerchantPortalStateInterface,
): boolean {
  return state.showAprCapComplianceDialog;
}

export function getShowHomeImprovementDialog(
  state: MerchantPortalStateInterface,
): boolean {
  return state.showHomeImprovementDialog;
}

export function getShowImportantChangesDialog(
  state: MerchantPortalStateInterface,
): boolean {
  return state.showImportantChangesDialog;
}

export function getPaymentProviders(
  state: MerchantPortalStateInterface,
): PaymentProvidersInterface {
  return state.paymentProviders;
}

export function getBankName(state: MerchantPortalStateInterface): string {
  return state.bankName;
}

export function getEnableRateSheetSave(state: MerchantPortalStateInterface): boolean {
  return state.rateSheetSave;
}

export function getAlertData(state: MerchantPortalStateInterface): any {
  return state.alertData;
}

export function getMerchantLocation(state: MerchantPortalStateInterface): MerchantLocation | null {
  return state.location;
}

export function getMerchantPrimaryLocation(state: MerchantPortalStateInterface) {
  return state.primaryLocation;
}

export function getMerchantLocations(state: MerchantPortalStateInterface) {
  return state.locations;
}
