<template>
  <standard-dialog
    :dialog="dialog"
    :on-cta="onClose"
    :on-cancel="onClose"
    :show-actions="false"
    :title="userName"
    :width="isMobile ? '382' : '420'">
    <template #title>
      <span v-private>{{ userName }}</span>
    </template>
    <template #body>
      <div class="simple-card__body-text">
        <v-row>
          <v-col>
            <ul>
              <div class="core-data fs-unmask">
                <li>
                  Address:
                  <span
                    v-private
                    v-fs-exclude
                    data-test="userAddressLabel">
                    {{ addressLabel(userDetails.address) }}
                  </span>
                </li>
                <li>
                  Phone:
                  <a
                    v-private="'Customer phone number'"
                    v-fs-exclude
                    :href="`tel:${userPhoneNumber}`"
                    data-test="userPhoneNumberLabel"
                    class="link-text">
                    {{ userPhoneNumber }}
                  </a>
                </li>

                <li class="fs-unmask">
                  Email:
                  <a
                    v-private="'Customer email'"
                    :href="`mailto:${userDetails.email}`"
                    data-test="userEmailLabel"
                    class="link-text">
                    {{ userDetails.email }}
                  </a>
                </li>
              </div>

              <div class="additional-data">
                <li class="small-header" data-test="loanInformationHeader">
                  Loan Information
                </li>
                <li>
                  Product ID:
                  <span
                    v-private
                    data-test="productIdLabel">
                    {{ productID }}
                  </span>
                </li>
                <li>
                  Available Spend:
                  <span
                    v-private
                    data-test="availableSpendLabel">
                    {{ creditBalance }}
                  </span>
                </li>
                <li>
                  Amount Spent:
                  <span
                    v-private
                    data-test="amountSpentLabel">
                    {{ amountSpent }}
                  </span>
                </li>
                <li>
                  Purchase Window End Date:
                  <span
                    v-private
                    data-test="purchaseWindowEndDateLabel">
                    {{ purchaseWindowEndDate }}
                  </span>
                </li>
              </div>
            </ul>
          </v-col>
        </v-row>

        <custom-button
          full-width
          size="small"
          @click="onClose">
          Close
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import USA_STATES from '@/constants/UsaStatesConstants';
import GetProcessing from '@/mixins/GetProcessing';
import { find, get } from 'lodash';
import moment from 'moment';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import DATE_CONSTANTS from '@/constants/DateConstants';

export default defineComponent({
  name: 'UserDetailsModal',

  components: {
    StandardDialog,
    CustomButton,
  },

  mixins: [
    GetProcessing,
  ],

  props: {
    onClose: { type: Function as PropType<() => void>, default: () => null },
    userDetails: { type: Object, required: true },
    dialog: { type: Boolean, default: false },
    userPhoneNumber: { type: String, default: '' },
  },

  computed: {
    stateLabel(): string {
      const state: any = find(
        USA_STATES,
        { value: get(this.$props.userDetails, 'address.state') },
      );

      return get(state, 'label', '');
    },

    userName(): string {
      return `${this.userDetails.first_name} ${this.userDetails.last_name}`;
    },

    selectedLoanApplication() {
      return this.$store.getters[
        'MerchantPortal/getSelectedLoanApplication'
      ];
    },

    applicationSelected() {
      return this.selectedLoanApplication.consumer_application;
    },

    creditBalance() {
      return this.applicationSelected
        ? CurrencyFormatLong(get(
          this.selectedLoanApplication,
          'consumer_account.available_spend',
          0,
        ))
        : null;
    },

    amountSpent() {
      return this.applicationSelected
        ? CurrencyFormatLong(get(
          this.selectedLoanApplication,
          'consumer_account.amount_spent',
          0,
        ))
        : null;
    },

    purchaseWindowEndDate() {
      const getDate = new Date(this.selectedLoanApplication.purchase_window_end_date);
      return moment(getDate).format(DATE_CONSTANTS.dateFormat);
    },

    productID() {
      return this.selectedLoanApplication.consumer_account.product_name;
    },

    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },

  methods: {
    addressLabel(address: any): string {
      const addressLabel: string[] = [];

      if (address.address_1) {
        addressLabel.push(address.address_1);
      }

      if (address.address_2) {
        addressLabel.push(address.address_2);
      }

      if (address.city) {
        addressLabel.push(address.city);
      }

      if (address.state) {
        addressLabel.push(address.state);
      }

      if (address.zip_code) {
        addressLabel.push(address.zip_code);
      }

      return addressLabel.join(', ');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/components/dialogs";

.simple-card {
  &__body-text {
    .core-data {
      border-bottom: 1px solid var(--grayscale-color-3);
      padding-bottom: 2rem;

      :last-child {
        padding-bottom: 0;
      }
    }

    ul {
      @include list-style-none;

      .additional-data {
        margin-top: 2rem;

        :last-child {
          padding-bottom: 2rem;
        }

        .small-header {
          font-weight: 700;
        }
      }

      li {
        font-size: 1rem;
        padding-bottom: 1em;
      }

      span {
        color: var(--grayscale-color-1);
      }

      .link-text {
        color: var(--primary-color);
      }
    }
  }
}

</style>
