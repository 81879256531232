<template>
  <div class="multi-location-user-table">
    <div class="table-controls" :class="{ 'multi-table': isCorporateAdmin }">
      <div v-if="isCorporateAdmin">
        <v-chip-group v-model="selectedTable" mandatory>
          <v-chip variant="text">
            <span>Users At This Location</span>
          </v-chip>
          <v-chip variant="text">
            <span>All Users</span>
          </v-chip>
        </v-chip-group>
      </div>
      <div class="search-wrapper">
        <text-input
          v-model="search"
          append-inner-icon="mdi-magnify"
          variant="underlined"
          clearable
          dense
          hide-details
          in-place
          label="Search" />
      </div>

      <div class="table-mobile-filters">
        <div>
          <strong>Filter By:</strong>
        </div>
        <multiselect-filter
          label="User Role"
          title="Filter By User Role"
          :items="allRoles"
          :selected="selectedRoles"
          @update:selected="onRoleFilterUpdate" />
        <multiselect-filter
          label="Status"
          title="Filter By Status"
          :items="allStatuses"
          :selected="selectedStatuses"
          @update:selected="onStatusFilterUpdate" />
        <multiselect-filter
          v-if="tableAllUsersSelected"
          label="Locations"
          title="Filter By Locations"
          searchable
          :items="allLocations"
          :selected="selectedLocations"
          @update:selected="onLocationFilterUpdate" />
      </div>
    </div>

    <div v-show="hasFiltersSelected" class="selected-filters">
      <v-chip
        v-for="role in selectedFilterRoleNames"
        :key="role"
        class="filter-chip"
        closable
        close-icon="mdi-close"
        @click:close="removeSelectedRole(role)">
        Role: {{ role }}
      </v-chip>
      <v-chip
        v-for="status in selectedStatuses"
        :key="status"
        class="filter-chip"
        closable
        close-icon="mdi-close"
        @click:close="removeSelectedStatus(status)">
        Status: {{ status }}
      </v-chip>
      <v-chip
        v-for="location in selectedFilterLocationNames"
        :key="location"
        class="filter-chip"
        closable
        close-icon="mdi-close"
        @click:close="removeSelectedLocation(location)">
        Location: {{ location }}
      </v-chip>
    </div>

    <v-data-table-server
      v-model:page="page"
      v-model:items-per-page="itemsPerPage"
      :mobile="isMobile"
      :disable-pagination="true"
      :headers="headers"
      :items="users"
      :loading="loading"
      :items-length="tableRowsCount"
      :items-per-page-options="[5, 10, 15]"
      class="standard-data-table"
      data-test="userTable"
      item-key="id">
      <template v-slot:[`header.role_display_name`]="{ column }">
        <multiselect-filter
          title="Filter By User Role"
          :label="column.title"
          :items="allRoles"
          :selected="selectedRoles"
          @update:selected="onRoleFilterUpdate" />
      </template>

      <template v-slot:[`header.status`]="{ column }">
        <multiselect-filter
          title="Filter By Status"
          :label="column.title"
          :items="allStatuses"
          :selected="selectedStatuses"
          @update:selected="onStatusFilterUpdate" />
      </template>

      <template v-slot:[`header.accessible_locations`]="{ column }">
        <multiselect-filter
          title="Filter By Locations"
          searchable
          :label="column.title"
          :items="allLocations"
          :selected="selectedLocations"
          min-width="350px"
          max-width="350px"
          @update:selected="onLocationFilterUpdate" />
      </template>

      <template v-slot:item="{ item }">
        <tr
          class="d-sm-none v-data-table__tr--mobile"
          :class="{ reinvitable: item.can_be_reinvited }">
          <td
            v-for="header in headers"
            :key="header.value"
            v-fs-exclude="header.fsExclude"
            v-private="header.private"
            class="v-data-table__td">
            <template v-if="header.value !== 'actions'">
              <div class="v-data-table__td-title">
                {{ header.title }}
              </div>

              <div class="v-data-table__td-value">
                <template v-if="header.value === 'accessible_locations'">
                  <button
                    v-if="item.locationNames.isConcated"
                    variant="link"
                    class="locations-button"
                    @click="dialogLocations = item.locationNames.names">
                    {{ item.locationNames.concatedValue }}
                  </button>
                  <span v-else class="location-names">
                    {{ item.locationNames.concatedValue }}
                  </span>
                </template>
                <template v-else-if="header.value === 'has_access'">
                  <v-icon v-if="item.has_access">
                    mdi-check
                  </v-icon>
                  <span v-else />
                </template>
                <template v-else>
                  {{ item[header.value] || '' }}
                </template>
              </div>
            </template>
            <template v-else>
              <div class="v-data-table__td-title">
                Actions
              </div>
              <div class="v-data-table__td-value">
                <table-actions
                  :user="item"
                  :processing="loading"
                  :all-users-table="tableAllUsersSelected"
                  :sending-reinvite="sendingReinvite"
                  @reinvite="sendReinvite"
                  @edit="setUserEditData"
                  @deactivate="setUserDeactivateData" />
              </div>
            </template>
          </td>
        </tr>

        <tr class="d-none d-sm-table-row" :class="{ reinvitable: item.can_be_reinvited }">
          <td
            v-for="header in headers"
            :key="header.value"
            v-fs-exclude="header.fsExclude"
            v-private="header.private"
            :class="`text-${header.align}`">
            <template v-if="header.value === 'accessible_locations'">
              <button
                v-if="item.locationNames.isConcated"
                variant="link"
                class="locations-button"
                @click="dialogLocations = item.locationNames.names">
                {{ item.locationNames.concatedValue }}
              </button>
              <span v-else class="location-names">
                {{ item.locationNames.concatedValue }}
              </span>
            </template>
            <template v-else-if="header.value === 'has_access'">
              <v-icon v-if="item.has_access">
                mdi-check
              </v-icon>
              <span v-else />
            </template>
            <span v-else-if="header.value !== 'actions'">
              {{ item[header.value] || '' }}
            </span>
            <span v-else>
              <table-actions
                :user="item"
                :processing="loading"
                :all-users-table="tableAllUsersSelected"
                :sending-reinvite="sendingReinvite"
                @reinvite="sendReinvite"
                @edit="setUserEditData"
                @deactivate="setUserDeactivateData" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table-server>

    <user-form
      v-if="userEditData"
      v-bind="userEditData"
      :all-users-table="tableAllUsersSelected"
      @save="onUserSave"
      @close="userEditData = null"
      @cancel="userEditData = null" />

    <status-toggle
      v-if="userDeactivateData"
      v-bind="userDeactivateData"
      :all-users-table="tableAllUsersSelected"
      @save="onStatusToggleSave"
      @close="userDeactivateData = null"
      @cancel="userDeactivateData = null" />

    <locations-dialog
      v-if="dialogLocations.length > 0"
      :locations="dialogLocations"
      @close="dialogLocations = []" />

    <re-invite-user-dialog
      v-if="reinvitedEmail"
      :email="reinvitedEmail"
      :show-alert="showReinviteAlert"
      @close="reinvitedEmail = ''"
      @close:alert="showReinviteAlert = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';
import TextInput from '@/components/Inputs/Text.vue';
import {
  RequestParams,
  getAllLocations,
  getUsersAtThisLocation,
  getUsersAllLocations,
  getAllRoles,
  getAllStatuses,
} from '@/api/merchant';
import TableActions from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/TableActions.vue';
import LocationsDialog from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/LocationsDialog.vue';
import {
  UserAtThisLocation,
  UserAllLocations,
  UserLocation,
} from '@/interfaces/merchantPortal/UserInterface';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/UserForm.vue';
import { debounce } from 'lodash';
import MerchantUserRoles from '@/constants/MerchantUserRoles';
import StatusToggle from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/StatusToggle.vue';
import MultiselectFilter from '@/components/Tables/MultiselectFilter.vue';
import ReInviteUserDialog from '@/components/Merchant/Portal/Admin/UserManagement/ReInviteUserDialog.vue';
import ReInviteMixin from '@/components/Merchant/Portal/Admin/UserManagement/ReInviteMixin';

const LOCATION_NAME_LIMIT = 50;

interface DeactivateUserProps {
  userId: number;
  status: string;
  firstName: string;
  lastName: string;
}

export default defineComponent({
  name: 'MultiLocationUsersTable',

  components: {
    LocationsDialog,
    UserForm,
    TextInput,
    TableActions,
    StatusToggle,
    MultiselectFilter,
    ReInviteUserDialog,
  },

  mixins: [MerchantPermissionsMixin, ReInviteMixin],

  data() {
    return {
      users: [] as Array<UserAtThisLocation | UserAllLocations>,
      userEditData: null as { user?: UserAtThisLocation; userId?: number } | null,
      userDeactivateData: null as DeactivateUserProps | null,
      dialogLocations: [],
      selectedTable: 0,
      loading: false,
      search: '',
      debouncedSearchFn: null as Function | null,
      allStatuses: [] as string[],
      selectedStatuses: [] as string[],
      allRoles: [] as { label: string, value: string }[],
      selectedRoles: [] as string[],
      allLocations: [] as { label: string, value: string }[],
      selectedLocations: [] as string[],
      tableRowsCount: 0,
      page: 1,
      itemsPerPage: 10,
    };
  },

  computed: {
    headers() {
      const firstName = {
        title: 'First Name',
        align: 'start',
        sortable: false,
        value: 'first_name',
        private: true,
        fsExclude: false,
      };
      const lastName = {
        title: 'Last Name',
        align: 'start',
        sortable: false,
        value: 'last_name',
        private: true,
        fsExclude: false,
      };
      const role = {
        title: 'User Role',
        align: 'start',
        sortable: false,
        value: 'role_display_name',
        fsExclude: false,
      };
      const email = {
        title: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        private: true,
        fsExclude: false,
      };
      const phone = {
        title: 'Phone',
        align: 'start',
        sortable: false,
        value: 'phoneNumber',
        private: true,
        fsExclude: true,
      };
      const id = {
        title: 'User ID',
        align: 'start',
        sortable: false,
        value: 'id',
        fsExclude: false,
      };
      const status = {
        title: 'Status',
        sortable: false,
        align: 'start',
        value: 'status',
        fsExclude: false,
      };
      const actions = {
        title: 'Actions',
        sortable: false,
        align: 'center',
        value: 'actions',
        fsExclude: false,
      };
      const locAccess = {
        title: 'Access this Location',
        sortable: false,
        align: 'center',
        value: 'has_access',
        fsExclude: false,
      };
      const locations = {
        title: 'Locations',
        sortable: false,
        align: 'start',
        value: 'accessible_locations',
        fsExclude: false,
      };

      if (this.tableUsersAtThisLocationSelected) {
        return [firstName, lastName, role, email, phone, id, status, actions];
      }
      return [firstName, lastName, locAccess, role, email, phone, id, status, locations, actions];
    },
    merchantUuid(): string {
      return this.$store.getters['MerchantPortal/getMerchantUuid'];
    },
    tableUsersAtThisLocationSelected() {
      return this.selectedTable === 0;
    },
    tableAllUsersSelected() {
      return this.selectedTable === 1;
    },
    selectedFilterRoleNames() {
      return this.selectedRoles.map(role => this.allRoles.find(it => it.value === role)?.label);
    },
    selectedFilterLocationNames() {
      return this.selectedLocations.map(location => {
        return this.allLocations.find(it => it.value === location)?.label;
      });
    },
    hasFiltersSelected() {
      return this.selectedFilterRoleNames.length
        || this.selectedStatuses.length
        || this.selectedFilterLocationNames.length;
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },

  watch: {
    selectedTable: {
      handler() {
        this.resetSearchAndFilters();
        this.populateUsers();
        this.updateLocationFilterOptions();
      },
    },
    page: {
      handler() {
        this.populateUsers();
      },
    },
    itemsPerPage: {
      handler() {
        this.populateUsers();
      },
    },
    search() {
      this.debouncedSearchFn!();
    },
  },

  async created() {
    this.debouncedSearchFn = debounce(this.populateUsers, 500);
    const allRolesResponse = await getAllRoles();
    this.allRoles = allRolesResponse.data.results.map((role: any) => ({
      label: role.display_name,
      value: role.name,
    }));
    const allStatusesResponse = await getAllStatuses();
    this.allStatuses = allStatusesResponse.data.map((status: string) => ({
      label: status,
      value: status,
    }));
    this.populateUsers();
  },

  methods: {
    resetSearchAndFilters() {
      this.search = '';
      this.selectedRoles = [];
      this.selectedStatuses = [];
      this.selectedLocations = [];
    },
    async updateLocationFilterOptions() {
      if (!this.tableAllUsersSelected) return;

      const locationsResponse = await getAllLocations({ page_size: 10000 });
      this.allLocations = locationsResponse.data.results.map((location: any) => ({
        label: location.name,
        value: location.name,
      }));
    },
    /**
     * Depending on which table is selected, we get the users to fill the table from the
     * appropriate endpoint. For `All Users` table we adjust the data to match the table
     * headers better.
     */
    async populateUsers() {
      this.users = [];
      this.loading = true;
      const pageSize = this.itemsPerPage;
      const page = this.page || 1;
      const params: RequestParams = {
        page,
        page_size: pageSize,
      };
      if (this.search) {
        params.search = this.search;
      }
      if (this.selectedRoles.length) {
        params.role = this.selectedRoles.join(',');
      }
      if (this.selectedStatuses.length) {
        params.status = this.selectedStatuses.join(',');
      }
      if (this.selectedLocations.length) {
        params.locations = this.selectedLocations.join(',');
      }

      const getFn = this.tableUsersAtThisLocationSelected
        ? getUsersAtThisLocation
        : getUsersAllLocations;
      const { data } = await getFn(this.merchantUuid, params);

      this.users = data.results.map((user: UserAllLocations) => ({
        ...user,
        role_display_name: MerchantUserRoles[user.role.name] || '',
        phoneNumber: user.phone_number.phone_number,
        locationNames: user.accessible_locations?.length
          ? this.getLocationsNames(user.accessible_locations)
          : undefined,
      }));

      this.tableRowsCount = data.count;
      this.loading = false;
    },
    /**
     * Get the names of the locations the user has access to. If the names are too long, we
     * truncate them and show a button to open a dialog with all the names.
     */
    getLocationsNames(accessible_locations: UserLocation[]) {
      const names = accessible_locations.map(it => it.name);
      const concated = names.join(', ');
      return {
        names,
        concatedValue: concated.length > LOCATION_NAME_LIMIT
          ? `${concated.slice(0, LOCATION_NAME_LIMIT)}...`
          : concated,
        isConcated: concated.length > LOCATION_NAME_LIMIT,
      };
    },
    setUserEditData(user: UserAtThisLocation) {
      this.userEditData = { userId: user.id };
    },
    setUserDeactivateData(user: UserAtThisLocation) {
      this.userDeactivateData = {
        userId: user.id,
        status: user.status,
        firstName: user.first_name,
        lastName: user.last_name,
      };
    },
    onUserSave() {
      this.userEditData = null;
      this.populateUsers();
    },
    onStatusToggleSave() {
      this.userDeactivateData = null;
      this.populateUsers();
    },
    onRoleFilterUpdate(roles: string[]) {
      this.selectedRoles = roles;
      this.populateUsers();
    },
    onStatusFilterUpdate(statuses: string[]) {
      this.selectedStatuses = statuses;
      this.populateUsers();
    },
    onLocationFilterUpdate(locations: string[]) {
      this.selectedLocations = locations;
      this.populateUsers();
    },
    removeSelectedRole(roleName: string) {
      const role = this.allRoles.find(it => it.label === roleName)?.value;
      this.selectedRoles = this.selectedRoles.filter(it => it !== role);
      this.populateUsers();
    },
    removeSelectedStatus(status: string) {
      this.selectedStatuses = this.selectedStatuses.filter(it => it !== status);
      this.populateUsers();
    },
    removeSelectedLocation(locationName: string) {
      const location = this.allLocations.find(it => it.value === locationName)?.label;
      this.selectedLocations = this.selectedLocations.filter(it => it !== location);
      this.populateUsers();
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import '@/assets/scss/mixins/media_queries';
@import "@/assets/scss/table-default";
@import "@/assets/scss/mixins/mixins";

.multi-location-user-table {
  .table-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    height: 3.125rem;
    background-color: var(--grayscale-color-5);
    border-bottom: 1px solid var(--grayscale-color-2);

    &.multi-table {
      justify-content: space-between;
    }

    :deep(.v-chip-group) {
      .v-slide-group__content {
        padding: 0;

        .v-chip {
          box-sizing: border-box;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1rem;
          color: var(--primary-color);
          font-weight: 600;
          background-color: transparent;

          &--selected {
            color: var(--grayscale-color-1);
            background-color: #FFFFFF;
            border: 2px solid var(--grayscale-color-1);

            .v-chip__overlay {
              background: transparent;
            }
          }
        }
      }
    }

    .search-wrapper {
      width: 50%;
      max-width: 20rem;
    }

    .table-mobile-filters {
      display: none;
    }

    @media screen and (max-width: 599px) {
      .table-mobile-filters {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0.5rem;
      }
    }
  }

  @media screen and (max-width: 599px) {
    .table-controls {
      flex-direction: column;
      align-items: flex-start;
      height: auto;

      > div {
        padding: 0.5rem 0.5rem 0 0.5rem;
      }

      .search-wrapper {
        padding-top: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .standard-data-table {
    &__is-owner {
      font-size: 1.5rem;
      color: var(--grayscale-color-2);
    }

    .reinvitable {
      background-color: var(--grayscale-color-4) !important;
    }

    :deep() .v-data-footer {
      height: 3.125rem;
      font-size: 0.9rem;
      color: var(--grayscale-color-1);

      .v-input {
        margin: 0 1rem;

        .v-select__selection {
          font-size: 0.9rem;
          color: var(--grayscale-color-1);
        }
      }
    }
  }

  .selected-filters {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 0.5rem;

    .filter-chip {
      font-size: 0.875rem;
      border: 1px solid var(--grayscale-color-3);
      color: var(--grayscale-color-1);
      background-color: var(--grayscale-color-5);
      white-space: normal;
      height: fit-content;
      min-height: 2rem;
    }
  }

  .locations-button {
    margin: auto 0;
    width: 10rem;
    padding: 0;
    font-weight: 400;
    text-align: left;
    color: var(--primary-color);
    cursor: pointer;
  }

  .location-names {
    display: inline-block;
    width: 10em;
  }

  .v-select__content {
    :deep(.v-list) {
      width: 9.875rem !important;
    }
  }

  @media screen and (max-width: 599px) {
    :deep() {
      .v-table {
        &__wrapper table tbody tr {
          padding: 0 !important;
        }
      }
    }
  }

  :deep() {
    .v-table-header tr th span {
      color: var(--grayscale-color-1);
    }

    .v-table__wrapper tbody tr td {
      color: var(--grayscale-color-1);
    }

    .v-data-table__tr--mobile .v-table__mobile-row .v-data-table__td-value {
      color: var(--grayscale-color-1);
    }
  }
}
</style>
