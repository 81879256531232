<template>
  <v-text-field
    ref="input"
    v-mask="inputMask"
    v-fs-exclude
    v-bind="$attrs"
    autocomplete="off"
    :data-test="dataTestAttr"
    :label="labelAttr"
    :disabled="disabled"
    :variant="variantAttr"
    :prepend-inner-icon="showIcon ? 'mdi-phone' : ''"
    :required="required"
    :rules="[validatePhone]"
    type="tel"
    validate-on="blur" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import phoneValidator from '@/validators/phone_number';

export default defineComponent({
  name: 'PhoneInput',

  mixins: [InputFieldMixin],

  props: {
    showIcon: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data: () => ({
    defaultDataTestAttr: 'phoneInput',
    defaultLabelAttr: 'Phone Number',
  }),

  computed: {
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
    inputMask() {
      return {
        showMaskOnHover: false,
        noValuePatching: false,
        mask: '+1 (999) 999-9999',
        oncleared: this.onMaskCleared,
      };
    },
  },

  created() {
    // The `noValuePatching` option causes the phone input to lose the mask when
    // it's being edited, ie. when there is a value present before applying the
    // mask.
    if (!this.$attrs.value) this.inputMask.noValuePatching = true;
  },

  methods: {
    validatePhone(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      if (!(phoneValidator(value))) return 'Please correct phone format';

      return true;
    },
  },
});
</script>
