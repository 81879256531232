<template>
  <div>
    <div v-if="showEndMessage">
      <p data-test="sessionClosedLabel">
        Your session is closed and you can safely close your browser.
        Come back anytime to access your loan at <a :href="portalUrl">{{ portalUrl }}</a>
      </p>
    </div>
    <bubble-card v-else class="mx-auto">
      <template #card-title>
        <h5>{{ cardTitle }}</h5>
      </template>
      <v-stepper
        :model-value="step"
        alt-labels
        flat
        class="stepper"
        :class="{ 'no-header': !hasToCreateAccount }">
        <v-stepper-header>
          <v-stepper-item
            complete-icon="mdi-check-circle-outline"
            :value="1"
            :complete="step > 1">
            Accept Terms
          </v-stepper-item>
          <v-stepper-item
            complete-icon="mdi-check-circle-outline"
            :value="2"
            :complete="step > 2">
            Password
          </v-stepper-item>
          <v-stepper-item complete-icon="mdi-check-circle-outline" :value="3" />
        </v-stepper-header>

        <v-stepper-window>
          <v-stepper-window-item :value="1">
            <accept-loan @accept="acceptLoanAgreement" />
          </v-stepper-window-item>

          <v-stepper-window-item :value="2">
            <div v-if="hasToCreateAccount">
              <h6 class="mb-6 mt-0 text-dialog-header" data-test="accountCreationLabel">
                Account creation is required to spend on your loan.
              </h6>
              <create-account @created="onAccountCreated" />
            </div>
            <div v-else>
              <p data-test="portalExistsLabel">
                Looks like you already have a Payment Portal Account and you can
                service both loans there. Would you like to log in?
              </p>
              <custom-button
                :href="portalUrl"
                class="mb-4"
                full-width>
                Take Me To Log In
              </custom-button>
              <custom-button
                variant="secondary"
                full-width
                @click="showEndMessage = true">
                Close
              </custom-button>
            </div>
          </v-stepper-window-item>

          <v-stepper-window-item :value="3" />
        </v-stepper-window>
      </v-stepper>
    </bubble-card>

    <complete-later-button />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import CreateAccount from '@/components/Consumer/LoanApplyWizard/ConsumerPortal/CreateAccount.vue';
import AcceptLoan from '@/components/Consumer/LoanApplyWizard/FinalizeLoan/AcceptLoan.vue';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';

export default defineComponent({
  name: 'FinalizeLoan',
  components: {
    AcceptLoan,
    CompleteLaterButton,
    CreateAccount,
    BubbleCard,
    CustomButton,
  },
  data() {
    return {
      step: 1,
      showEndMessage: false,
    };
  },
  computed: {
    appId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
    portalUrl(): string {
      return this.$store.getters['Consumer/getOnboardingDetails'].portalUrl;
    },
    hasToCreateAccount(): boolean {
      return !this.$store.getters['Consumer/getOnboardingDetails'].hasAccount;
    },
    cardTitle(): string {
      if (this.step === 1) {
        return 'Please Accept The Terms Below';
      }

      if (this.hasToCreateAccount) {
        return 'Create A Password To Set Up Your Account';
      }

      return 'Congratulations!';
    },
  },
  methods: {
    async acceptLoanAgreement() {
      if (this.hasToCreateAccount) {
        await this.$store.dispatch('Consumer/getInviteToConsumerPortal');
      }

      this.step = 2;
    },
    onAccountCreated() {
      window.location.href = this.portalUrl;
    },
  },
});
</script>

<style lang="scss" scoped>
.stepper {
  margin: -1rem;

  &.no-header :deep(.v-stepper-header) {
    display: none;
  }

  :deep() {
    .v-stepper-header {
      box-shadow: none;
      background-color: var(--grayscale-color-5);
      padding: 0 0.5rem;
      flex-wrap: nowrap;

      .v-stepper-item {
        padding: 1rem 0;
        flex-basis: 6rem;
        opacity: 1;

        .v-avatar {
          background-color: var(--secondary-color) !important;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }

        .v-stepper-item__content {
          color: var(--secondary-color);
          font-size: 0.875rem;
          font-weight: 700;
          text-shadow: none;
          display: none;
        }

        &--selected > .v-stepper-item__content,
        &--complete > .v-stepper-item__content {
          display: block;
        }

        &--complete {
          .v-avatar {
            background-color: transparent !important;
            border: none;

            .v-icon {
              font-size: 1.75rem;
              color: var(--secondary-color);
            }
          }
        }

        .v-icon {
          font-size: 1.5rem;
        }
      }
    }

    .v-stepper-window {
      margin: 0;
      padding: 1rem;
    }
  }
}

.text-dialog-header {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 142%;
  font-style: normal;
}

.checkbox {
  color: var(--grayscale-color-1);
  margin-bottom: 0.625rem;

  :deep(.v-input__control) {
    align-items: center;

    label {
      padding-bottom: 0.2rem;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }
}

:deep(.password-input-container) {
  .password-rules {
    font-size: 0.75rem;

    p {
      font-size: 0.75rem;
    }
  }
}
</style>
