import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: "standard-data-table__actions",
    "no-gutters": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_menu, { location: "left" }, {
        activator: _withCtx(({ props }) => [
          (!_ctx.hideActionsMenuButton)
            ? (_openBlock(), _createBlock(_component_custom_button, _mergeProps({
                key: 0,
                class: "standard-data-table__actions-dots",
                "data-test": `uma${_ctx.user.id}`,
                variant: "icon",
                size: "small",
                "aria-label": "Actions",
                ripple: false
              }, props), {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-dots-vertical")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1040, ["data-test"]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, { class: "text-left" }, {
            default: _withCtx(() => [
              (!_ctx.hideEditButton)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    class: "action-buttons",
                    disabled: _ctx.processing,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit', _ctx.user)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_custom_button, {
                        disabled: _ctx.processing,
                        class: "custom-button",
                        label: "Edit",
                        variant: "text",
                        ripple: false
                      }, null, 8, ["disabled"])
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (!_ctx.hideActiveButton)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 1,
                    class: "action-buttons",
                    disabled: _ctx.processing,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('deactivate', _ctx.user)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_custom_button, {
                        disabled: _ctx.processing,
                        class: "custom-button",
                        label: _ctx.user.status === 'Active' ? 'Deactivate' : 'Reactivate',
                        variant: "text",
                        ripple: false
                      }, null, 8, ["disabled", "label"])
                    ]),
                    _: 1
                  }, 8, ["disabled"]))
                : _createCommentVNode("", true),
              (!_ctx.hideReInviteButton)
                ? (_openBlock(), _createBlock(_component_v_list_item, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_custom_button, {
                        class: "custom-button",
                        disabled: _ctx.processing || _ctx.sendingReinvite,
                        label: "Resend Invite",
                        variant: "text",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('reinvite', _ctx.user.email)))
                      }, null, 8, ["disabled"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}