<template>
  <div>
    <div v-if="inPlaceInput" class="card-text__info-group__title">
      <span>{{ label }}</span>
    </div>

    <currency-input
      v-bind="$attrs"
      data-test="annualSelect"
      :disabled="disabled"
      :error-messages="errors.annual_sales_revenue"
      :in-place="inPlaceInput"
      label="Annual Sales Revenue"
      :max="999999999999999"
      :min="0"
      required
      show-icon
      error-capitalized />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import GetErrors from '@/mixins/GetErrors';

export default defineComponent({
  name: 'AnnualSalesRevenue',

  components: { CurrencyInput },

  mixins: [GetErrors],

  props: {
    label: { type: String, default: '' },
    inPlaceInput: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
});
</script>
