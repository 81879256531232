<template>
  <div>
    <div :class="wrapElementsTablet ? 'flex-wrap' : ''" class="customer-summary-card-user-details">
      <template v-if="applicationSelected">
        <div class="customer-summary-card-user-details__user-name">
          <v-avatar
            class="avatar text-uppercase"
            color="var(--grayscale-color-4)"
            :size="avatarSize">
            <span>{{ nameInitials }}</span>
          </v-avatar>
        </div>

        <div class="customer-summary-card-user-details__amount-app-data">
          <div class="app-data-inner">
            <div :class="isSpecificWidth ? 'flex-wrap' : ''" class="d-flex justify-space-between">
              <custom-button
                v-private="'Customer name on summary card'"
                :disabled="processing"
                variant="text"
                size="small"
                data-test="nameButton"
                class="btn-compact text-capitalize"
                @click="showUserDetailsModal">
                {{ name }}
              </custom-button>

              <div class="section-header__actions">
                <v-menu
                  v-if="customerAccount && isAdminOrAbove"
                  min-width="7rem"
                  offset="0 -16"
                  location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      class="table-header__icon"
                      data-test="refundMenuBtn"
                      v-bind="props">
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="$emit('showRefundsDialog')">
                      <v-list-item-title class="menu-item" data-test="refundBtn">
                        Refund
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <div class="customer-summary-card-user-details__app-id">
              <span id="appId" data-test="detAppId">{{ appId }}</span>
              <copy-to-clipboard-popup :string-to-copy="appId ? appId.replaceAll('-', '') : ''" />
            </div>
          </div>

          <div
            v-if="isValidForTransaction(customerAccount) && creditBalance && !isTablet"
            :class="{ 'customer-summary-card-user-details__balance--unknown': !creditBalance }"
            class="customer-summary-card-user-details__balance">
            <div class="customer-summary-card-user-details__balance__label">
              <span>
                Available Spend: <span v-private>{{ creditBalance }}</span>
              </span>
            </div>
          </div>

          <div v-if="!isTablet" class="customer-summary-card-user-details__spent-amount">
            <span data-test="purchaseWindowEndLabel">Purchase Window End Date:
              <span v-private>{{ purchaseWindowEndDate }}</span>
            </span>
          </div>
        </div>

        <customer-summary-details-user-details-modal
          :dialog="showUserDetails"
          :user-details="userDetails"
          :user-phone-number="selectedLoanApplication.phone_number"
          :on-close="closeUserDetailsModal" />
      </template>

      <div
        v-else
        class="select-user-txt"
        data-test="nameLabel">
        {{ name }}
      </div>
    </div>

    <div v-if="applicationSelected && isTablet" class="tablet-section">
      <div
        v-if="isValidForTransaction(customerAccount) && creditBalance"
        :class="{ 'customer-summary-card-user-details__balance--unknown': !creditBalance }"
        class="customer-summary-card-user-details__balance">
        <div class="customer-summary-card-user-details__balance__label text-left">
          <span>
            Available Spend: <span v-private>{{ creditBalance }}</span>
          </span>
        </div>
      </div>

      <div class="customer-summary-card-user-details__spent-amount text-left">
        <span>Purchase Window End Date: <span v-private>{{ purchaseWindowEndDate }}</span></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { get } from 'lodash';
import moment from 'moment';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomerSummaryDetailsUserDetailsModal
  from '@/components/CustomerSummaryCard/Modals/UserDetailsModal.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import GetProcessing from '@/mixins/GetProcessing';
import DATE_CONSTANTS from '@/constants/DateConstants';
import CopyToClipboardPopup from '@/components/CustomerSummaryCard/CopyToClipboardPopup.vue';
import { defineComponent } from 'vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';

export default defineComponent({
  name: 'SummaryCardDetails',
  components: {
    CustomButton,
    CustomerSummaryDetailsUserDetailsModal,
    CopyToClipboardPopup,
  },
  mixins: [
    GetHumanReadableIdLabelMixin,
    GetProcessing,
    MerchantPermissionsMixin,
  ],
  data() {
    return {
      showUserDetails: false,
    };
  },
  computed: {
    selectedLoanApplication() {
      return this.$store.getters['MerchantPortal/getSelectedLoanApplication'];
    },
    applicationSelected() {
      return this.selectedLoanApplication.consumer_application;
    },
    userDetails() {
      return get(this.selectedLoanApplication, 'user', {});
    },
    nameInitials() {
      const firstLetter = this.userDetails.first_name.charAt(0);
      const secondLetter = this.userDetails.last_name.charAt(0);
      return firstLetter + secondLetter;
    },
    name() {
      const name = [this.userDetails.first_name, this.userDetails.last_name];
      const joinedName = name.join('');
      let displayName = '';

      if (joinedName.length < 30) {
        displayName = name.join(' ');
      } else if (joinedName.length === 30) {
        const abrName = [`${this.userDetails.first_name.charAt(0)}.`, this.userDetails.last_name];
        displayName = abrName.join(' ');
      } else if (joinedName.length > 30) {
        const abrCutName = [`${this.userDetails.first_name.charAt(0)}.`, `${this.userDetails.last_name.slice(0, 24)}...`];
        displayName = abrCutName.join(' ');
      }

      return this.applicationSelected ? displayName : 'Your Customer’s Name will Appear Here';
    },
    appId() {
      return this.applicationSelected
        ? this.formatHRID(this.selectedLoanApplication.human_readable_id)
        : null;
    },
    customerAccount() {
      return get(this.selectedLoanApplication, 'consumer_account', 0);
    },
    creditBalance() {
      return this.applicationSelected
        ? CurrencyFormatLong(get(this.selectedLoanApplication, 'consumer_account.available_spend', 0))
        : null;
    },
    amountSpent() {
      return this.applicationSelected
        ? CurrencyFormatLong(get(
          this.selectedLoanApplication,
          'consumer_account.amount_spent',
          0,
        ))
        : null;
    },
    purchaseWindowEndDate() {
      const getDate = new Date(this.selectedLoanApplication.purchase_window_end_date);
      return moment(getDate).format(DATE_CONSTANTS.dateFormatSlashes);
    },
    avatarSize() {
      if ((this.$vuetify.display.width > 599) && (this.$vuetify.display.width < 1024)) {
        return '72';
      }
      return '112';
    },
    isSpecificWidth() {
      return ((this.$vuetify.display.width > 599) && (this.$vuetify.display.width < 810));
    },
    wrapElementsTablet() {
      return ((this.$vuetify.display.width > 599) && (this.$vuetify.display.width < 760));
    },
    isTablet(): boolean {
      return ((this.$vuetify.display.width > 599) && (this.$vuetify.display.width < 1024));
    },
  },
  methods: {
    isValidForTransaction(application: any): boolean {
      const isValid = get(application, 'is_valid_for_transaction', 0);

      if (isValid === true) {
        return true;
      }

      return false;
    },
    showUserDetailsModal(): void {
      this.showUserDetails = true;
    },
    closeUserDetailsModal(): void {
      this.showUserDetails = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/mixins/media_queries';

.tablet-section {
  padding: 0 1rem 1.5rem 1rem;
}

.customer-summary-card-user-details {
  display: flex;
  margin: 0;
  padding: 0 1rem 1.5rem 1rem;
  font-weight: bold;
  text-align: left;

  @include tablet {
    padding: 0 0.2rem 1rem 1rem;
  }

  &__user-name,
  &__user-id {
    display: flex;
  }

  &__spent-amount span {
    color: var(--grayscale-color-1);
  }

  &__user-name {
    flex-shrink: 3;
    flex-wrap: wrap;
    margin-right: 1rem;
    margin-top: 1rem;

    @include mobile {
      margin-top: 1rem;
      margin-right: 1rem;
    }

    @include tablet {
      margin-top: 1rem;
      margin-right: 1rem;
    }

    .avatar {
      span {
        font-family: Helvetica, 'Montserrat', sans-serif;
        color: #C4CBD2;
        font-weight: 700;
        font-size: 3.625rem;

        @include tablet {
          font-size: 2.25rem;
        }
      }
    }

    .btn-compact {
      display: flex;
      justify-content: flex-end;
      color: var(--primary-color);
      padding: 0;
      font-size: 1rem;
    }
  }

  &__user-name, &__amount-app-data {
    width: 100%;
  }

  &__amount-app-data {
    text-align: left;
    margin-top: 1rem;

    .app-data-inner {
      .customer-summary-card-user-details__app-id {
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid var(--grayscale-color-3);
        padding-bottom: 0.5rem;
        max-width: 90%;

        @include mobile {
          max-width: 75%;
        }
      }

      div .btn-compact {
        font-size: 1.25rem !important;

        @include tablet {
          font-size: 1rem !important;
        }
      }
    }

    @include mobile {
      margin-top: 1rem;
    }
  }

  &__app-id, &__spent-amount {
    font-weight: normal;
  }

  &__app-id span {
    font-size: 1.25rem;
    color: var(--grayscale-color-1);
    font-weight: 700;

    @include tablet {
      font-size: 1rem;
    }
  }

  &__balance {
    color: var(--grayscale-color-1);

    &--unknown {
      color: var(--grayscale-color-2);
    }

    &__amount,
    &__bullets {
      color: var(--grayscale-color-1);
      margin-left: .3rem;
    }

    &__label {
      height: 1.875rem;
      line-height: 1.875rem;

      span {
        font-weight: 400;
      }

      @include small-tablet {
        height: auto;
      }
    }
  }
}

.select-user-txt {
  margin-top: 1rem;
  align-self: flex-start;
}

@include up-to-desktop {
  .customer-summary-card-user-details {
    border-bottom: none;

    &__user-name, &__amount-app-data {
      text-align: left;
      padding: 0 0 1rem .5rem;

      @include mobile {
        padding: 0 0 0.5rem 0;
      }

      @include tablet {
        padding: 0;
      }
    }

    &__app-id, &__spent-amount {
      margin-top: 0;
    }
  }
}

</style>
