import {
  onBeforeMount, ref, Ref, watch,
} from 'vue';
import store from '@/store';
import { PaginatedResponse, RequestOptions } from '@/api/types';
import { useConsumerActivity } from './useConsumerActivity';
import { useTableRequests } from './useTableRequests';

type Request<T> = (options: RequestOptions) => PaginatedResponse<T>

interface ConsumerActivityTableControls {
  orderBy?: Ref<string> | string;
  orderDesc?: Ref<boolean>;
}

// eslint-disable-next-line import/prefer-default-export
export const useConsumerActivityTable = <T>(
  request: Request<T>,
  controls?: ConsumerActivityTableControls,
) => {
  const { search, loading, filters } = useConsumerActivity();
  const orderBy = controls?.orderBy ? ref(controls.orderBy) : undefined;
  const orderDesc = controls?.orderDesc;

  const {
    count, fetchData, page, results,
  } = useTableRequests(request, {
    search, loading, orderBy, orderDesc, filters,
  });

  const seeConsumerSummary = (applicationuuid: string) => {
    store.dispatch('MerchantPortal/selectLoanApplication', applicationuuid);
    document.getElementById('app')!.scrollIntoView({ behavior: 'smooth' });
  };

  onBeforeMount(fetchData);

  const watchSort = (val: Ref<unknown> | undefined) => val && watch(val, fetchData);
  watchSort(orderBy);
  watchSort(orderDesc);

  // Exposed method for parent component to refresh the table
  const refresh = () => fetchData();

  return {
    search,
    loading,
    count,
    page,
    results,
    seeConsumerSummary,
    refresh,
  };
};
