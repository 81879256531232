<template>
  <div class="offer-code-table--title--actions">
    <v-switch
      v-model="internalValue"
      inset
      hide-details
      :ripple="false"
      @update:model-value="$emit('toggle:active')" />
    <v-menu attach location="bottom left">
      <template v-slot:activator="{ props }">
        <v-btn
          icon="mdi-dots-vertical"
          variant="plain"
          data-test="openEditMenu"
          v-bind="props" />
      </template>
      <v-list class="offer-code-table--menu-list">
        <v-list-item>
          <custom-button
            data-test="addRemoveProducts"
            variant="text"
            size="small"
            @click="$emit('click:editProducts')">
            Add or Remove Products
          </custom-button>
        </v-list-item>
        <v-list-item>
          <custom-button
            data-test="editOfferCodeName"
            variant="text"
            size="small"
            @click="$emit('click:editName')">
            Edit Offer Code Name
          </custom-button>
        </v-list-item>
        <v-list-item>
          <custom-button
            data-test="deleteOfferCode"
            variant="text"
            size="small"
            @click="$emit('click:delete')">
            Delete This Offer Code
          </custom-button>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'HeaderActions',
  components: { CustomButton },
  props: {
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      internalValue: null as boolean|null,
    };
  },
  watch: {
    active: {
      handler(value: boolean) {
        this.internalValue = value;
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.offer-code-table--title--actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep() .v-input {
    margin: 0 !important;

    .v-switch__track {
      background-color: var(--grayscale-color-3);
      opacity: 1;
      // Measurements of old v-switch
      height: 28px !important;
      width: 48px !important;
    }

    .v-selection-control--dirty {
      .v-switch__track {
        background-color: var(--grayscale-color-1)!important;
      }
    }

    .v-switch__thumb {
      // Measurements of old v-switch
      height: 20px !important;
      width: 20px !important;
      transform: none !important;
    }

    &--readonly :deep(input) {
      cursor: default !important;
    }

    &--selection-controls {
      padding: 0;
    }
  }
}
</style>
