<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    z-index="201"
    max-width="15.3125rem">
    <template v-slot:activator="{ props }">
      <div v-bind="props" class="filter-activator">
        <span>Status</span>
        <v-icon>mdi-filter-variant</v-icon>
      </div>
    </template>

    <status-filter-body @close="showMenu = false" />
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import StatusFilterBody from './StatusFilterBody.vue';

export default defineComponent({
  components: { StatusFilterBody },

  setup() {
    const showMenu = ref(false);

    return { showMenu };
  },

});
</script>

<style lang="scss" scoped>
.filter-activator {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  cursor: pointer;
}
</style>
