<template>
  <custom-expansion-panel
    :expanded="!isMobile"
    :can-collapse="!isMobile"
    class="fs-unmask full-width gray-border section-card">
    <template #title="{ isExpanded }">
      <div class="section-header">
        <v-select
          v-model="selected"
          class="old-v-select section-header__title"
          :items="items"
          :readonly="!isExpanded"
          :menu-icon="isExpanded ? '$dropdown' : ''"
          hide-details
          flat
          variant="solo"
          bg-color="transparent"
          data-test="recentLoanDropdown"
          :menu-props="{ bottom: true, offsetY: true }"
          @click.stop>
          <template v-slot:item="{ props }">
            <v-list-item v-bind="props" class="text-left" />
          </template>
        </v-select>

        <div class="search-refresh-wrapper section-header__actions">
          <v-text-field
            v-model="search"
            append-inner-icon="mdi-magnify"
            label="Search"
            class="table-search-bar"
            variant="underlined"
            width="14rem"
            single-line
            hide-details
            data-test="portalTableSearch"
            @click.stop />

          <div>
            <standard-data-table-refresh-button
              v-if="!isMobile"
              :active="refreshing"
              @click="refreshTable" />
          </div>
        </div>
      </div>
    </template>
    <template #text>
      <v-text-field
        v-model="search"
        append-inner-icon="mdi-magnify"
        label="Search"
        flat
        bg-color="transparent"
        variant="underlined"
        class="table-search-bar--mobile"
        single-line
        hide-details
        data-test="portalTableSearch" />
      <table-small
        v-if="selected === TableTypesEnum.LOAN_APPLICATIONS"
        v-model:page="currentPage"
        :headers="headers"
        :applications="applications"
        :get-human-readable="getHumanReadableIdLabel"
        :get-fee-label="getMerchantFeeLabel"
        :app-statuses="consumerApplicationStatuses"
        :get-name-label="getProductNameLabel"
        :search-input="search"
        :select-loan-app="selectLoanApp"
        :items-length="applicationsCount"
        :is-mobile="isMobile"
        @updatePage="updateApplicationsPage" />

      <table-small
        v-if="selected === TableTypesEnum.LOAN_INVITATIONS"
        v-model:page="currentPage"
        :headers="headersInvitations"
        :applications="invitations"
        :get-human-readable="getHumanReadableIdLabel"
        :get-fee-label="getMerchantFeeLabel"
        :app-statuses="consumerApplicationStatuses"
        :get-name-label="getProductNameLabel"
        :search-input="search"
        :select-loan-app="selectLoanApp"
        :items-length="invitationsCount"
        :is-mobile="isMobile"
        @updatePage="updateInvitationsPage" />

      <table-small
        v-if="selected === TableTypesEnum.AVAILABLE_TRANSACTIONS"
        v-model:page="currentPage"
        :headers="headersTransactions"
        :applications="transactions"
        :get-human-readable="getHumanReadableIdLabel"
        :get-amount-label="getMerchantAmountLabel"
        :get-spend-label="getMerchantAvaliableSpendLabel"
        :app-statuses="consumerApplicationStatuses"
        :get-name-label="getProductNameLabel"
        :search-input="search"
        :select-loan-app="selectLoanApp"
        :items-length="transactionsCount"
        :is-mobile="isMobile"
        @updatePage="updateTransactionsPage" />
    </template>
  </custom-expansion-panel>
</template>

<script lang="ts">
import CustomExpansionPanel from '@/components/CustomExpansionPanel.vue';
import StandardDataTableRefreshButton
  from '@/components/Buttons/DataTableRefreshButton.vue';
import ConsumerApplicationStatuses from '@/enums/ConsumerApplicationStatuses';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import GetProcessing from '@/mixins/GetProcessing';
import { LoanInvitation } from '@/interfaces/merchantPortal/LoanInvitation';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import TableSmall from '@/components/Tables/TableSmall.vue';
import SelectLoanApplicationMixin from '@/mixins/Merchant/SelectLoanApplicationMixin';
import debounce from 'lodash/debounce';
import { defineComponent } from 'vue';

enum TableTypesEnum {
  LOAN_APPLICATIONS = 'Recent Loan Applications',
  LOAN_INVITATIONS = 'Recent Loan Invitations',
  AVAILABLE_TRANSACTIONS = 'Available Transactions',
}

export default defineComponent({
  name: 'RecentLoanApplications',
  components: {
    CustomExpansionPanel,
    StandardDataTableRefreshButton,
    TableSmall,
  },
  mixins: [
    GetHumanReadableIdLabelMixin,
    GetProcessing,
    SelectLoanApplicationMixin,
  ],
  data() {
    const headers: Array<TableHeaderInterface> = [
      {
        title: 'Name',
        sortable: false,
        value: 'user.full_name',
      },
      {
        title: 'Application ID',
        align: 'center',
        sortable: false,
        value: 'human_readable_id',
      },
      {
        title: 'Status',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        title: 'Amount',
        align: 'end',
        sortable: false,
        value: 'credit_limit',
      },
      {
        title: 'Product ID',
        align: 'end',
        sortable: false,
        value: 'product_name',
      },
    ];
    const headersInvitations: Array<TableHeaderInterface> = [
      {
        title: 'Sent To',
        sortable: false,
        value: 'sentTo',
      },
      {
        title: 'Date/Time',
        align: 'center',
        sortable: false,
        value: 'activate_date',
      },
      {
        title: 'Offer Code',
        value: 'offer_code_name',
      },
      {
        title: 'Submitted by',
        align: 'end',
        sortable: false,
        value: 'invited_by_merchant_user',
      },
    ];
    const headersTransactions: Array<TableHeaderInterface> = [
      {
        title: 'Customer Name',
        value: 'user.full_name',
      },
      {
        title: 'Amount Spent',
        value: 'amount_spent',
      },
      {
        title: 'Available Spend',
        value: 'available_spend',
      },
      {
        title: 'Purchase Window End Date',
        value: 'purchase_window_end_date',
      },
      {
        title: 'Application ID',
        align: 'end',
        value: 'human_readable_id',
      },
    ];
    return {
      headers,
      headersInvitations,
      headersTransactions,
      selected: TableTypesEnum.LOAN_APPLICATIONS,
      disabled: false,
      search: '',
      loanApplications: null,
      refreshing: false,
      currentPage: 1,
      searchActivated: false,
      debouncedSearchHandler: null as any,
    };
  },
  computed: {
    items() {
      return [TableTypesEnum.LOAN_APPLICATIONS, TableTypesEnum.LOAN_INVITATIONS, TableTypesEnum.AVAILABLE_TRANSACTIONS];
    },
    consumerApplicationStatuses() {
      return ConsumerApplicationStatuses;
    },
    TableTypesEnum() {
      return TableTypesEnum;
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
    applications() {
      return this.$store.getters['MerchantPortal/getNewLoanApplications'];
    },
    applicationsCount() {
      return this.$store.getters['MerchantPortal/getNewLoanApplicationsCount'];
    },
    transactions() {
      return this.$store.getters['MerchantPortal/getAvailableTransactions'];
    },
    transactionsCount() {
      return this.$store.getters['MerchantPortal/getAvailableTransactionsCount'];
    },
    invitations(): Array<LoanInvitation> {
      const invitations: Array<LoanInvitation> = this.$store.getters['MerchantPortal/getNewLoanInvitations'];
      return invitations.map(it => {
        const sentTo = it.email || it.phone;
        return { ...it, sentTo };
      });
    },
    invitationsCount(): number {
      return this.$store.getters['MerchantPortal/getNewLoanInvitationsCount'];
    },
    searchedApplications() {
      return this.$store.getters['MerchantPortal/getSearchedItems'];
    },
    searchedInput() {
      return this.$store.getters['MerchantPortal/getSearchInput'];
    },
  },
  watch: {
    applications: {
      handler(newVal: any) {
        this.loanApplications = newVal;
      },
      immediate: true,
    },
    searchedApplications: {
      handler(newVal: any) {
        if (this.searchedInput) {
          this.loanApplications = newVal;
        } else {
          this.loanApplications = this.applications;
        }
      },
      immediate: true,
    },
    selected: {
      handler(val: TableTypesEnum) {
        this.search = '';
        if (val === TableTypesEnum.LOAN_INVITATIONS) {
          this.updateInvitationsPage({ pageNumber: 1 });
        } else if (val === TableTypesEnum.AVAILABLE_TRANSACTIONS) {
          this.updateTransactionsPage({ pageNumber: 1, isDesc: true, sortHeader: 'purchase_window_end_date' });
        }
        this.currentPage = 1;
      },
      immediate: true,
    },
    search(val: string) {
      this.debouncedSearchHandler(val);
    },
  },
  created() {
    this.debouncedSearchHandler = debounce(this.searchHandler, 500);
  },
  methods: {
    async searchHandler(val: string) {
      const params = val && val.length > 2
        ? { pageNumber: 1, searchString: val }
        : null;

      let action = null;

      if ((val.length > 2) || (val.length < 1 && this.searchActivated)) {
        this.refreshing = true;
        this.currentPage = 1;
        this.searchActivated = true;

        action = this.getActionName();

        if (action) {
          await this.$store.dispatch(action, val.length > 2 ? params : { pageNumber: 1 });
          this.refreshing = false;
        }
      }
    },
    async refreshTable() {
      this.searchActivated = false;
      this.search = '';
      this.refreshing = true;
      if (this.selected === TableTypesEnum.LOAN_APPLICATIONS) {
        await this.updateApplicationsPage({ pageNumber: 1 });
      } else if (this.selected === TableTypesEnum.LOAN_INVITATIONS) {
        await this.updateInvitationsPage({ pageNumber: 1 });
      } else if (this.selected === TableTypesEnum.AVAILABLE_TRANSACTIONS) {
        await this.updateTransactionsPage({ pageNumber: 1 });
      }
      this.currentPage = 1;
      this.refreshing = false;
    },
    selectLoanApp(application: any) {
      this.selectLoanApplication(application);
      document.getElementById('app')!.scrollIntoView({ behavior: 'smooth' });
    },
    getProductNameLabel(item: any): string {
      return item?.consumer_account?.product_name || item?.product_name || '';
    },
    getMerchantFeeLabel(application: any): string {
      const creditLimit = application?.consumer_account?.credit_limit
      || application?.credit_limit
      || 0;
      return CurrencyFormatLong(creditLimit);
    },
    getMerchantAmountLabel(application: any): string {
      const creditLimit = application?.consumer_account.amount_spent
      || application?.amount_spent
      || 0;
      return CurrencyFormatLong(creditLimit);
    },
    getMerchantAvaliableSpendLabel(application: any): string {
      const creditLimit = application?.consumer_account.available_spend
      || application?.available_spend
      || 0;
      return CurrencyFormatLong(creditLimit);
    },
    async updatePage(val: any, action: string) {
      this.refreshing = true;

      this.currentPage = val.pageNumber;

      const payload = {
        pageNumber: val.pageNumber,
        headerSort: val.sortHeader,
        isDescending: val.isDesc,
        searchString: val.searchString,
      };

      await this.$store.dispatch(action, payload);

      this.refreshing = false;
    },
    updateApplicationsPage(val: any) {
      return this.updatePage(val, 'MerchantPortal/dispatchFetchNewLoanApplications');
    },
    updateInvitationsPage(val: any) {
      return this.updatePage(val, 'MerchantPortal/dispatchGetInvitations');
    },
    updateTransactionsPage(val: any) {
      if (val.isDesc !== undefined) {
        return this.updatePage(val, 'MerchantPortal/dispatchGetAvailableTransactionsSort');
      }

      return this.updatePage(val, 'MerchantPortal/dispatchGetAvailableTransactions');
    },
    getActionName(): string {
      let action = '';
      if (this.selected === TableTypesEnum.LOAN_APPLICATIONS) {
        action = 'MerchantPortal/dispatchFetchNewLoanApplications';
      } else if (this.selected === TableTypesEnum.LOAN_INVITATIONS) {
        action = 'MerchantPortal/dispatchGetInvitations';
      } else if (this.selected === TableTypesEnum.AVAILABLE_TRANSACTIONS) {
        action = 'MerchantPortal/dispatchGetAvailableTransactions';
      }
      return action;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/mixins/media_queries';
@import '@/assets/scss/portal-section';
@import '@/assets/scss/main-fonts';

:deep(.v-item-group .v-expansion-panel .v-expansion-panel-title) {
  padding-left: 0;
}

.merchant-portal-recent-loan-applications {
  @include data-table-card-full-size;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: var(--grayscale-color-1);

  & .columnheader {
    color: var(--grayscale-color-1) !important;
    font-weight: bold !important;
    border-bottom: 0px !important;
  }

  & .btn-link {
    font-weight: normal;
  }

  .name-btn {
    margin-left: -.5rem;
  }
}

.full-width {
  width: 100%;
}

.menu-item {
  cursor: pointer;
}

.section-header {
  border-bottom: none !important;

  .search-refresh-wrapper {
    display: flex;
    align-items: center;

    .table-search-bar {
      max-width: 16rem;
    }
  }

  &__title {
    margin-right: 2rem;
    margin-bottom: 0 !important;
    max-width: 16rem;

    :deep(.v-field__input) {
      padding: 0!important;

      .v-select__selection {
        max-width: min-content;

        .v-select__selection-text {
          @extend .subheader;

          margin-right: 0;
          overflow: visible;
          text-overflow: initial;
        }

        input {
          display: none;
        }
      }

      .v-icon {
        color: var(--secondary-color);
        font-size: 2rem;
      }

      .v-input__append-inner {
        margin-left: 0;
      }
    }
  }
}

.table-search-bar :deep() .v-label,
.table-search-bar--mobile :deep() .v-label {
  font-size: var(--mmt-input-placeholder-font-size);
}

.table-search-bar--mobile {
  display: none;
}

@include tablet {
  .section-header {
    flex-wrap: nowrap;

    .search-refresh-wrapper {
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: .5rem;
      flex: 1;

      .table-search-bar {
        display: none;

         &--mobile {
          display: grid;
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

@include mobile {
  .table-search-bar {
    display: none;

    &--mobile {
      display: grid;
      margin-top: 0;
      padding: 0.5rem 1rem;
      background: var(--grayscale-color-5);
    }
  }

  :deep(.v-item-group .v-expansion-panel) {
    border-radius: 0 !important;

    .v-expansion-panel-title {
      padding-left: 1rem;
    }
  }
}
</style>
