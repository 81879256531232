<template>
  <v-row class="standard-data-table__actions" no-gutters>
    <v-menu location="left">
      <template v-slot:activator="{ props }">
        <custom-button
          v-if="!hideActionsMenuButton"
          class="standard-data-table__actions-dots"
          :data-test="`uma${user.id}`"
          variant="icon"
          size="small"
          aria-label="Actions"
          :ripple="false"
          v-bind="props">
          <v-icon>mdi-dots-vertical</v-icon>
        </custom-button>
      </template>
      <v-list class="text-left">
        <v-list-item
          v-if="!hideEditButton"
          class="action-buttons"
          :disabled="processing"
          @click="$emit('edit', user)">
          <custom-button
            :disabled="processing"
            class="custom-button"
            label="Edit"
            variant="text"
            :ripple="false" />
        </v-list-item>
        <v-list-item
          v-if="!hideActiveButton"
          class="action-buttons"
          :disabled="processing"
          @click="$emit('deactivate', user)">
          <custom-button
            :disabled="processing"
            class="custom-button"
            :label="user.status === 'Active' ? 'Deactivate' : 'Reactivate'"
            variant="text"
            :ripple="false" />
        </v-list-item>
        <v-list-item v-if="!hideReInviteButton">
          <custom-button
            class="custom-button"
            :disabled="processing || sendingReinvite"
            label="Resend Invite"
            variant="text"
            @click="$emit('reinvite', user.email)" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';
import { UserAllLocations } from '@/interfaces/merchantPortal/UserInterface';
import UsersRolesEnum from '@/enums/UsersRolesEnum';

export default defineComponent({
  name: 'TableActions',

  components: { CustomButton },

  mixins: [MerchantPermissionsMixin],

  props: {
    user: { type: Object as PropType<UserAllLocations>, required: true },
    processing: { type: Boolean, default: false },
    sendingReinvite: { type: Boolean, default: false },
    allUsersTable: { type: Boolean, default: false },
  },

  computed: {
    loggedInUserId(): number {
      return this.$store.getters['Auth/getUserId'];
    },
    loggedInUserIsSalesPerson(): boolean {
      return !this.isAdminOrAbove && !this.isSalesManager && !this.isSalesRep;
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
    userIsActive() {
      return this.user.status === 'Active';
    },
    userIsMerchantOwner() {
      return this.user.is_merchant_owner;
    },
    userIsAdmin() {
      return this.user.role.name === UsersRolesEnum.ADMIN;
    },
    userIsSalesManager() {
      return this.user.role.name === UsersRolesEnum.SALES_MANAGER;
    },
    userIsSalesRep() {
      return this.user.role.name === UsersRolesEnum.SALES_REP;
    },
    userIsCorporateAdmin() {
      return this.user.role.name === UsersRolesEnum.CORPORATE_ADMIN;
    },
    checkRole() {
      return (!this.allUsersTable && this.isAdminOrAbove && this.user.is_merchant_owner)
      || (this.isSalesManager && this.userIsAdmin)
      || (this.isSalesManager && this.userIsSalesManager);
    },
    hideActionsMenuButton() {
      // Sales managers can only edit sales reps
      // Nobody can edit corporate admins
      return (this.isSalesManager && !this.userIsSalesRep) || this.userIsCorporateAdmin;
    },
    hideActiveButton(): boolean {
      return this.loggedInUserIsSalesPerson
      || this.user.can_be_reinvited
      || (this.user.id === this.loggedInUserId)
      || this.checkRole;
    },
    hideEditButton(): boolean {
      return this.loggedInUserIsSalesPerson
      || !this.userIsActive
      || (this.user.is_merchant_owner && this.user.id === this.loggedInUserId)
      || this.checkRole;
    },
    hideReInviteButton(): boolean {
      return !this.user.can_be_reinvited || (this.user.id === this.loggedInUserId);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/mixins/media_queries';

.standard-data-table {
  &__actions {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    max-width: 15rem;

    @include up-to-large-desktop {
      max-width: fit-content;
    }

    @include mobile {
      flex-direction: column;
    }

    &-dots {
      min-width: auto !important;
      color: var(--grayscale-color-1) !important;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}

.action-buttons {
  font-size: 1rem !important;
  padding-left: 1.125rem;
}

.custom-button {
  @include user-management-custom-button;
}
</style>
