<template>
  <nav class="merchant-oboarding-wizard-header v-elevation-4" data-test="mobWizardHeader">
    <v-container class="merchant-oboarding-wizard-header__container">
      <v-row
        v-if="showLogo"
        align="center"
        class="ma-0">
        <v-col
          class="merchant-oboarding-wizard-header__logo-row"
          lg="4"
          sm="3"
          cols="3">
          <div class="merchant-oboarding-wizard-header__logo-container">
            <img
              :src="logoUrl"
              alt="Logo"
              class="merchant-oboarding-wizard-header__logo standard-logo"
              data-test="logoImg">
          </div>
        </v-col>

        <v-col
          class="pa-0"
          lg="5"
          sm="6"
          cols="6">
          <div v-if="showSteps" class="mx-auto wizard-step wizard-step-max">
            <standard-wizard-step />
          </div>
        </v-col>

        <v-col
          lg="3"
          sm="3"
          cols="3"
          class="merchant-oboarding-wizard-header__header-button">
          <div v-if="isReEntryEnabled && showSteps && showSaveForLaterButton">
            <v-menu v-if="$vuetify.display.xs" location="right">
              <template v-slot:activator="{ props }">
                <custom-button
                  variant="icon"
                  size="small"
                  v-bind="props">
                  <v-icon color="var(--grayscale-color-1)">
                    mdi-dots-vertical
                  </v-icon>
                </custom-button>
              </template>
              <v-list>
                <v-list-item class="menu-item" @click="showCompleteLater = true">
                  <v-list-item-title>
                    <span>Save For Later</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <custom-button
              v-else
              variant="secondary"
              size="small"
              @click="showCompleteLater = true">
              Save For Later
            </custom-button>
          </div>
        </v-col>
      </v-row>
      <v-row v-else-if="showSteps">
        <standard-wizard-step />
      </v-row>
    </v-container>

    <complete-this-later-dialog
      :dialog="showCompleteLater"
      :on-cancel="hideCompleteLater"
      :on-cta="completeLater"
      :disable-button="disableCompleteLaterButton"
      @closeModal="hideCompleteLater" />
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import StandardWizardStep from '@/components/WizardStep.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetSponsorMixin from '@/mixins/GetSponsor';
import CompleteThisLaterDialog from '@/components/Dialogs/CompleteThisLaterDialog.vue';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import { completeLaterMob } from '@/api/reentry';
import { broadcast } from '@/composables/eventBus';
import ApplicationStatusEnum from '@/enums/Merchant/ApplicationStatusEnum';

export default defineComponent({
  name: 'WizardHeader',

  components: { StandardWizardStep, CustomButton, CompleteThisLaterDialog },

  mixins: [GetSponsorMixin, FeatureFlagsMixin],

  props: {
    showLogo: { type: Boolean, default: true },
  },

  data: () => ({
    showCompleteLater: false,
    hideWizardStep: false,
    disableCompleteLaterButton: false,
  }),

  computed: {
    ...mapGetters('Ui', {
      logoUrl: 'getBrandingHeaderLogo',
    }),

    wizardStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardStep'];
    },

    showSteps(): boolean {
      if (!this.currentAppStatus || this.currentAppStatus === ApplicationStatusEnum.PENDING) {
        return false;
      }

      return !this.hideWizardStep && this.$store.getters['MerchantOnboarding/getShowMobStepper'];
    },

    currentAppStatus(): ApplicationStatusEnum {
      return this.$store.getters['MerchantOnboarding/getApplicationStatus'];
    },

    showSaveForLaterButton(): boolean {
      return this.$store.getters['MerchantOnboarding/isApplicationInProgress'];
    },

    isReEntryEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.RE_ENTRY);
    },
  },

  methods: {
    async completeLater() {
      this.disableCompleteLaterButton = true;

      const response = await completeLaterMob(this.getUserVerifyData?.merchantApplicationId);

      if (response.status === 200) {
        this.hideWizardStep = true;
        this.showCompleteLater = false;
        this.$emit('reEntrySent');
        broadcast('reEntrySent');
      }

      this.disableCompleteLaterButton = false;
    },

    hideCompleteLater() {
      this.showCompleteLater = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/media_queries";
@import "@/assets/scss/vuetify/elevation.scss";

.merchant-oboarding-wizard-header {
  display: flex;
  align-items: center;
  height: 3.5rem;
  background-color: #FFFFFF;

  &__logo-row {
    text-align: left;

    @include desktop-large {
      margin-right: -3.7rem;
    }
  }

  &__header-button {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &__logo-row,
  &__container {
    padding: 0;

    .wizard-step-max {
      max-width: 27.5rem;
    }
  }

  &__logo-container {
    display: inline-block;
    width: auto;
    height: 2rem;
    overflow: hidden;

    @include mobile {
      padding: 0 0.5rem;
      vertical-align: middle;
    }
  }

  &__logo {
    position: relative;
    padding: 0;
    height: 100%;
    width: auto;

    @include mobile {
      padding: 0 0.5rem;
      vertical-align: middle;
      width: 100%;
    }
  }

  &__steps {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
  }

  :deep() {
    .v-stepper {
      box-shadow: none;

      .v-stepper-header {
        @include mobile {
          padding: 0 1rem;
        }
      }

      .v-stepper-item {
        opacity: 1;
        padding: 0;
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;

        @include mobile {
          padding: 0.5rem 0 1rem 0;
        }

        &--selected .v-stepper-item__avatar {
          background-color: var(--secondary-color) !important;
        }
      }

      .v-stepper-item__avatar {
        width: 2rem !important;
        height: 2rem !important;
        margin-right: 0;
        font-size: 1rem;
        font-weight: 900;
        color: white;
        background-color: var(--grayscale-color-2);

        @include mobile {
          width: 1.5rem !important;
          height: 1.5rem !important;
          margin-right: 0;
          font-size: 0.75rem;
          font-weight: 900;
        }
      }
    }
  }
}

:deep(.menu-item) {
  cursor: pointer;
  color: var(--primary-color) !important;
  font-weight: 700;
  font-size: 0.875rem !important;
}
</style>
