<template>
  <custom-expansion-panel
    :expanded="!isMobile"
    :can-collapse="!isMobile"
    class="consumer-activity">
    <template #title>
      <div>
        <div class="consumer-activity__header fs-unmask">
          <h5 class="ma-0 text-left" :class="{ 'px-4': !isMobile }">
            Consumer Activity
          </h5>
        </div>
      </div>
    </template>

    <template #text>
      <div class="consumer-activity__header fs-unmask pl-3 pr-2">
        <tab-navigation v-if="!isMobile" v-model="currentTab">
          <tab-navigation-btn
            v-for="tab in tabs"
            :key="tab.value"
            :value="tab.value">
            {{ tab.title }}
          </tab-navigation-btn>
        </tab-navigation>

        <div v-else class="consumer-activity-controls">
          <div>
            <v-select
              v-model="currentTab"
              class="activity-select old-v-select"
              :menu-props="{ offset: [-40, 0] }"
              hide-details
              :items="tabs" />
          </div>
          <template v-if="currentTab === 'recentApplications'">
            <v-btn
              icon
              variant="plain"
              size="small"
              class="bg-white full-opacity ml-6"
              @click="showFilters = true">
              <v-icon color="var(--primary-color)">
                mdi-filter-variant
              </v-icon>
            </v-btn>

            <v-dialog v-model="showFilters" content-class="app-status-filter-dialog">
              <status-filter-body @close="showFilters = false" />
            </v-dialog>
          </template>
        </div>

        <div class="align-center d-flex">
          <div class="search-wrapper">
            <text-input
              v-model="search"
              append-inner-icon="mdi-magnify"
              dense
              hide-details
              in-place
              placeholder="Sent To, ID Number" />
          </div>

          <v-icon
            v-if="!isMobile"
            tag="button"
            :class="{ 'mdi-spin': loading }"
            :disabled="loading"
            @click="refresh">
            mdi-refresh
          </v-icon>
        </div>
      </div>

      <div class="consumer-activity__content" :class="{ 'is-mobile': isMobile }">
        <tab-navigation-content :value="currentTab">
          <tab-navigation-item tab="recentApplications">
            <recent-applications-table ref="recentApplicationsTable" />
          </tab-navigation-item>

          <tab-navigation-item tab="opportunities">
            <opportunities-table ref="opportunitiesTable" />
          </tab-navigation-item>
        </tab-navigation-content>
      </div>
    </template>
  </custom-expansion-panel>
</template>

<script lang="ts">
import {
  defineComponent, ref, watch, onBeforeMount,
} from 'vue';
import { useConsumerActivity } from '@/composables/useConsumerActivity';
import { useDisplay } from 'vuetify';
import { useDisplayStatus } from '@/composables/useConsumerApplicationDisplayStatus';
import CustomExpansionPanel from '@/components/CustomExpansionPanel.vue';
import TabNavigation from '@/components/Tabs/TabNavigation.vue';
import TabNavigationBtn from '@/components/Tabs/TabNavigationBtn.vue';
import TabNavigationContent from '@/components/Tabs/TabNavigationContent.vue';
import TabNavigationItem from '@/components/Tabs/TabNavigationItem.vue';
import TextInput from '@/components/Inputs/Text.vue';
import OpportunitiesTable from './OpportunitiesTable.vue';
import RecentApplicationsTable from './RecentApplicationsTable.vue';
import StatusFilterBody from './StatusFilterBody.vue';

export default defineComponent({
  name: 'ConsumerActivity',

  components: {
    CustomExpansionPanel,
    TabNavigation,
    TabNavigationBtn,
    TabNavigationContent,
    TabNavigationItem,
    TextInput,
    OpportunitiesTable,
    RecentApplicationsTable,
    StatusFilterBody,
  },

  setup() {
    const { loading, search, filters } = useConsumerActivity();
    const { xs } = useDisplay();
    const isMobile = xs;

    const refs = {
      recentApplications: ref<InstanceType<typeof RecentApplicationsTable> | null>(null),
      opportunities: ref<InstanceType<typeof OpportunitiesTable> | null>(null),
    };

    const showFilters = ref(false);
    const tabs = [
      { title: 'Recent Applications', value: 'recentApplications' },
      { title: 'Opportunities', value: 'opportunities' },
    ];

    const currentTab = ref<'recentApplications' | 'opportunities'>('recentApplications');

    const refresh = () => refs[currentTab.value]?.value!.refresh();

    watch(currentTab, () => {
      search.value = '';
      filters.display_status = [];
    });

    const { fetchStatuses } = useDisplayStatus();
    onBeforeMount(() => fetchStatuses());

    return {
      loading,
      tabs,
      showFilters,
      currentTab,
      search,
      refresh,
      recentApplicationsTable: refs.recentApplications,
      opportunitiesTable: refs.opportunities,
      isMobile,
    };
  },
});
</script>

<style lang="scss" scoped>
.consumer-activity {
  border: 1px solid var(--grayscale-color-2);
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
  overflow: hidden;

  &__header {
    background-color: var(--grayscale-color-5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3rem;
  }

  &-controls {
    display: flex;
    align-items: center;
    width: 100%;

    > div:first-child {
      width: 50%;
    }
  }
}

@media screen and (min-width: 600px) {
  .consumer-activity {
    &__header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 3rem;
    }

    &__content {
      height: 22rem;
    }
  }
}

h5 {
  font-size: 1rem !important;
  font-weight: 800;
}

.search-wrapper {
  width: 100%;

  :deep(.v-input) {
    font-size: 0.875rem !important;

    input::placeholder {
      color: var(--grayscale-color-1);
      opacity: 1;
    }
  }

  @media screen and (min-width: 600px) {
    width: 12rem;
    margin-right: 1rem;
  }
}

.activity-select {
  margin: 0 !important;

  :deep(.v-field) {
    border-radius: 1.25rem;
    height: 2rem;
    padding: 0 0.5rem;
    background-color: white;
    border: 2px solid var(--grayscale-color-1);
    color: var(--grayscale-color-1);
    overflow: hidden;
    font-size: 0.875rem;
    font-weight: 700;

    .v-field__overlay,
    .v-field__outline {
      display: none;
    }

    .v-field__input {
      padding: 0;
      min-height: 1.8rem;
    }

    i {
      font-size: 2rem;
      color: var(--grayscale-color-1);
    }

    .v-field__field {
      overflow: none;
    }
  }
}

:deep() {
  .v-expansion-panel-title {
    border: none !important;
  }

  .v-expansion-panel--active > .v-expansion-panel-header {
    border: none;
    min-height: 3rem !important;
  }

  .v-dialog {
    width: auto;
  }

  .v-table > .v-table__wrapper > table > thead > tr > th,
  .v-table > .v-table__wrapper > table > tbody > tr > td {
    height: 3rem;
  }

  .v-table .v-data-table-footer {
    height: 3rem;

    button {
      height: 1.5rem;
    }
  }
}

.v-theme--light .is-mobile :deep() {
  .tab-navigation-content {
    padding: 0 0.5rem;
  }

  .v-table,
  .v-table .v-table__wrapper {
    border-radius: 0;

    table {
      border-collapse: separate;
      border-spacing: 0 1rem;

      tr {
        background-color: var(--grayscale-color-3) !important;

        &:hover {
          background-color: var(--grayscale-color-3) !important;
        }

        td {
          background-color: white;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
          }

          &:last-child {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
