<template>
  <div class="standard-wizard__step standard-wizard__step--two" data-test="MOB-step2">
    <div v-if="!isTypeIC1">
      <v-form data-test="userPasswordForm" @submit.prevent="createUserPassword">
        <v-card>
          <v-card-title>
            <h3 class="my-0 step-header" data-test="passwordHeader">
              Password
            </h3>
          </v-card-title>

          <v-card-text>
            <div class="card-section">
              <div class="small-title" data-test="passwordInstructionText">
                Create a password for the merchant portal.
              </div>

              <password-input
                v-model="stepData.password"
                autocomplete="new-password"
                data-test="passwordInput"
                :disabled="passwordCreated"
                :error-messages="errors.password"
                :password-verify="stepData.passwordCheck"
                :show-password-while-typing="true"
                required
                show-requirements-tooltip
                @blur="resetErrorState" />

              <password-input
                v-model="stepData.passwordCheck"
                autocomplete="new-password"
                data-test="passwordCheckInput"
                :disabled="passwordCreated"
                :error-messages="errors.password_check"
                required
                label="Verify Password"
                :password-verify="stepData.password"
                :show-password-while-typing="true"
                @blur="resetErrorState" />
            </div>

            <custom-button
              :disabled="nextStepDisabled"
              data-test="nextBtn"
              full-width
              type="submit">
              Next
            </custom-button>
          </v-card-text>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetErrors from '@/mixins/GetErrors';
import isValidPassword from '@/validators/password';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';
import OtpMixin from '@/mixins/OtpMixin';
import PasswordInput from '@/components/Inputs/Password.vue';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import GetSponsorMixin from '@/mixins/GetSponsor';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepTwo',

  components: {
    CustomButton,
    PasswordInput,
  },

  mixins: [
    GetErrors,
    OtpMixin,
    NavigatesStepsMixin,
    GetSponsorMixin,
    HoneypotTrackMixin,
  ],

  data: () => ({
    stepData: {
      password: '',
      passwordCheck: '',
    },
    passwordCreated: false,
    ownStep: 2,
    disableNextButton: false,
    hpEventName: 'Password Page Visit',
  }),

  computed: {
    userVerifyData(): OnboardingUserData {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'];
    },

    userId(): number {
      return this.userVerifyData?.userId;
    },

    nextStepDisabled(): boolean {
      const { password, passwordCheck } = this.stepData;
      if (!isValidPassword(password)
      || password !== passwordCheck
      || this.disableNextButton) return true;
      return this.passwordCreated;
    },
  },

  methods: {
    async createUserPassword(): Promise<void> {
      this.disableNextButton = true;

      if (this.passwordCreated) {
        this.goToNextStep();
        return;
      }

      const createPasswordResponseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchCreatePassword',
        {
          password: this.stepData.password,
          passwordCheck: this.stepData.passwordCheck,
          userId: this.userId,
        },
      );

      if (createPasswordResponseStatus === 200) {
        this.passwordCreated = true;
        this.trackEvent(this.hpEventName, PageTypesShorthand.MOB);
        this.goToNextStep();
      }

      this.disableNextButton = false;
    },

    resetErrorState() {
      this.$store.dispatch('Ui/resetErrorState');
    },

    goToNextStep(): void {
      this.goToStep(this.ownStep + 1);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

:deep(.v-card) {
  box-shadow: $v-elevation-4;
  border: 1px solid var(--grayscale-color-2);
  border-radius: 8px;
  .v-card-title {
    padding: 1rem;
    background-color: var(--grayscale-color-5);
    margin-bottom: 1rem;
  }
  .v-card-text .card-section {
    margin-bottom:2rem;
    border-radius: 8px;
    .small-title {
      margin-bottom: 2rem;
      font-size: 1rem;
      text-align: left;
      color: var(--grayscale-color-1);
    }
  }
  .step-header {
    font-size: 1.125rem;
  }
}

.standard-wizard__step {
  background-color: #fff;
}
</style>
