<template>
  <v-text-field
    ref="input"
    v-mask="inputMask"
    v-fs-exclude
    v-bind="$attrs"
    autocomplete="social-security-number"
    :append-inner-icon="appendIcon"
    :data-test="dataTestAttr"
    :disabled="disabled"
    :label="labelAttr"
    :variant="variantAttr"
    :rules="[isValidSsn]"
    type="text"
    validate-on="blur"
    @click.right.prevent
    @copy.prevent
    @click:appendInner="showSsn = !showSsn"
    @focus="onInputFocus" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Inputmask from 'inputmask';
import validateSsn from '@/validators/ssn';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';

export default defineComponent({
  name: 'SsnInput',

  mixins: [InputFieldMixin],

  props: {
    disabled: { type: Boolean, default: false },
  },

  data: () => ({
    defaultLabelAttr: 'SSN',
    defaultDataTestAttr: 'ssnInput',
    showSsn: false,
  }),

  computed: {
    appendIcon() {
      if (this.disabled) return '';
      return this.showSsn ? 'mdi-eye-off' : 'mdi-eye';
    },
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },

    inputMask(): any {
      const mask = {
        showMaskOnHover: false,
        mask: '999-99-9999',
        jitMasking: false,
        autoUnmask: true,
        inputmode: 'numeric',
        onBeforePaste: (value: any) => {
          return this.beforePaste(value);
        },
      };

      if (this.showSsn) {
        return mask;
      }

      return {
        ...mask,
        onBeforeWrite(_: any, buffer: string[]) {
        /**
         * This is called on keyboard inputs, blur (not focus) and some custom inputmask event
         * It's removed if showSsn == true
         * replaces numbers with a dot if element is not focused
         */
          if (this.$el[0] !== document.activeElement) {
            buffer.forEach((char, index) => {
              if (!Number.isNaN(Number(char))) {
                buffer[index] = '\u25CF';
              }
            });
          }
        },
      };
    },
  },

  watch: {
    showSsn() {
      this.resetInputMask();

      /**
       * Another hack to make the inputmask work properly when toggling show ssn
       */
      this.inputElement!.focus();
      this.inputElement!.blur();

      if (this.inputElement?.readOnly) {
        this.inputElement.inputmask!.setValue(this.$attrs.modelValue as string);
      }
    },
  },

  mounted() {
    if (this.$attrs.modelValue && this.inputElement) {
      this.inputElement.inputmask!.setValue(this.$attrs.modelValue as string);
    }
  },

  methods: {
    resetInputMask() {
      const el = this.inputElement!;
      el.inputmask!.remove();
      Inputmask(this.inputMask).mask(el);
    },

    isValidSsn(ssn: string | undefined): string | boolean {
      if (!ssn) return this.isRequired();

      if (!validateSsn(ssn)) return 'Please correct social security number format';

      const verify = this.verifyInput(ssn);

      if (verify) return verify;

      return true;
    },

    onInputFocus() {
      /**
       * hack to force the inputmask to re-run masking
       * and call onBeforeWrite() by updating an option
       */
      this.inputElement!.inputmask!.option({ autoUnmask: true });
    },
  },
});
</script>
