import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_mask = _resolveDirective("mask")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ ref: "input" }, _ctx.$attrs, {
    autocomplete: "off",
    "data-test": _ctx.dataTestAttr,
    disabled: _ctx.disabled,
    label: _ctx.labelAttr,
    variant: _ctx.variantAttr,
    "prepend-inner-icon": _ctx.showIcon ? 'mdi-calendar' : '',
    rules: _ctx.rules || [_ctx.dateValidation],
    "validate-on": "blur",
    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["right","prevent"])),
    onCopy: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
  }), null, 16, ["data-test", "disabled", "label", "variant", "prepend-inner-icon", "rules"])), [
    [_directive_mask, _ctx.inputMask]
  ])
}