<template>
  <div class="global-alerts">
    <v-slide-y-transition group>
      <alert
        v-for="alert in alerts"
        :id="alert.id"
        :key="alert.id"
        :type="alert.type"
        :text="alert.text"
        :timed="alert.timed"
        :dismissible="alert.dismissible"
        :html-text="alert.htmlText"
        @close="remove" />
    </v-slide-y-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import Alert from './Alert.vue';

export default defineComponent({
  name: 'GlobalAlerts',

  components: {
    Alert,
  },

  computed: {
    alerts(): Array<GlobalAlertIface> {
      return this.$store.getters['Ui/getGlobalAlerts'];
    },
  },

  methods: {
    remove(id: number) {
      this.$store.dispatch('Ui/removeGlobalAlert', id);
    },
  },

});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/media_queries";

.global-alerts {
  position: fixed;
  left: 0;
  right: 0;
  top: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 28rem;
  z-index: 9999;

  @include media-breakpoint-only-xs {
    width: 100%;
  }
}
</style>
