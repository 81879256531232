<template>
  <div class="password-input-container">
    <v-text-field
      ref="input"
      v-fs-exclude
      v-bind="$attrs"
      :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      :data-test="dataTestAttr"
      :label="labelAttr"
      variant="outlined"
      :prepend-inner-icon="showIcon ? 'mdi-lock' : ''"
      :rules="[passwordValidation]"
      :type="showPassword ? 'text' : 'password'"
      validate-on="blur"
      :model-value="modelValue"
      @blur="onBlur"
      @click:appendInner="showPassword = !showPassword"
      @focus="onFocus" />

    <v-expand-transition v-if="showRequirementsTooltip && isFocused">
      <div v-show="isFocused" class="password-rules">
        <p class="password-rules__title">
          We suggest using a short sentence or phrase, and avoiding commonly used passwords.
        </p>
        <ul class="password-rules__list">
          <li
            v-for="{ label, isValid } in passwordRequirements"
            :key="label"
            :class="isValid ? 'greyed-out' : ''">
            <span class="password-rule__text">{{ label }}</span>
          </li>
        </ul>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import containsLowercaseCharacters from '@/validators/containsLowercaseCharacters';
import containsNumbers from '@/validators/containsNumbers';
import containsUppercaseCharacters from '@/validators/containsUppercaseCharacters';
import minEightCharacters from '@/validators/min_8_characters';
import isValidPassword from '@/validators/password';

export default defineComponent({
  name: 'PasswordInput',

  mixins: [InputFieldMixin],

  props: {
    modelValue: { type: String, default: '' },
    showIcon: { type: Boolean, default: true },
    showRequirementsTooltip: { type: Boolean, default: false },
    tooltipPosition: { type: String, default: 'top' },
    passwordVerify: { type: String, default: '' },
    disableInputValidation: { type: Boolean, default: false },
    showPasswordWhileTyping: { type: Boolean, default: false },
  },

  data: () => ({
    defaultDataTestAttr: 'passwordInput',
    defaultLabelAttr: 'Password',
    showPassword: false,
    isFocused: true,
  }),

  computed: {
    tooltipClass(): string {
      return `password-tooltip--${this.tooltipPosition}`;
    },

    passwordRequirements(): Array<{ label: string, isValid: boolean }> {
      return [
        { label: 'Minimum 8 Characters', isValid: this.passwordHasMinEightCharacters },
        { label: 'At Least One Lowercase Letter', isValid: this.passwordHasLowerCaseCharacters },
        { label: 'At Least One Uppercase Letter', isValid: this.passwordHasUppercaseCharacters },
        { label: 'At Least One Number', isValid: this.passwordHasNumbers },
      ];
    },

    passwordHasLowerCaseCharacters(): boolean {
      return containsLowercaseCharacters(this.modelValue);
    },

    passwordHasNumbers(): boolean {
      return containsNumbers(this.modelValue);
    },

    passwordHasUppercaseCharacters(): boolean {
      return containsUppercaseCharacters(this.modelValue);
    },

    passwordHasMinEightCharacters(): boolean {
      return minEightCharacters(this.modelValue);
    },
  },

  watch: {
    passwordVerify() {
      if (this.modelValue) (this.$refs.input as any).validate();
    },
  },

  mounted() {
    if (this.showPasswordWhileTyping) {
      this.showPassword = true;
    }
  },

  methods: {
    passwordValidation(value: string | undefined): string | boolean {
      if (this.disableInputValidation) return true;

      if (!value) return this.isRequired();

      if (!isValidPassword(value)) return 'Please correct password format';

      const { passwordVerify } = this;

      if (passwordVerify && passwordVerify !== value) return 'Passwords do not match';

      return true;
    },

    onBlur(e: any) {
      this.isFocused = false;

      if (this.showPasswordWhileTyping) {
        this.showPassword = false;
      }

      this.$emit('blur', e);
    },

    onFocus() {
      this.isFocused = true;

      if (this.showPasswordWhileTyping) {
        this.showPassword = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.password-input-container {
  position: relative;
}

.greyed-out {
  color: var(--grayscale-color-2);
}

.password-rules {
  text-align: left;

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: disc;
    padding: 0 0 0 1rem;
    margin-bottom: 2rem;
  }

  li {
    flex-basis: calc(50% - 1rem);
  }
}
</style>
