import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_form = _resolveComponent("user-form")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: "",
    "on-cta": _ctx.saveUser,
    "cta-disabled": _ctx.buttonDisabled,
    "on-cancel": _ctx.closeModal,
    "button-label": "Save"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_user_form, {
        user: _ctx.user,
        "form-data": _ctx.formData,
        user_type: _ctx.user_type,
        "email-error": _ctx.emailError,
        onUpdateFormData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.formData = $event)),
        onUpdateUserType: _ctx.updateUserStatus,
        onEmailUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.disableSaveButton = false))
      }, null, 8, ["user", "form-data", "user_type", "email-error", "onUpdateUserType"])
    ]),
    _: 1
  }, 8, ["on-cta", "cta-disabled", "on-cancel"]))
}