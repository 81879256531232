<template>
  <div>
    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :items="users"
      :loading="loading"
      :show-select="false"
      :single-select="false"
      :mobile="isMobile"
      class="standard-data-table"
      data-test="userTable"
      hide-default-footer
      item-key="uid">
      <template v-slot:item="{ item }">
        <tr
          class="d-sm-none v-data-table__tr--mobile"
          :class="{ reinvitable: item.can_be_reinvited }">
          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[0].title }}
            </div>
            <div class="v-data-table__td-value">
              {{ item.user.first_name }}
            </div>
          </td>

          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[1].title }}
            </div>
            <div v-private class="v-data-table__td-value">
              {{ item.user.last_name }}
            </div>
          </td>

          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[2].title }}
            </div>
            <div class="v-data-table__td-value">
              {{ getUserRoleText(item) }}
            </div>
          </td>

          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[3].title }}
            </div>
            <div v-private class="v-data-table__td-value">
              {{ item.user.email }}
            </div>
          </td>

          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[4].title }}
            </div>
            <div
              v-private
              v-fs-exclude
              class="v-data-table__td-value">
              {{ getUserPhoneNumberLabel(item) }}
            </div>
          </td>

          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[5].title }}
            </div>
            <div class="v-data-table__td-value">
              {{ item.user.id }}
            </div>
          </td>

          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[6].title }}
            </div>
            <div class="v-data-table__td-value">
              {{ getUserStatus(item) }}
            </div>
          </td>
          <td class="v-data-table__td">
            <div class="v-data-table__td-title">
              {{ headers[7].title }}
            </div>
            <div class="v-data-table__td-value">
              <v-row class="standard-data-table__actions" no-gutters>
                <v-menu
                  attach
                  location="top"
                  offset="-40">
                  <template v-slot:activator="{ props }">
                    <custom-button
                      v-if="!hideActionsMenuButton(item)"
                      class="standard-data-table__actions-dots"
                      :data-test="`uma${item.user.id}`"
                      variant="icon"
                      size="small"
                      aria-label="Actions"
                      :ripple="false"
                      :elevation="0"
                      v-bind="props">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </custom-button>
                  </template>

                  <v-list class="text-left">
                    <v-list-item
                      v-if="!hideEditButton(item)"
                      class="action-buttons"
                      :disabled="processing"
                      @click="userEdit = item">
                      <custom-button
                        :disabled="processing"
                        class="custom-button"
                        label="Edit"
                        variant="text"
                        :ripple="false" />
                    </v-list-item>

                    <v-list-item
                      v-if="!hideActiveButton(item)"
                      class="action-buttons"
                      :disabled="processing"
                      @click="deactivateUser = item">
                      <custom-button
                        :disabled="processing"
                        class="custom-button"
                        :label="item.is_active ? 'Deactivate' : 'Reactivate'"
                        variant="text"
                        :ripple="false" />
                    </v-list-item>

                    <v-list-item v-if="!hideReInviteButton(item)" class="action-buttons">
                      <custom-button
                        class="custom-button"
                        :disabled="processing || sendingReinvite"
                        label="Reinvite"
                        variant="text"
                        @click="sendReinvite(item.user.email)" />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </div>
          </td>
        </tr>

        <tr class="d-none d-sm-table-row" :class="{ reinvitable: item.can_be_reinvited }">
          <td v-private>
            {{ item.user.first_name }}
          </td>
          <td v-private>
            {{ item.user.last_name }}
          </td>
          <td>
            {{ getUserRoleText(item) }}
          </td>
          <td v-private>
            {{ item.user.email }}
          </td>
          <td
            v-private
            v-fs-exclude
            class="text-end">
            {{ getUserPhoneNumberLabel(item) }}
          </td>
          <td>{{ item.user.id }}</td>
          <td>{{ getUserStatus(item) }}</td>
          <td>
            <v-row class="standard-data-table__actions" no-gutters>
              <v-menu offset="-20" location="start">
                <template v-slot:activator="{ props }">
                  <custom-button
                    v-if="!hideActionsMenuButton(item)"
                    class="standard-data-table__actions-dots"
                    :data-test="`uma${item.user.id}`"
                    variant="icon"
                    size="small"
                    aria-label="Actions"
                    :ripple="false"
                    v-bind="props">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </custom-button>
                </template>

                <v-list class="text-left">
                  <v-list-item
                    v-if="!hideEditButton(item)"
                    class="action-buttons"
                    :disabled="processing"
                    @click="userEdit = item">
                    <custom-button
                      :disabled="processing"
                      class="custom-button"
                      label="Edit"
                      variant="text"
                      :ripple="false" />
                  </v-list-item>

                  <v-list-item
                    v-if="!hideActiveButton(item)"
                    class="action-buttons"
                    :disabled="processing"
                    @click="deactivateUser = item">
                    <custom-button
                      :disabled="processing"
                      class="custom-button"
                      :label="item.is_active ? 'Deactivate' : 'Reactivate'"
                      variant="text"
                      :ripple="false" />
                  </v-list-item>

                  <v-list-item v-if="!hideReInviteButton(item)" class="action-buttons">
                    <custom-button
                      class="custom-button"
                      :disabled="processing || sendingReinvite"
                      label="Resend Invite"
                      variant="text"
                      @click="sendReinvite(item.user.email)" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>

    <edit-user
      v-if="userEdit"
      :user="userEdit"
      @save="refreshUsers"
      @close="userEdit = null"
      @cancel="userEdit = null" />

    <deactivate-user
      v-if="deactivateUser"
      :user="deactivateUser"
      @save="refreshUsers"
      @close="deactivateUser = null"
      @cancel="deactivateUser = null" />

    <re-invite-user-dialog
      v-if="reinvitedEmail"
      :email="reinvitedEmail"
      :show-alert="showReinviteAlert"
      @close="reinvitedEmail = ''"
      @close:alert="showReinviteAlert = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import DeactivateUser from '@/components/Merchant/Portal/Admin/UserManagement/DeactivateUser.vue';
import { MerchantUserInterface } from '@/interfaces/states/MerchantUserInterface';
import EditUser from '@/components/Merchant/Portal/Admin/UserManagement/EditUser.vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';
import ReInviteUserDialog from '@/components/Merchant/Portal/Admin/UserManagement/ReInviteUserDialog.vue';
import ReInviteMixin from '@/components/Merchant/Portal/Admin/UserManagement/ReInviteMixin';

export default defineComponent({
  name: 'UsersTable',
  components: {
    CustomButton,
    DeactivateUser,
    EditUser,
    ReInviteUserDialog,
  },
  mixins: [MerchantPermissionsMixin, ReInviteMixin],
  data() {
    return {
      headers: [
        {
          title: 'First Name',
          align: 'start',
          sortable: false,
          value: 'first_name',
        }, {
          title: 'Last Name',
          align: 'start',
          sortable: false,
          value: 'last_name',
        }, {
          title: 'User Role',
          align: 'start',
          sortable: false,
          value: 'user_type',
        }, {
          title: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
        }, {
          title: 'Phone',
          align: 'start',
          sortable: false,
          value: 'phone',
        }, {
          title: 'User ID',
          align: 'start',
          sortable: false,
          value: 'uid',
        }, {
          title: 'Status',
          sortable: false,
          align: 'left',
          value: 'is_active',
        }, {
          title: 'Actions',
          sortable: false,
          align: 'center',
          value: 'actions',
        },
      ],
      user: {},
      userEdit: null,
      deactivateUser: null,
      loading: false,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
    processing(): boolean {
      return this.$store.getters['Ui/getProcessing'];
    },
    loggedInUserId(): number {
      return this.$store.getters['Auth/getUserId'];
    },
    loggedInUserIsSalesPerson(): boolean {
      return !this.isAdminOrAbove && !this.isSalesManager && !this.isSalesRep;
    },
    users(): Array<MerchantUserInterface> {
      return this.$store.getters['MerchantPortal/getMerchantUsers'];
    },
  },
  async created() {
    await this.refreshUsers();
  },
  methods: {
    menuNudgeComp(item: MerchantUserInterface) {
      if (item.is_active && !this.isMobile) return '112';
      if (!item.is_active && this.isMobile) return '32';
      return '80';
    },
    async refreshUsers(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('MerchantPortal/dispatchFetchMerchantUsers');
      this.loading = false;
    },
    checkRole(user: MerchantUserInterface): boolean {
      return (this.isAdminOrAbove && user.is_merchant_owner)
      || (this.isSalesManager && user.is_admin)
      || (this.isSalesManager && user.is_salesmanager);
    },
    hideActionsMenuButton(user: MerchantUserInterface): boolean {
      return this.isSalesManager && (user.is_admin || user.is_salesmanager);
    },
    hideEditButton(user: MerchantUserInterface): boolean {
      return this.loggedInUserIsSalesPerson
      || !user.is_active
      || (user.is_merchant_owner && user.user.id === this.loggedInUserId)
      || this.checkRole(user);
    },
    hideActiveButton(user: MerchantUserInterface): boolean {
      return this.loggedInUserIsSalesPerson
      || user.can_be_reinvited
      || (user.user.id === this.loggedInUserId)
      || this.checkRole(user);
    },
    hideReInviteButton(user: MerchantUserInterface): boolean {
      return !user.can_be_reinvited || (user.user.id === this.loggedInUserId);
    },
    getUserPhoneNumberLabel(user: MerchantUserInterface): string {
      return user.user.phonenumber_set[0]?.phone_number;
    },
    getUserStatus(user: MerchantUserInterface): string {
      if (user.can_be_reinvited) return 'Invite Sent';
      return user.is_active ? 'Active' : 'Deactivated';
    },
    getUserRoleText(user: MerchantUserInterface): string {
      if (user.is_admin) return 'Admin';
      if (user.is_salesmanager) return 'Manager';
      if (user.is_salesrep) return 'Representative';
      return 'Salesperson';
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import '@/assets/scss/mixins/media_queries';
@import "@/assets/scss/table-default";
@import "@/assets/scss/mixins/mixins";

.custom-button {
  @include user-management-custom-button;
}

.standard-data-table {
  &__is-owner {
    font-size: 1.5rem;
    color: var(--grayscale-color-2);
  }

  &__actions {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    max-width: 15rem;

    @include up-to-large-desktop {
      max-width: fit-content;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  &__actions-dots {
    min-width: auto !important;
    color: var(--grayscale-color-1) !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .reinvitable {
    background-color: var(--grayscale-color-4) !important;
  }
}

.v-overlay__content {
  .v-list {
    width: 9.875rem !important;
  }
}

.action-buttons {
  font-size: 1rem !important;
  padding-left: 1.125rem;
}

@media screen and (max-width: 599px) {
  :deep() {
    .v-table {
      &__wrapper table tbody tr {
        padding: 0 !important;
      }
    }
  }
}

:deep() {
  .v-table-header tr th span {
    color: var(--grayscale-color-1);
  }

  .v-table__wrapper tbody tr td {
    color: var(--grayscale-color-1);
  }

  .v-table__mobile-table-row .v-table__mobile-row .v-table__mobile-row__cell {
    color: var(--grayscale-color-1);
  }
}
</style>
