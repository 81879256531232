<template>
  <standard-dialog
    :dialog="show"
    :cta-disabled="ctaDisabled"
    :on-cancel="clearData"
    :show-cancel-cta="false"
    :on-cta="!passwordReset ? resetUserPassword : clearData"
    :show-progress-indicator="processing"
    :show-title="false"
    :button-label="passwordReset ? 'OK' : 'Save'">
    <template #body>
      <div>
        <v-row v-if="!passwordReset">
          <v-col>
            <password-input
              v-model="oldPassword"
              :errors="errors.old_password"
              :show-password-while-typing="true"
              autocomplete="current-password"
              label="Old Password" />

            <password-input
              v-model="newPassword"
              autocomplete="new-password"
              :show-password-while-typing="true"
              show-requirements-tooltip
              label="New Password" />

            <password-input
              v-model="passwordCheck"
              autocomplete="new-password"
              :show-password-while-typing="true"
              label="Verify Password" />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            Your new password has been saved.
          </v-col>
        </v-row>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { resetPassword } from '@/api/account';
import PasswordInput from '@/components/Inputs/Password.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { ResetUserPasswordData } from '@/interfaces/merchantPortal/ResetUserPasswordData';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import ValidationTypesMixin from '@/mixins/ValidationTypesMixin';
import password from '@/validators/password';

export default defineComponent({
  name: 'MobileModal',
  components: {
    PasswordInput,
    StandardDialog,
  },
  mixins: [
    GetErrors,
    GetProcessing,
    ValidationTypesMixin,
  ],
  props: {
    show: { type: Boolean, default: false },
    onClose: { type: Function, default: () => null },
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      passwordCheck: '',
      passwordReset: false,
    };
  },
  computed: {
    ctaDisabled(): boolean {
      const { oldPassword, newPassword, passwordCheck } = this;
      if (!password(oldPassword) || !password(newPassword)) return true;
      return !(oldPassword !== newPassword && newPassword === passwordCheck);
    },
  },
  methods: {
    async resetUserPassword() {
      const requestData: ResetUserPasswordData = {
        old_password: this.oldPassword,
        password: this.newPassword,
        password_check: this.passwordCheck,
      };
      const userId = this.$store.getters['Auth/getUserId'];
      if (!userId) return;

      try {
        await resetPassword(userId, requestData);
        this.passwordReset = true;
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
      }
    },
    clearData(): void {
      this.oldPassword = '';
      this.newPassword = '';
      this.passwordCheck = '';
      this.passwordReset = false;

      this.onClose();
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/components/dialogs";

</style>
