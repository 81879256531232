import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47a6d7be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-data-table__td" }
const _hoisted_2 = { class: "v-data-table__td-title" }
const _hoisted_3 = { class: "v-data-table__td-value" }
const _hoisted_4 = { class: "v-data-table__td" }
const _hoisted_5 = { class: "v-data-table__td-title" }
const _hoisted_6 = { class: "v-data-table__td-value" }
const _hoisted_7 = { class: "v-data-table__td" }
const _hoisted_8 = { class: "v-data-table__td-title" }
const _hoisted_9 = { class: "v-data-table__td-value" }
const _hoisted_10 = { class: "v-data-table__td" }
const _hoisted_11 = { class: "v-data-table__td-title" }
const _hoisted_12 = { class: "v-data-table__td-value" }
const _hoisted_13 = { class: "v-data-table__td" }
const _hoisted_14 = { class: "v-data-table__td-title" }
const _hoisted_15 = { class: "v-data-table__td-value" }
const _hoisted_16 = { class: "v-data-table__td" }
const _hoisted_17 = { class: "v-data-table__td-title" }
const _hoisted_18 = { class: "v-data-table__td-value" }
const _hoisted_19 = { class: "v-data-table__td" }
const _hoisted_20 = { class: "v-data-table__td-title" }
const _hoisted_21 = { class: "v-data-table__td-value" }
const _hoisted_22 = { class: "v-data-table__td" }
const _hoisted_23 = { class: "v-data-table__td-title" }
const _hoisted_24 = { class: "v-data-table__td-value" }
const _hoisted_25 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_edit_user = _resolveComponent("edit-user")!
  const _component_deactivate_user = _resolveComponent("deactivate-user")!
  const _component_re_invite_user_dialog = _resolveComponent("re-invite-user-dialog")!
  const _directive_private = _resolveDirective("private")!
  const _directive_fs_exclude = _resolveDirective("fs-exclude")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_data_table, {
      "disable-pagination": true,
      headers: _ctx.headers,
      items: _ctx.users,
      loading: _ctx.loading,
      "show-select": false,
      "single-select": false,
      mobile: _ctx.isMobile,
      class: "standard-data-table",
      "data-test": "userTable",
      "hide-default-footer": "",
      "item-key": "uid"
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("tr", {
          class: _normalizeClass(["d-sm-none v-data-table__tr--mobile", { reinvitable: item.can_be_reinvited }])
        }, [
          _createElementVNode("td", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headers[0].title), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.user.first_name), 1)
          ]),
          _createElementVNode("td", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.headers[1].title), 1),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createTextVNode(_toDisplayString(item.user.last_name), 1)
            ])), [
              [_directive_private]
            ])
          ]),
          _createElementVNode("td", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.headers[2].title), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getUserRoleText(item)), 1)
          ]),
          _createElementVNode("td", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.headers[3].title), 1),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createTextVNode(_toDisplayString(item.user.email), 1)
            ])), [
              [_directive_private]
            ])
          ]),
          _createElementVNode("td", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.headers[4].title), 1),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createTextVNode(_toDisplayString(_ctx.getUserPhoneNumberLabel(item)), 1)
            ])), [
              [_directive_private],
              [_directive_fs_exclude]
            ])
          ]),
          _createElementVNode("td", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.headers[5].title), 1),
            _createElementVNode("div", _hoisted_18, _toDisplayString(item.user.id), 1)
          ]),
          _createElementVNode("td", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.headers[6].title), 1),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getUserStatus(item)), 1)
          ]),
          _createElementVNode("td", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.headers[7].title), 1),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_v_row, {
                class: "standard-data-table__actions",
                "no-gutters": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, {
                    attach: "",
                    location: "top",
                    offset: "-40"
                  }, {
                    activator: _withCtx(({ props }) => [
                      (!_ctx.hideActionsMenuButton(item))
                        ? (_openBlock(), _createBlock(_component_custom_button, _mergeProps({
                            key: 0,
                            class: "standard-data-table__actions-dots",
                            "data-test": `uma${item.user.id}`,
                            variant: "icon",
                            size: "small",
                            "aria-label": "Actions",
                            ripple: false,
                            elevation: 0
                          }, props), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("mdi-dots-vertical")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["data-test"]))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list, { class: "text-left" }, {
                        default: _withCtx(() => [
                          (!_ctx.hideEditButton(item))
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 0,
                                class: "action-buttons",
                                disabled: _ctx.processing,
                                onClick: ($event: any) => (_ctx.userEdit = item)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_custom_button, {
                                    disabled: _ctx.processing,
                                    class: "custom-button",
                                    label: "Edit",
                                    variant: "text",
                                    ripple: false
                                  }, null, 8, ["disabled"])
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"]))
                            : _createCommentVNode("", true),
                          (!_ctx.hideActiveButton(item))
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 1,
                                class: "action-buttons",
                                disabled: _ctx.processing,
                                onClick: ($event: any) => (_ctx.deactivateUser = item)
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_custom_button, {
                                    disabled: _ctx.processing,
                                    class: "custom-button",
                                    label: item.is_active ? 'Deactivate' : 'Reactivate',
                                    variant: "text",
                                    ripple: false
                                  }, null, 8, ["disabled", "label"])
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"]))
                            : _createCommentVNode("", true),
                          (!_ctx.hideReInviteButton(item))
                            ? (_openBlock(), _createBlock(_component_v_list_item, {
                                key: 2,
                                class: "action-buttons"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_custom_button, {
                                    class: "custom-button",
                                    disabled: _ctx.processing || _ctx.sendingReinvite,
                                    label: "Reinvite",
                                    variant: "text",
                                    onClick: ($event: any) => (_ctx.sendReinvite(item.user.email))
                                  }, null, 8, ["disabled", "onClick"])
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ], 2),
        _createElementVNode("tr", {
          class: _normalizeClass(["d-none d-sm-table-row", { reinvitable: item.can_be_reinvited }])
        }, [
          _withDirectives((_openBlock(), _createElementBlock("td", null, [
            _createTextVNode(_toDisplayString(item.user.first_name), 1)
          ])), [
            [_directive_private]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("td", null, [
            _createTextVNode(_toDisplayString(item.user.last_name), 1)
          ])), [
            [_directive_private]
          ]),
          _createElementVNode("td", null, _toDisplayString(_ctx.getUserRoleText(item)), 1),
          _withDirectives((_openBlock(), _createElementBlock("td", null, [
            _createTextVNode(_toDisplayString(item.user.email), 1)
          ])), [
            [_directive_private]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_25, [
            _createTextVNode(_toDisplayString(_ctx.getUserPhoneNumberLabel(item)), 1)
          ])), [
            [_directive_private],
            [_directive_fs_exclude]
          ]),
          _createElementVNode("td", null, _toDisplayString(item.user.id), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.getUserStatus(item)), 1),
          _createElementVNode("td", null, [
            _createVNode(_component_v_row, {
              class: "standard-data-table__actions",
              "no-gutters": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_menu, {
                  offset: "-20",
                  location: "start"
                }, {
                  activator: _withCtx(({ props }) => [
                    (!_ctx.hideActionsMenuButton(item))
                      ? (_openBlock(), _createBlock(_component_custom_button, _mergeProps({
                          key: 0,
                          class: "standard-data-table__actions-dots",
                          "data-test": `uma${item.user.id}`,
                          variant: "icon",
                          size: "small",
                          "aria-label": "Actions",
                          ripple: false
                        }, props), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-dots-vertical")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040, ["data-test"]))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, { class: "text-left" }, {
                      default: _withCtx(() => [
                        (!_ctx.hideEditButton(item))
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 0,
                              class: "action-buttons",
                              disabled: _ctx.processing,
                              onClick: ($event: any) => (_ctx.userEdit = item)
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_custom_button, {
                                  disabled: _ctx.processing,
                                  class: "custom-button",
                                  label: "Edit",
                                  variant: "text",
                                  ripple: false
                                }, null, 8, ["disabled"])
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"]))
                          : _createCommentVNode("", true),
                        (!_ctx.hideActiveButton(item))
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 1,
                              class: "action-buttons",
                              disabled: _ctx.processing,
                              onClick: ($event: any) => (_ctx.deactivateUser = item)
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_custom_button, {
                                  disabled: _ctx.processing,
                                  class: "custom-button",
                                  label: item.is_active ? 'Deactivate' : 'Reactivate',
                                  variant: "text",
                                  ripple: false
                                }, null, 8, ["disabled", "label"])
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"]))
                          : _createCommentVNode("", true),
                        (!_ctx.hideReInviteButton(item))
                          ? (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 2,
                              class: "action-buttons"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_custom_button, {
                                  class: "custom-button",
                                  disabled: _ctx.processing || _ctx.sendingReinvite,
                                  label: "Resend Invite",
                                  variant: "text",
                                  onClick: ($event: any) => (_ctx.sendReinvite(item.user.email))
                                }, null, 8, ["disabled", "onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["headers", "items", "loading", "mobile"]),
    (_ctx.userEdit)
      ? (_openBlock(), _createBlock(_component_edit_user, {
          key: 0,
          user: _ctx.userEdit,
          onSave: _ctx.refreshUsers,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.userEdit = null)),
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userEdit = null))
        }, null, 8, ["user", "onSave"]))
      : _createCommentVNode("", true),
    (_ctx.deactivateUser)
      ? (_openBlock(), _createBlock(_component_deactivate_user, {
          key: 1,
          user: _ctx.deactivateUser,
          onSave: _ctx.refreshUsers,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deactivateUser = null)),
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deactivateUser = null))
        }, null, 8, ["user", "onSave"]))
      : _createCommentVNode("", true),
    (_ctx.reinvitedEmail)
      ? (_openBlock(), _createBlock(_component_re_invite_user_dialog, {
          key: 2,
          email: _ctx.reinvitedEmail,
          "show-alert": _ctx.showReinviteAlert,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reinvitedEmail = '')),
          "onClose:alert": _cache[5] || (_cache[5] = ($event: any) => (_ctx.showReinviteAlert = false))
        }, null, 8, ["email", "show-alert"]))
      : _createCommentVNode("", true)
  ]))
}