<template>
  <v-text-field
    ref="input"
    v-mask="inputMask"
    v-bind="$attrs"
    autocomplete="off"
    :data-test="dataTestAttr"
    :disabled="disabled"
    :label="labelAttr"
    :variant="variantAttr"
    :prepend-inner-icon="showIcon ? 'mdi-calendar' : ''"
    :rules="rules || [dateValidation]"
    validate-on="blur"
    @click.right.prevent
    @copy.prevent />
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import { INPUT_MASK_DEFAULTS } from '@/components/Inputs/defaults';
import validateDate from '@/validators/date';

export default defineComponent({
  name: 'DateInput',

  mixins: [InputFieldMixin],

  props: {
    showIcon: { type: Boolean, default: false },
    enforceDobCheck: { type: Boolean, default: false },
    minAge: { type: Number, default: 0 },
    maxAge: { type: Number, default: 0 },
    min: { type: [Date, String, Number], default: 0 },
    max: { type: [Date, String, Number], default: 0 },
    rules: { type: Array as PropType<any[] | null>, default: () => null },
    isFsExcluded: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },

  data: () => ({
    defaultLabelAttr: 'Date',
  }),

  computed: {
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
    inputMask(): any {
      const mask = {
        ...INPUT_MASK_DEFAULTS,
        mask: '99-99-9999',
        oncleared: this.onMaskCleared,
      };

      return {
        ...mask,
        onBeforePaste: (value: any) => {
          return this.beforePaste(value);
        },
      };
    },
  },

  mounted() {
    if (this.isFsExcluded) {
      this.$el.querySelector('input')?.classList.add('fs-exclude');
    }
  },

  methods: {
    dateValidation(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      const validatorOpts: any = {};

      if (this.enforceDobCheck) {
        validatorOpts.dob = true;
        if (this.minAge) validatorOpts.minDob = this.minAge;
        if (this.maxAge) validatorOpts.maxDob = this.maxAge;
      }

      if (this.min) validatorOpts.minDate = this.min;
      if (this.max) validatorOpts.maxDate = this.max;

      const { isValid, message } = validateDate(value, validatorOpts);

      if (!isValid) return message!;

      const verify = this.verifyInput(value);

      if (verify) return verify;

      return true;
    },
  },
});
</script>
