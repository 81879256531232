import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d82aa13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_dialog = _resolveComponent("standard-dialog")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classList)
  }, [
    _createVNode(_component_standard_dialog, {
      dialog: true,
      "on-cta": _ctx.toggleUser,
      "on-cancel": _ctx.closeModal,
      "cta-disabled": _ctx.processing,
      title: _ctx.title,
      "button-label": "Yes",
      "data-test": "deactivateModal"
    }, {
      body: _withCtx(() => [
        (_ctx.isUserActive)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_ctx.userNameLabel), 1)
              ])), [
                [_directive_private]
              ]),
              _createTextVNode(" will no longer be able to login to your merchant portal. ")
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_ctx.userNameLabel), 1)
              ])), [
                [_directive_private]
              ]),
              _createTextVNode(" will be able to login to your merchant portal with the previously assigned permissions. ")
            ]))
      ]),
      _: 1
    }, 8, ["on-cta", "on-cancel", "cta-disabled", "title"])
  ], 2))
}