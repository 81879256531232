<template>
  <div>
    <custom-alert v-if="errorAcceptingLoan" type="error">
      There was an error accepting the loan agreement
    </custom-alert>
    <checkbox-input
      v-model="checkLoanDoc"
      data-test="checkLoanDoc"
      hide-details
      class="checkbox">
      <template #label>
        <span>
          I agree to my <a
            :href="loanAgreementUrl"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop>
            Loan Documents
          </a> and
          agree to create a payment portal account and add a payment method in order
          to transact with my loan.
        </span>
      </template>
    </checkbox-input>
    <checkbox-input
      v-model="checkESign"
      data-test="checkESign"
      hide-details
      class="checkbox">
      <template #label>
        <span>
          The originating bank on your loan is {{ lenderName }}. By checking this box,
          I accept my loan agreement with {{ lenderName }} and their
          <a
            :href="lenderPrivacyPolicyUrl"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop>
            Privacy Notice
          </a>.
        </span>
      </template>
    </checkbox-input>
    <p>
      {{ selectedLoan.short_description }}
    </p>
    <custom-button
      :disabled="!(checkLoanDoc && checkESign) || loading"
      full-width
      @click="onAccept">
      I Accept This Loan Agreement
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import CheckboxInput from '@/components/Inputs/Checkbox.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { patchAvailableConsents } from '@/utils/Consents';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';

export default defineComponent({
  name: 'AcceptLoan',
  components: { CustomButton, CheckboxInput, CustomAlert },
  data() {
    return {
      checkESign: false,
      checkLoanDoc: false,
      loading: false,
      errorAcceptingLoan: false,
      consentTypes: [
        ConsentTypesEnum.WARM_WELCOME,
        ConsentTypesEnum.BORROWER_AGREEMENT,
      ],
    };
  },
  computed: {
    loanAgreementUrl(): string {
      const loanAgreement = this.$store.getters['Consumer/getLoanAgreement'];
      if (!loanAgreement) return '';

      const file = new File([loanAgreement], 'loan-agreement.pdf', { type: loanAgreement.type });
      return URL.createObjectURL(file);
    },
    lenderName(): string {
      return this.$store.getters['Consumer/getLenderName'];
    },
    lenderPrivacyPolicyUrl(): string {
      return this.$store.getters['Consumer/getLenderPrivacyPolicyUrl'];
    },
    selectedLoan(): any {
      return this.$store.getters['Consumer/getSelectedLoan'];
    },
    appId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
  },
  methods: {
    async onAccept() {
      this.loading = true;
      this.errorAcceptingLoan = false;
      try {
        await this.$store.dispatch('Consumer/acceptApplication');
        await this.updateConsents();
        this.$emit('accept');
      } catch {
        this.errorAcceptingLoan = true;
      } finally {
        this.loading = false;
      }
    },
    async updateConsents(): Promise<number> {
      return patchAvailableConsents(this.consentTypes, this.appId, ConsentEntityTypes.CONSUMER);
    },
  },
});
</script>

<style lang="scss" scoped>
.checkbox {
  color: var(--grayscale-color-1);
  margin-top: 0;
  margin-bottom: 1.5rem !important;
  padding-top: 0;

  :deep(.v-input__control) {
    align-items: center;

    .v-icon:not(.mdi-checkbox-marked) {
      color: var(--grayscale-color-2) !important;
    }

    .v-input--selection-controls__input {
      margin-left: 0.5rem;
      margin-right: 1rem;
    }

    label {
      padding-bottom: 0.2rem;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: var(--grayscale-color-1);
    }
  }
}

p {
  font-size: 0.75rem;
}
</style>
