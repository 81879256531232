import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReInviteMixin',

  data() {
    return {
      reinvitedEmail: '',
      showReinviteAlert: false,
      sendingReinvite: false,
    };
  },

  methods: {
    async sendReinvite(email: string) {
      this.sendingReinvite = true;
      window.setTimeout(() => {
        this.sendingReinvite = false;
      }, 5000);

      try {
        await this.$store.dispatch('MerchantPortal/reSendUserInvite', { email });
        this.reinvitedEmail = email;
      } catch (err) {
        this.showReinviteAlert = true;
      }
    },
  },
});
