import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_password_input = _resolveComponent("password-input")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: _ctx.show,
    "cta-disabled": _ctx.ctaDisabled,
    "on-cancel": _ctx.clearData,
    "show-cancel-cta": false,
    "on-cta": !_ctx.passwordReset ? _ctx.resetUserPassword : _ctx.clearData,
    "show-progress-indicator": _ctx.processing,
    "show-title": false,
    "button-label": _ctx.passwordReset ? 'OK' : 'Save'
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        (!_ctx.passwordReset)
          ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_password_input, {
                      modelValue: _ctx.oldPassword,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
                      errors: _ctx.errors.old_password,
                      "show-password-while-typing": true,
                      autocomplete: "current-password",
                      label: "Old Password"
                    }, null, 8, ["modelValue", "errors"]),
                    _createVNode(_component_password_input, {
                      modelValue: _ctx.newPassword,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
                      autocomplete: "new-password",
                      "show-password-while-typing": true,
                      "show-requirements-tooltip": "",
                      label: "New Password"
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_password_input, {
                      modelValue: _ctx.passwordCheck,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.passwordCheck) = $event)),
                      autocomplete: "new-password",
                      "show-password-while-typing": true,
                      label: "Verify Password"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Your new password has been saved. ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ])
    ]),
    _: 1
  }, 8, ["dialog", "cta-disabled", "on-cancel", "on-cta", "show-progress-indicator", "button-label"]))
}