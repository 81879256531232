<template>
  <div class="standard-data-table__actions__edit-modal">
    <custom-button
      :disabled="processing"
      size="small"
      variant="link"
      data-test="addNewUserBtn"
      @click="showModal">
      <v-icon>mdi-plus</v-icon>
      Add New User
    </custom-button>

    <standard-dialog
      v-if="showAddModal"
      dialog
      :on-cta="addNewUser"
      :on-cancel="closeModal"
      :on-clear="clearForm"
      :cta-disabled="addNewUserDisabled || hasErrors"
      :show-close-confirmation="!formEmpty"
      :clear-disabled="formEmpty"
      :persistent="true"
      :show-cancel-cta="formEmpty"
      :show-clear-cta="!formEmpty"
      button-label="Send Invite"
      data-test="addNewUserModal"
      close-confirm-body-text="Cancel creating this user?"
      close-confirmation-confirm-text="Yes"
      close-confirmation-cancel-text="No">
      <template #body>
        <user-form
          :form-data="formData"
          :email-error="emailError"
          @updateFormData="formData = $event"
          @emailUpdated="disableAddUserButton = false" />
        <standard-error-list :clear-non-field-errors-on-destroy="true" />
      </template>
    </standard-dialog>

    <standard-dialog
      v-if="confirmationModal"
      dialog
      :show-cancel-cta="false"
      button-label="OK"
      :on-cancel="closeConfirmationModal"
      :on-cta="closeConfirmationModal">
      <template #body>
        <span>
          An email invite has been sent to
          <strong v-private>{{ confirmationEmail }}.</strong>
        </span>
        <v-spacer />
        <span>
          Once they set up a password and login, they will be able to work in your portal.
        </span>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardErrorList from '@/components/ErrorList.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { CreateNewMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/CreateNewMerchantUserPayloadInterface';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import emailAddress from '@/validators/email_address';
import phoneNumber from '@/validators/phone_number';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/UserForm.vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'AddNewUser',
  components: {
    CustomButton,
    StandardDialog,
    StandardErrorList,
    UserForm,
  },
  mixins: [
    GetProcessing,
    GetErrors,
    HoneypotTrackMixin,
  ],
  data() {
    const formData = {} as CreateNewMerchantUserPayloadInterface;
    return {
      formData,
      confirmationEmail: '',
      showAddModal: false,
      confirmationModal: false,
      hpEventName: 'New Merchant User Added',
      disableAddUserButton: false,
    };
  },
  computed: {
    isSalesManager() {
      return this.$store.getters['MerchantPortal/getMerchantData']?.user_type.name === 'merchant-sales-manager' || false;
    },
    formEmpty(): boolean {
      return (!this.formData.first_name
        && !this.formData.last_name
        && !this.formData.phone_number
        && !this.formData.email
        && !this.formData.user_type)
        || this.processing;
    },
    hasErrors(): boolean {
      return !!this.errors?.non_field_errors;
    },
    isValidFirstName(): boolean {
      return !!this.formData.first_name;
    },
    isValidLastName(): boolean {
      return !!this.formData.last_name;
    },
    isValidEmail(): boolean {
      return emailAddress(this.formData.email);
    },
    isValidPhoneNumber(): boolean {
      return phoneNumber(this.formData.phone_number);
    },
    isValidUserType(): boolean {
      return !!this.formData.user_type;
    },
    addNewUserDisabled(): boolean {
      return this.processing
        || !this.isValidFirstName
        || !this.isValidLastName
        || !this.isValidEmail
        || !this.isValidPhoneNumber
        || !this.isValidUserType
        || this.disableAddUserButton;
    },
    emailError(): string {
      return this.errors.email ? this.errors.email[0] : '';
    },
  },
  watch: {
    showAddModal(value: string) {
      if (value && this.isSalesManager) this.formData.user_type = 'Salesrep';
    },
  },
  created() {
    this.refreshUsers();
  },
  methods: {
    clearForm(): void {
      this.formData = {} as CreateNewMerchantUserPayloadInterface;
      this.$store.dispatch('Ui/resetErrorState');
    },
    async addNewUser(): Promise<void> {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        user_type,
      } = this.formData;

      this.confirmationEmail = email;

      const payload: CreateNewMerchantUserPayloadInterface = {
        first_name,
        last_name,
        email,
        phone_number,
        user_type,
      };

      const gucaPayload = {
        merchant_invitation: true,
        email: this.formData.email,
        phone_number: this.formData.phone_number,
        check_against_consumer: true,
        check_against_merchant: true,
      };

      const response: number = await this.$store.dispatch(
        'MerchantPortal/dispatchCreateNewMerchantUser',
        payload,
      );

      if (response === 201) {
        await this.$store.dispatch('Auth/gucaCheckApi', gucaPayload);
        await this.refreshUsers();

        this.closeModal();
        this.showConfirmationModal();

        this.trackEvent(this.hpEventName, PageTypesShorthand.MP);
      }

      if (this.emailError) {
        this.disableAddUserButton = true;
      }
    },
    async refreshUsers(): Promise<void> {
      await this.$store.dispatch('MerchantPortal/dispatchFetchMerchantUsers');
    },
    showModal(): void {
      this.showAddModal = true;
    },
    closeModal(): void {
      this.clearForm();
      this.$store.dispatch('Ui/resetErrorState');

      this.showAddModal = false;
    },
    showConfirmationModal(): void {
      this.confirmationModal = true;
    },
    closeConfirmationModal(): void {
      this.confirmationModal = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
