import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-574bdcf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "simple-card__body-text",
  "data-app": "true"
}
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { class: "subheader" }
const _hoisted_4 = { class: "search-refresh-wrapper section-header__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_refresh_button = _resolveComponent("refresh-button")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_custom_expansion_panel = _resolveComponent("custom-expansion-panel")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_custom_expansion_panel, {
      expanded: _ctx.isExpanded,
      "can-collapse": _ctx.onlyOnActivityCenter || !_ctx.isMobile
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_v_spacer),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              "append-inner-icon": "mdi-magnify",
              label: "Search",
              class: "table-search-bar",
              variant: "underlined",
              width: "14rem",
              "single-line": "",
              "hide-details": ""
            }, null, 8, ["modelValue"]),
            (!_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_refresh_button, {
                  key: 0,
                  "data-test": "recentRefreshBtn",
                  active: _ctx.refreshingFromParent,
                  onClick: _ctx.refreshTable
                }, null, 8, ["active", "onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      text: _withCtx(() => [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
          "append-inner-icon": "mdi-magnify",
          label: "Search",
          flat: "",
          "bg-color": "transparent",
          variant: "underlined",
          class: "table-search-bar--mobile",
          "single-line": "",
          "hide-details": "",
          "data-test": "portalTableSearch"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_data_table_server, _mergeProps({ page: _ctx.page }, _ctx.options, {
          headers: _ctx.headers,
          items: _ctx.items,
          search: _ctx.search,
          "disable-sort": _ctx.isMobile,
          mobile: _ctx.isMobile,
          "items-per-page-options": _ctx.itemsPerPageOptions,
          "items-per-page-text": "Rows Displayed",
          "item-key": "date_time",
          "items-length": _ctx.itemsLength,
          class: "merchant-portal-recent-activity__data-table",
          "onUpdate:page": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:page', $event))),
          "onUpdate:options": _ctx.updatePage
        }), {
          "item.consumer_first_name": _withCtx(({ item }) => [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(item.consumer_first_name), 1)
            ])), [
              [_directive_private]
            ])
          ]),
          "item.consumer_last_name": _withCtx(({ item }) => [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(item.consumer_last_name), 1)
            ])), [
              [_directive_private]
            ])
          ]),
          "item.payment_request_status": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(item.payment_request_status), 1)
          ]),
          "item.request_date": _withCtx(({ item }) => [
            _createTextVNode(_toDisplayString(_ctx.DateTimeFormatShort(item.request_date)), 1)
          ]),
          "item.consumer_charge_amount": _withCtx(({ item }) => [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(_ctx.CurrencyFormatLong(item.consumer_charge_amount)), 1)
            ])), [
              [_directive_private]
            ])
          ]),
          "item.requested_by": _withCtx(({ item }) => [
            _withDirectives((_openBlock(), _createElementBlock("span", null, [
              _createTextVNode(_toDisplayString(item.requested_by), 1)
            ])), [
              [_directive_private]
            ])
          ]),
          _: 1
        }, 16, ["page", "headers", "items", "search", "disable-sort", "mobile", "items-per-page-options", "items-length", "onUpdate:options"])
      ]),
      _: 1
    }, 8, ["expanded", "can-collapse"])
  ]))
}