import { defineComponent } from 'vue';
import MerchantStatusReasons from '@/enums/Merchant/MerchantStatusReasons';
import GetSponsorMixin from '@/mixins/GetSponsor';
import MerchantDataMixin from '@/mixins/Merchant/MerchantDataMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';

interface StepConfig {
  default: {
    step?: number;
    subStep?: number;
  },
  IC1?: {
    step?: number;
    subStep?: number;
  },
}

const RE_ENTRY_CONFIG: Partial<Record<MerchantStatusReasons, StepConfig>> = {
  [MerchantStatusReasons.POST_OTP_PRE_PASSWORD]: {
    default: { step: 1 },
    IC1: {},
  },
  [MerchantStatusReasons.POST_OTP_TOKEN_CREATED]: {
    default: {},
    IC1: { step: 2 },
  },
  [MerchantStatusReasons.POST_PASSWORD_PRE_OWNERS]: {
    default: { step: 3 },
    IC1: { step: 2 },
  },
  [MerchantStatusReasons.POST_OWNERS_PRE_INDUSTRY]: {
    default: { step: 4, subStep: 2 },
    IC1: { step: 3, subStep: 2 },
  },
  [MerchantStatusReasons.POST_INDUSTRY_PRE_BUSINESS_NAME]: {
    default: { step: 4, subStep: 3 },
    IC1: { step: 3, subStep: 3 },
  },
  [MerchantStatusReasons.POST_BUSINESS_NAME_PRE_BUSINESS_STRUCTURE]: {
    default: { step: 4, subStep: 4 },
    IC1: { step: 3, subStep: 4 },
  },
  [MerchantStatusReasons.POST_BUSINESS_STRUCTURE_PRE_DBS]: {
    default: { step: 4, subStep: 5 },
    IC1: { step: 3, subStep: 5 },
  },
  [MerchantStatusReasons.POST_DBS_PRE_ASR_ACS]: {
    default: { step: 4, subStep: 6 },
    IC1: { step: 3, subStep: 6 },
  },
  [MerchantStatusReasons.POST_ASR_ACS_PRE_PAYMENTS_ACCEPTED_YFV]: {
    default: { step: 4, subStep: 7 },
    IC1: { step: 3, subStep: 7 },
  },
  [MerchantStatusReasons.POST_PAYMENTS_ACCEPTED_YFV_PRE_CONFIRM_ALL]: {
    default: { step: 5 },
    IC1: { step: 4 },
  },
  [MerchantStatusReasons.POST_SUBMIT_APP_PRE_PAYMENT_PROVIDER]: {
    default: {},
    IC1: { step: 5 },
  },
  [MerchantStatusReasons.POST_PAYMENT_PROVIDER_PRE_RATE_SHEET]: {
    default: {},
    IC1: { step: 6 },
  },
};

export default defineComponent({
  name: 'MobReEntryMixin',

  mixins: [
    GetSponsorMixin,
    MerchantDataMixin,
    FeatureFlagsMixin,
  ],

  computed: {
    wizardStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardStep'];
    },

    wizardSubStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardSubStep'];
    },

    appInProgress(): boolean {
      return this.$store.getters['MerchantOnboarding/isApplicationInProgress'];
    },

    isReEntry(): boolean {
      return this.isReEntryEnabled && this.appInProgress;
    },

    isReEntryEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.RE_ENTRY);
    },

    appStatusReason(): MerchantStatusReasons {
      return this.$store.getters['MerchantOnboarding/appStatusReason'];
    },
  },

  methods: {
    navigateStep(step: number) {
      window.scrollTo(0, 0);

      if (step) {
        this.setStep(step);

        const query = {
          step: step.toString(),
        };

        if (!this.isTypeIC1 && step === 4) {
          this.navigateSubStep(2, query);
        } else if (this.isTypeIC1 && step === 3) {
          this.navigateSubStep(2, query);
        } else {
          this.$router.push({
            path: this.$route.path,
            query,
          });
        }
      }
    },

    navigateSubStep(subStep: number, query: any = {}) {
      window.scrollTo(0, 0);

      if (subStep) {
        if (subStep <= 0) {
          this.navigateStep(this.wizardStep - 1);
        } else {
          this.setSubStep(subStep);

          this.$router.push({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              ...query,
              subStep: subStep.toString(),
            },
          });
        }
      }
    },

    navigate(step?: number, subStep?: number) {
      window.scrollTo(0, 0);

      if (!step) return;

      this.setStep(step);
      const query: { step: string, subStep?: string } = {
        step: step.toString(),
      };
      if (subStep) {
        query.subStep = subStep.toString();
      }

      if ((subStep || 0) <= 2 && ((this.isTypeIC1 && step === 3) || (!this.isTypeIC1 && step === 4))) {
        // TODO Add explanation
        query.subStep = '2';
      }

      this.$router.push({
        path: this.$route.path,
        query,
      });
    },

    setStep(step: number) {
      if (step) {
        this.$store.dispatch(
          'MerchantOnboarding/dispatchSetMerchantOnboardingWizardStep',
          step,
        );
      }
    },

    setSubStep(subStep: number) {
      if (subStep) {
        this.$store.dispatch(
          'MerchantOnboarding/dispatchSetMerchantOnboardingWizardSubStep',
          subStep,
        );
      }
    },

    async reEntrySetup() {
      await this.$store.dispatch('MerchantOnboarding/dispatchFetchReEntryData');

      const reasonSteps = RE_ENTRY_CONFIG[this.appStatusReason as MerchantStatusReasons]!;
      if (!reasonSteps) return;

      const step = reasonSteps[this.isTypeIC1 ? 'IC1' : 'default']!;
      this.navigate(step.step, step.subStep);
    },
  },
});
