<template>
  <div>
    <div v-if="!isCreditStillFrozen">
      <h5 data-test="welcomeBackHeaderLabel">
        Welcome Back!
      </h5>
      <p class="mb-6" data-test="unfrozenCreditLabel">
        <template v-if="isSoftFreeze">
          To continue your application, please confirm you have unfrozen your credit.
        </template>
        <template v-else>
          To finish your application, please confirm you have unfrozen your credit.
        </template>
      </p>
      <p
        v-if="!isSoftFreeze"
        class="mb-6"
        data-test="clickApplyLabel">
        By clicking "Apply" you are making a credit application and we will pull your credit report
        and it may impact your credit score.
      </p>
      <custom-button
        class="mb-6"
        full-width
        @click="onConfirm">
        <template v-if="isSoftFreeze">
          Yes, view My Offers
        </template>
        <template v-else>
          Yes, Apply
        </template>
      </custom-button>
      <custom-button
        variant="secondary"
        full-width
        @click="onCancel">
        No, I Need To Unfreeze My Credit
      </custom-button>
    </div>
    <credit-freeze-error v-else :expiration-date="invitationExpirationDate" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CreditFreezeError from '@/components/Consumer/LoanApplyWizard/Messages/CreditFreezeError.vue';
import { dateToFormat } from '@/helpers/DateTimeHelper';
import DATE_CONSTANTS from '@/constants/DateConstants';
import InvitationApplicationStatusDetails from '@/enums/Consumer/InvitationApplicationStatusDetails';

export default defineComponent({
  name: 'CreditFreezeReEntry',
  components: { CustomButton, CreditFreezeError },
  data() {
    return {
      isCreditStillFrozen: false,
    };
  },
  computed: {
    invitationExpirationDate(): string | null {
      const date = this.$store.getters['Consumer/getLoanApplyWizardData'].expires_on_date;

      return dateToFormat(date, DATE_CONSTANTS.dateFormat);
    },
    isSoftFreeze(): boolean {
      return this.$store.getters['Consumer/getInvitation'].app_status_detail
        === InvitationApplicationStatusDetails.SOFT_PULL_FROZEN_CREDIT_FILE;
    },
  },
  methods: {
    onConfirm() {
      this.$emit('continue');
    },
    onCancel() {
      this.isCreditStillFrozen = true;
    },
  },
});
</script>
