<template>
  <standard-dialog
    content-class="locations-dialog"
    :dialog="true"
    @cancel="$emit('close')">
    <template #title>
      <h2>Locations</h2>
    </template>

    <template #body>
      <ul>
        <li v-for="location in locations" :key="location">
          <span>{{ location }}</span>
        </li>
      </ul>
    </template>

    <template #actions>
      <custom-button full-width @click="$emit('close')">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'LocationsDialog',

  components: {
    CustomButton,
    StandardDialog,
  },

  props: {
    locations: { type: Array, required: true },
  },
});
</script>

<style lang="scss" scoped>
.locations-dialog {
  h2 {
    margin: 0;
    font: inherit;
    color: inherit;
  }

  ul {
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    list-style-type: disc;

    li {
      line-height: 1.5;
    }
  }
}
</style>
