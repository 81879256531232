<template>
  <div :class="classList">
    <standard-dialog
      :dialog="true"
      :on-cta="toggleUser"
      :on-cancel="closeModal"
      :cta-disabled="processing"
      :title="title"
      button-label="Yes"
      data-test="deactivateModal">
      <template #body>
        <span v-if="isUserActive">
          <span v-private>{{ userNameLabel }}</span> will no longer be able to login to
          your merchant portal.
        </span>
        <span v-else>
          <span v-private>{{ userNameLabel }}</span> will be able to login to your merchant portal
          with the previously assigned permissions.
        </span>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { UpdateMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/UpdateMerchantUserPayloadInterface';
import { MerchantUserInterface } from '@/interfaces/states/MerchantUserInterface';
import GetProcessing from '@/mixins/GetProcessing';

export default defineComponent({
  name: 'DeactivateUser',
  components: {
    StandardDialog,
  },
  mixins: [GetProcessing],
  props: {
    user: { type: Object as PropType<MerchantUserInterface>, required: true },
    onCta: { type: Function, default: () => null },
  },
  computed: {
    classList() {
      const baseClass = 'standard-data-table__actions__activate-modal';
      const classList: Array<string> = [baseClass];

      if (this.isUserActive) {
        classList.push(`${baseClass}--active`);
      }

      if (!this.isUserActive) {
        classList.push(`${baseClass}--inactive`);
      }

      return classList.join(' ');
    },
    isUserActive(): boolean {
      return this.user.is_active;
    },
    userId(): number {
      return this.$props.user.user.id;
    },
    userNameLabel(): string {
      const { first_name, last_name } = this.$props.user.user;

      return `${first_name} ${last_name}`;
    },
    title(): string {
      const active: string = 'Deactivate this user?';
      const inActive: string = 'Reactivate this user?';

      return this.isUserActive ? active : inActive;
    },
  },
  methods: {
    async toggleUser(): Promise<void> {
      if (this.isUserActive) {
        await this.deactivateUser();
      } else {
        await this.activateUser();
      }

      this.onCta();
      this.$emit('save');
      this.closeModal();
    },
    async deactivateUser(): Promise<void> {
      const payload: UpdateMerchantUserPayloadInterface = {
        userId: this.userId,
        data: {
          is_active: false,
        },
      };

      await this.$store.dispatch(
        'MerchantPortal/dispatchUpdateMerchantUser',
        payload,
      );
    },
    async activateUser(): Promise<void> {
      const payload: UpdateMerchantUserPayloadInterface = {
        userId: this.userId,
        data: {
          is_active: true,
        },
      };

      await this.$store.dispatch(
        'MerchantPortal/dispatchUpdateMerchantUser',
        payload,
      );
    },
    closeModal() {
      this.$emit('cancel');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
@import "@/assets/scss/mixins/mixins";

.standard-data-table__actions__activate-modal {
  &--inactive {
    .btn {
      color: #000000;
      border-color: #000000;
    }
  }
}

.custom-button {
  @include user-management-custom-button;
}

</style>
