import UsersRolesEnum from '@/enums/UsersRolesEnum';
import AuthGuard from '@/router/auth-guard';
import ConsumerWelcome from '@/views/Consumer/Welcome.vue';
import Consumer from '@/views/Consumer/index.vue';
import ConsumerLoanApply from '@/views/Consumer/LoanApply.vue';
import IDVerificationComplete from '@/components/Consumer/LoanApplyWizard/IDVerification/IDVerificationComplete.vue';
import MerchantPortal from '@/views/Merchant/Portal/index.vue';
import MerchantActivityCenter from '@/views/Merchant/Portal/ActivityCenter.vue';
import MerchantAllTransactions from '@/views/Merchant/Portal/AllTransactions.vue';
import MerchantRateSheets from '@/views/Merchant/Portal/RateSheets.vue';
import MerchantStatements from '@/views/Merchant/Portal/Statements.vue';
import MerchantAdmin from '@/views/Merchant/Portal/Admin.vue';
import MerchantLogin from '@/views/Merchant/Login.vue';
import MerchantCreateUser from '@/views/Merchant/CreateUser.vue';
import MerchantBeginOnboarding from '@/views/Merchant/BeginOnboarding.vue';
import MerchantOnboarding from '@/views/Merchant/Onboarding.vue';
import MerchantStatementDetails from '@/views/Merchant/Portal/StatementDetails.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Widgets from '@/views/Widgets.vue';
import StandardErrorPage from '@/views/StandardErrorPages/StandardErrorPage.vue';
import PageNotFound from '@/views/StandardErrorPages/PageNotFound.vue';
// eslint-disable-next-line import/no-cycle
import store from '@/store'; // This should be only temporary
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [{
  path: '/',
  redirect: {
    name: 'merchant-login',
  },
  meta: {
    title: 'Home',
  },
},
{
  path: '/consumer/welcome/:id',
  name: 'consumer-welcome',
  component: ConsumerWelcome,
  meta: {
    title: 'Welcome',
  },
},
{
  path: '/consumer',
  name: 'consumer',
  component: Consumer,
  meta: {
    title: 'Consumer MerchantPortal',
  },
},
{
  path: '/consumer/loan-apply',
  name: 'consumer-loan-apply',
  component: ConsumerLoanApply,
  meta: {
    title: 'Loan Apply',
  },
},
{
  path: '/consumer/id-verification-complete',
  name: 'id-verification-complete',
  component: IDVerificationComplete,
  meta: {
    title: 'ID Verification Complete',
  },
},
{
  path: '/merchant',
  component: MerchantPortal,
  beforeEnter: AuthGuard,
  meta: {
    title: 'Merchant MerchantPortal',
  },
  children: [
    {
      path: '',
      name: 'merchant',
      redirect: {
        name: 'merchant-activity-center',
      },
    },
    {
      path: '/merchant/activity-center',
      name: 'merchant-activity-center',
      component: MerchantActivityCenter,
      beforeEnter: AuthGuard,
      meta: {
        title: 'Activity Center',
      },
    },
    {
      path: '/merchant/all-transactions',
      name: 'merchant-all-transactions',
      component: MerchantAllTransactions,
      beforeEnter: AuthGuard,
      meta: {
        title: 'All Transactions',
      },
    },
    {
      path: '/merchant/rate-sheets',
      name: 'merchant-rate-sheets',
      component: MerchantRateSheets,
      beforeEnter: AuthGuard,
      meta: {
        title: 'Rate Sheets',
        userRole: UsersRolesEnum.ADMIN,
      },
    },
    {
      path: '/merchant/statements',
      name: 'merchant-statements',
      component: MerchantStatements,
      beforeEnter: AuthGuard,
      meta: {
        title: 'Statements',
        userRole: UsersRolesEnum.ADMIN,
      },
    },
    {
      path: '/merchant/admin',
      name: 'merchant-admin',
      component: MerchantAdmin,
      beforeEnter: AuthGuard,
      meta: {
        title: 'Admin',
        userRole: UsersRolesEnum.ADMIN,
      },
    },
  ],
},
{
  path: '/merchant/login',
  name: 'merchant-login',
  component: MerchantLogin,
  props: true,
},
{
  path: '/merchant/create-user',
  name: 'merchant-create-user',
  component: MerchantCreateUser,
},
{
  path: '/merchant/begin-onboarding',
  name: 'merchant-begin-onboarding',
  component: MerchantBeginOnboarding,
  meta: {
    title: 'Merchant Begin Onboarding',
  },
},
{
  path: '/merchant/onboarding',
  name: 'merchant-onboarding',
  component: MerchantOnboarding,
  meta: {
    title: 'Merchant Onboarding',
  },
},
{
  path: '/merchant/statement-details/:id',
  name: 'merchant-statement-details',
  component: MerchantStatementDetails,
  beforeEnter: AuthGuard,
  meta: {
    title: 'Statement Details',
    userRole: UsersRolesEnum.ADMIN,
  },
},
{
  path: '/reset-password',
  name: 'reset-password',
  component: ResetPassword,
  meta: {
    title: 'Reset Password',
  },
},
{
  path: '/widgets',
  name: 'widgets',
  component: Widgets,
  meta: {
    title: 'Widgets',
  },
},
{
  path: '/error-page',
  name: 'error-page',
  component: StandardErrorPage,
  meta: {
    title: 'Error Page',
  },
},
{
  path: '/404',
  name: '404',
  component: PageNotFound,
  meta: {
    title: 'Page Not Found',
  },
},
{
  path: '/:pathMatch(.*)*',
  redirect: {
    name: '404',
  },
},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function getLocation(query: any) {
  const { step, subStep } = query;
  return {
    step: Number(step) || 0,
    subStep: Number(subStep) || 0,
  };
}

router.beforeEach((to, from, next) => {
  if (from.name !== 'merchant-onboarding' || to.name !== 'merchant-onboarding') {
    next();
    return;
  }

  const featureFlagActive = store.getters['Config/getFeatureFlags'].includes(FeatureFlagsConstants.RE_ENTRY);
  if (!featureFlagActive) {
    next();
    return;
  }

  const fromLocation = getLocation(from.query);
  const toLocation = getLocation(to.query);
  const phoneVerified = store.getters['MerchantOnboarding/getPassedPhoneVerification'];

  // Allow next if the user is coming from a page without a step
  // or is going to step 1 (re-entry redirects to the mobile verification step)
  if (!fromLocation.step || (!phoneVerified && toLocation.step === 1)) {
    next();
    return;
  }

  // Allow next if the user is going to the next step
  if (fromLocation.step < toLocation.step) {
    next();
    return;
  }

  // Allow next if the user is going to the same step but next substep
  if (fromLocation.step === toLocation.step && fromLocation.subStep <= toLocation.subStep) {
    next();
    return;
  }

  store.dispatch('MerchantOnboarding/setShowBackButtonPreventModal', true);
  next(false);
});

export default router;
